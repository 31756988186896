import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import useFunctionsTools from '../../../utils/useFunctionsTools'
import TitlePage from '../../Common/TitlePage/TitlePage'
import useEventsTools from '../../Hooks/useEventsTools'
import usePagesTools from '../../Hooks/usePagesTools'
import useParamsTools from '../../Hooks/useParamsTools'
import usePicturesTools from '../../Hooks/usePicturesTools'
import useWichTerminal from '../../Hooks/useWichTerminal'
import './ConferenceInscription.scss'
import GreyBlock from './partials/GreyBlock/GreyBlock'

function ConferenceInscription (props) {
  const { fetchOneEventByIdFromApi, oneEvent } = useEventsTools()
  const { convertDateWithTimezone } = useFunctionsTools()
  const { spinner } = usePagesTools()
  const { id } = useParams()
  const { fetchParamsData, getIdTypeParamByTypeAndName, params } = useParamsTools()
  const { displaySpeakers } = usePicturesTools()
  const { getStorage, getUserData } = useMpecSecure()
  const { getTextForPage } = useMpecTranslations('conferenceInscription')
  const { isMobile } = useWichTerminal()
  const [animate] = useState(props.isWorkshop ? _.upperFirst(getTextForPage('workshopInscription.animate')) : _.upperFirst(getTextForPage('subtitle2')))
  const [isWorkshop] = useState(props.isWorkshop || false)
  const [titlePage] = useState(props.isWorkshop ? _.upperFirst(getTextForPage('workshopInscription.title')) : _.upperFirst(getTextForPage('title')))
  const [workshop] = useState(props.isWorkshop ? 'workshop' : '')
  const sessionId = getStorage().getItem('sessionId')

  useEffect(() => {
    fetchParamsData().then()
    fetchOneEventByIdFromApi(getUserData().idCompany, parseInt(id), sessionId).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getScheduleData = (schedule, part) => {
    const date = convertDateWithTimezone(schedule.date, schedule.createdDate, schedule.updatedDate)
    const closeRegistrationDate = !_.isNull(schedule.closeRegistrationDate) ? convertDateWithTimezone(schedule.closeRegistrationDate, schedule.createdDate, schedule.updatedDate) : null
    const newHour = date.clone().add(schedule.duration, 'm').format(' à HH[h]mm')
    const openRegistrationDate = convertDateWithTimezone(schedule.openRegistrationDate, schedule.createdDate, schedule.updatedDate)

    switch (part) {
      case 1:
        return {
          closeRegistrationDate1: closeRegistrationDate,
          date: date.format('DD MMMM Y'),
          duration: schedule?.duration,
          idEventSchedule: schedule?.idEventSchedule,
          isRemote: schedule?.isRemote,
          inscriptionDate: openRegistrationDate,
          limitPerPersons: schedule?.limitPerPersons,
          limitOfParticipants: oneEvent.event?.limitOfParticipants,
          location: schedule?.location,
          nbOfRegistrationsActualUser: schedule?.nbOfRegistrationsActualUser,
          room: schedule?.location,
          roomDesc: date.format('DD MMMM Y [de] HH[h]mm') + newHour,
          remainingPlaces: schedule.remainingPlaces
        }
      case 2:
        return {
          closeRegistrationDate2: closeRegistrationDate,
          date2: date.format('DD MMMM Y [de] HH[h]mm') + newHour,
          duration2: schedule?.duration,
          idEventSchedule2: schedule?.idEventSchedule,
          inscriptionDate2: openRegistrationDate,
          isRemote2: schedule?.isRemote,
          limitPerPersons2: schedule?.limitPerPersons,
          limitOfParticipants2: oneEvent.event?.limitOfParticipants,
          location2: schedule?.location,
          nbOfRegistrationsActualUser2: schedule?.nbOfRegistrationsActualUser,
          roomDesc2: date.format('DD MMMM Y [de] HH[h]mm') + newHour,
          room2: schedule?.location,
          remainingPlaces2: schedule.remainingPlaces
        }
      default:
        return {
          closeRegistrationDate1: closeRegistrationDate,
          date: date.format('DD MMMM Y'),
          duration: schedule?.duration,
          idEventSchedule: schedule?.idEventSchedule,
          inscriptionDate: openRegistrationDate,
          isRemote: schedule?.isRemote,
          location: schedule?.location,
          limitPerPersons: schedule?.limitPerPersons,
          limitOfParticipants: oneEvent.event?.limitOfParticipants,
          nbOfRegistrationsActualUser: schedule?.nbOfRegistrationsActualUser,
          room: schedule?.room,
          roomDesc: date.format('DD MMMM Y [de] HH[h]mm') + newHour,
          time: date.format('[de] HH[h]mm') + newHour,
          remainingPlaces: schedule.remainingPlaces
        }
    }
  }

  const getConference = () => {
    // We can have just two elements for now
    //@Todo adapt for infinite element
    let scheduleObject = {}
    let scheduleObject1 = {}
    let scheduleObject2 = {}
    const schedule1 = _.head(oneEvent?.schedules)
    if (oneEvent.schedules.length > 1) {
      const schedule2 = _.last(oneEvent?.schedules)
      scheduleObject1 = getScheduleData(schedule1, 1)
      scheduleObject2 = getScheduleData(schedule2, 2)
    } else {
      scheduleObject = getScheduleData(schedule1)
    }

    return {
      idUser: oneEvent.event?.idUser,
      isVariousParts: oneEvent.event?.isVariousPart,
      title: oneEvent.event?.title,
      ...scheduleObject1,
      ...scheduleObject2,
      ...scheduleObject
    }
  }

  const getTitlePage = () => {
    const conference = getConference()
    const closeDate = conference.closeRegistrationDate1
    const noCloseDate = _.isNull(closeDate)
    if (!noCloseDate) {
      const date = closeDate
      const now = moment()
      const passed = now > date

      if (passed) {
        return props.isWorkshop ? _.upperFirst(getTextForPage('workshopInscription.titleInfos')) : _.upperFirst(getTextForPage('titleInfos'))
      }
    }

    return titlePage
  }

  const display = () => {
    const idWaitingList = parseInt(getIdTypeParamByTypeAndName('_eventsInscriptionStatus', 'waiting.list'))
    const idInProgress = parseInt(getIdTypeParamByTypeAndName('_eventsInscriptionStatus', 'in.progress'))

    return (
      <Fragment>
        <TitlePage title={getTitlePage()}/>
        <div className=" container col-lg-11 d-flex content row justify-content-between mt-5">
          <div className="col-lg-6">
            {displayPage()}
            {displaySpeakers(_.head(oneEvent?.schedules)?.speakers, isMobile())}
          </div>
          <GreyBlock
            conferences={getConference()}
            params={params}
            isWorkshop={isWorkshop}
            idWaitingList={idWaitingList}
            idInProgress={idInProgress}
          />
        </div>
      </Fragment>
    )
  }

  const displayPage = () => {
    if (oneEvent) {
      return (<Fragment>
        <h2 className={'smallTitle text-left mb-5 ' + workshop}>{oneEvent?.event?.title}</h2>
        {ReactHtmlParser(oneEvent?.event?.description)}
        <h2 className={'subtitleOther text-left mt-5 mb-3 ' + workshop}>{animate}</h2>
      </Fragment>)
    }
  }

  return (
    <div className="ConferenceInscription content-wrapper mb-5">
      {(oneEvent && params) ? display() : spinner(_.upperFirst(getTextForPage('activation')))}
    </div>
  )
}

ConferenceInscription.propTypes = {
  animate: PropTypes.string,
  isWorkshop: PropTypes.bool,
  speakerFirstname: PropTypes.string,
  speakerLastname: PropTypes.string,
  textParticipate: PropTypes.string,
  titlePage: PropTypes.string
}

export default ConferenceInscription
