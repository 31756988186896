import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'

function GreyBlock (props) {
  const { getTextForPage, getTextButton } = useMpecTranslations('returnMaternityPaternityLeave')
  const setClicked = props.clicked
  const handleChange = props.handleChange
  const displayErrorMessage = props.displayErrorMessage

  return (
    <div className={'col-lg-5 greyBlock mb-5'}>
      <h2 className={'page-subtitle mb-5'}>{getTextForPage('greyBlock.titleBlock')}</h2>
      <p>{getTextForPage('greyBlock.text1Block')}</p>
      <p>{getTextForPage('greyBlock.text2Block')}</p>
      <div className="custom-control custom-checkbox mb-3">
        <input
          type="checkbox" className="custom-control-input mr-3"
          id="inscription" value="inscription" onChange={(event) => {
          handleChange('inscription', event)
        }}/>
        <label className="custom-control-label ml-5 pl-4 inscriptionLabel"
               htmlFor="inscription">
          {getTextForPage('greyBlock.conditions')}</label>
      </div>
      {displayErrorMessage()}
      <Button
        buttonFunction={setClicked.bind(this)}
        classSup={'btnGreen pr-5 pl-5'}
        classSupDiv={'mr-auto ml-auto'}
        className={'btn p-3'}
        isButtonWithAction={true}
        title={_.upperFirst(getTextButton('inscription'))}
      />
      <p className={'font-weight-bold font-italic text-center mt-5'}>{getTextForPage('greyBlock.text3Block')}</p>
    </div>
  )
}

GreyBlock.propTypes = {
  clicked: PropTypes.func,
  displayErrorMessage: PropTypes.func,
  handleChange: PropTypes.func
}

export default GreyBlock
