import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useWichTerminal from '../../Hooks/useWichTerminal'
import './../../../scss/custom/custom.scss'
import './Header.scss'

function Header (props) {
  const [logo, setLogo] = useState(props.logo)
  const [classNameSup] = useState(props.classNameSup || '')
  const [isDisplayLogo] = useState(props.isDisplayLogo)
  const { isMobile } = useWichTerminal()
  const [imgBannerBackground] = useState(props.imgBannerBackground)

  useEffect(() => {
    if (_.has(props, 'logo')) {
      setLogo(props.logo)
    }
  }, [props])

  const spinner = () => {
    return (
      <div className="spinner-border text-success spinner" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  const renderLogo = () => {
    return (
      <img src={logo} className={isMobile() ? 'col-12 p-0' : 'img-logo'} alt=""/>
    )
  }

  const displayLogo = () => {
    if (isDisplayLogo) {
      const myLogo = logo ? renderLogo() : spinner()
      return <div className={isMobile() ? '' : 'logoPlace d-flex'}>
        {myLogo}
      < /div>
    }
  }
  return (
    <div className={'HeaderLogo'}>
      <div className="header-logo">
        <div className={`col-12 picture header-picture ${imgBannerBackground} ${classNameSup}`}>
        </div>
        {displayLogo()}
      </div>
    </div>
  )
}

Header.propTypes = {
  logo: PropTypes.string,
  mobile: PropTypes.bool,
  imgBannerBackground: PropTypes.string,
  isDisplayLogo: PropTypes.bool
}

export default Header
