import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'

function ThanksBlock (props) {
  const { getTextForPage, getTextButton, getRouteFor } = useMpecTranslations('coachingParentsZens')
  const [subject] = useState(props.subject || getTextForPage('thanksBlock.text2Thanks'))

  const texts = [
    { text: 'thanksBlock.text1Thanks' },
    { text: subject },
    { text: 'thanksBlock.text3Thanks' }
  ]

  const [title] = useState(props.title || getTextForPage('thanksBlock.titleBlock'))

  const displayTexts = () => {
    return _.map(texts, text => {
      return <p key={uuidv4()} className={'m-0'}>{getTextForPage(text.text, text.variable)}</p>
    })
  }

  return (
    <div className={'col-lg-5 greyBlock mb-5'}>
      <h2 className={'page-subtitle mb-5'}>{title}</h2>
      {displayTexts()}
      <Button
        link={getRouteFor('contact', {}, true)}
        title={_.upperFirst(getTextButton('contactFamily'))}
        classSup={'btnGreen pr-5 pl-5'}
        classSupDiv={'mr-auto ml-auto'}
      />
    </div>
  )
}

ThanksBlock.propTypes = {
  subject: PropTypes.string,
  title: PropTypes.string
}

export default ThanksBlock
