import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import OriginalCard from '../../Common/OriginalCard/OriginalCard'
import TitlePage from '../../Common/TitlePage/TitlePage'
import UnderTitle from '../../Common/UnderTitle/UnderTitle'
import './HomePage.scss'

function HomePage () {
  const source = Axios.CancelToken.source()
  const { getTextCompaniesNames, getTextForPage } = useMpecTranslations('homePage')
  const { getUserData } = useMpecSecure()
  const [company] = useState(getUserData('companyName'))
  const [blocks, setBlocks] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    fetchBlocks().then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchBlocks = async () => {
    try {
      const result = await Axios.post('/api/homePage/blocks/get-data', null, {
        cancelToken: source.token
      })
      const results = result.data
      setBlocks(results)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const allCards = () => {
    return (
      blocks.map((value) => {
        return <div key={uuidv4()} className="col-lg-4 mb-4">
          <OriginalCard
            route={value?.route}
            description={value?.description}
            imgBackground={value?.img}
            title={value?.title}
          />
        </div>
      })
    )
  }

  return (
    <div className="HomePage content-wrapper mb-5">
      <HeaderPage company={company}/>
      <div className="container">
        <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
        <div className="content mb-5">
          <div className="container-fluid">
            <UnderTitle
              text={getTextForPage('description', { company: getTextCompaniesNames(company) })}
            />
            <div className={'row d-flex'}>
              {isLoad ? allCards() : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
