import React, { useState } from 'react'
import useMpecTranslations from '../../../../Tools/translations'
import Button from '../../../Common/Button/Button'
import H3 from '../../../Common/HX/H3'

function FormGazouyi () {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const { getTextForPage, getTextButton } = useMpecTranslations('gazouyi')

  return (
    <form className={"mt-5"}>
      <H3 text={getTextForPage('titleContent3')} className={'size-title regularGreenTitle mb-3 mt-5'}/>
      <p>{getTextForPage('textServices')}</p>
      <div>
        <div className="form-check mt-4">
          <input
            name="acceptedTerms"
            type="checkbox"
            value={acceptedTerms}
            onChange={event => setAcceptedTerms(event.target.value)}
            required
            className="form-check-input"
          />
          <label className="form-check-label littleCondition" htmlFor="flexCheckDefault">
            {getTextForPage('acceptData')}
          </label>
        </div>
        <div className={'text-center mt-5 mb-80'}>
          <Button title={getTextButton('signUp')} className={'btn-fat-green-800 round-corner-25'} classNameButton={''}/>
        </div>
      </div>
    </form>

  )
}

export default FormGazouyi
