import _ from 'lodash'
import useMpecTranslations from '../../../../../Tools/translations'
import useFormsTools from '../../../../Hooks/useFormsTools'

function FormDataStep2 () {
  const { getTextFormsLabels, getTextForPage } = useMpecTranslations('register')
  const { mpecFormHandleChange } = useFormsTools()

  const dataBirthDate = [
    {
      classInput: 'col-12 col-md-12 col-lg-9',
      classLabel: 'col-12 col-md-12 col-lg-3',
      firstDiv: 'row align-items-end',
      id: 'inputAge',
      required: false,
      text: _.upperFirst(getTextFormsLabels('birthDate')),
      type: 'date',
      handleBlur: mpecFormHandleChange,
      formName: 'register'
    }
  ]

  const dataGender = [
    {
      id: 'gender-man',
      name: 'gender',
      text: _.upperFirst(getTextFormsLabels('man')),
      type: 'radio'
    },
    {
      id: 'gender-woman',
      name: 'gender',
      text: _.upperFirst(getTextFormsLabels('woman')),
      type: 'radio'
    }
  ]

  const dataRole = [
    {
      id: 'role-parent',
      name: 'type',
      text: _.upperFirst(getTextFormsLabels('parent')),
      type: 'checkbox'
    },
    {
      id: 'role-grandParent',
      name: 'type',
      text: _.upperFirst(getTextFormsLabels('grandParent')),
      type: 'checkbox'
    }
  ]
  const dataCheckType = [
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-2',
      id: 'activities',
      text: _.upperFirst(getTextForPage('step2.activities')),
      type: 'checkbox'

    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-2',
      id: 'articles',
      text: _.upperFirst(getTextForPage('step2.articles')),
      type: 'checkbox'

    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-3',
      id: 'conference',
      text: _.upperFirst(getTextForPage('step2.conference')),
      type: 'checkbox'

    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-3',
      id: 'adviceAndCoaching',
      text: _.upperFirst(getTextForPage('step2.adviceAndCoaching')),
      type: 'checkbox'

    }
  ]
  const dataCheckAge = [
    {
      classLabel: 'categoryText',
      firstDiv: 'col-4 col-lg-3',
      id: 'pregnancy',
      text: _.upperFirst(getTextForPage('step2.pregnancy')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-4 col-lg-1',
      id: 'zeroThreeYear',
      text: _.upperFirst(getTextForPage('step2.zeroThreeYear')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-4 col-lg-1',
      id: 'threeSixYear',
      text: _.upperFirst(getTextForPage('step2.threeSixYear')),
      type: 'checkbox'

    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-4 col-lg-1',
      id: 'sixElevenYear',
      text: _.upperFirst(getTextForPage('step2.sixElevenYear')),
      type: 'checkbox'

    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-4 col-lg-2',
      id: 'elevenFifteenYear',
      text: _.upperFirst(getTextForPage('step2.elevenFifteenYear')),
      type: 'checkbox'

    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-4 col-lg-2',
      id: 'fifteenEighteenYear',
      text: _.upperFirst(getTextForPage('step2.fifteenEighteenYear')),
      type: 'checkbox'
    }
  ]
  const dataCheckThematic = [
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-4',
      id: 'corporateParenting',
      text: _.upperFirst(getTextForPage('step2.corporateParenting')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-5',
      id: 'accompanyTheChildren',
      text: _.upperFirst(getTextForPage('step2.accompanyTheChildren')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-3',
      id: 'health',
      text: _.upperFirst(getTextForPage('step2.health')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-4',
      id: 'boostingParentingSkills',
      text: _.upperFirst(getTextForPage('step2.boostingParentingSkills')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-4',
      id: 'balanceLife',
      text: _.upperFirst(getTextForPage('step2.balanceLife')),
      type: 'checkbox'
    },
    {
      classLabel: 'categoryText',
      firstDiv: 'col-6 col-lg-4',
      id: 'developingSkills',
      text: _.upperFirst(getTextForPage('step2.developingSkills')),
      type: 'checkbox'
    }
  ]

  return {
    dataBirthDate,
    dataCheckAge,
    dataCheckThematic,
    dataCheckType,
    dataGender,
    dataRole
  }
}

export default FormDataStep2