import React from 'react'
import './ComplementaryGuardCera.scss'
import BabysitorMainPage from '../ComplementaryGuard/babysitor/BabysitorMainPage'

function ComplementaryGuardCera () {
  return (
    <BabysitorMainPage isCera={true}/>
  )
}

export default ComplementaryGuardCera