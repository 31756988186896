import Axios from 'axios'
import _ from 'lodash'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { noPicture } from '../Common/Images/Images'

function usePicturesTools () {
  const [avatar, setAvatar] = useState(noPicture)
  const [companyLogoData, setCompanyLogoData] = useState()
  const [pictureData, setPictureData] = useState(noPicture)

  const displaySpeakers = (speakers, isMobile) => {
    const speakersImg = getAllSpeakers(speakers)
    const classDiv = isMobile ? 'col-lg-12 row mb-4' : 'd-flex col-lg-12 mb-4 row align-items-center'
    const classDivChild = isMobile ? 'mr-auto ml-auto mt-4 mb-4' : 'image col-lg-3 mr-1'
    const classDivChild2 = isMobile ? '' : 'col-lg-8'
    return _.map(speakersImg, data => {
      return (
        <div className={classDiv} key={uuidv4()}>
          <div className={classDivChild}>
            <img
              src={data.imgCloud}
              className="img-circle imgSize"
              alt={data.firstname}
            />
          </div>
          <div className={classDivChild2}>
            <p className={'mb-0 font-weight-bold'}>{data.firstname + ' ' + data.lastname}</p>
            <p>{data.description}</p>
          </div>
        </div>
      )
    })
  }

  const getAllSpeakers = (speakers) => {
    const result = []
    _.forEach(speakers, speaker => {
      result.push(getImgSpeaker(speaker))
    })

    return result
  }

  const getAvatar = (name) => {
    getPicture(name, setAvatar)
  }

  const getCompanyLogo = async (company) => {
    const myPicture = await Axios.post('/api/assets/get-company-logo', { company: _.lowerCase(company) })
    const data = myPicture.data
    if (data !== 'no-picture') {
      setCompanyLogoData(myPicture.data)
    }
  }

  const getImgAWSByFirstnameAndLastname = (firstname, lastname) => {
    if (firstname && lastname) {
      const name = _.lowerCase(firstname.charAt(0)) + _.lowerCase(lastname) + '.jpg'
      return process.env.REACT_APP_LINK_IMG + 'users/' + name
    }
  }

  const getImgSpeaker = (speaker) => {
    const firstname = speaker.user?.firstname
    const lastname = speaker.user?.lastname
    const description = speaker.description

    return {
      firstname,
      lastname,
      description,
      imgCloud: getImgAWSByFirstnameAndLastname(firstname, lastname)
    }
  }

  const getPicture = async (picture, setter) => {
    const myPicture = await Axios.post('/api/assets/get-picture', { picture })
    const data = myPicture.data
    if (data !== 'no-picture') {
      setter(myPicture.data)
    }
  }

  const getProfilePicture = async (picture) => {
    const myPicture = await Axios.post('/api/assets/get-picture', { picture })
    const data = myPicture.data
    if (data !== 'no-picture') {
      setPictureData(myPicture.data)
    }
  }

  return {
    avatar,
    companyLogoData,
    displaySpeakers,
    getAvatar,
    getCompanyLogo,
    getImgAWSByFirstnameAndLastname,
    getProfilePicture,
    pictureData
  }
}

export default usePicturesTools
