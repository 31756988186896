const nurseryPlace = {
  fr: {
    translation: {
      pages: {
        nurseryPlace: {
          anyQuestions: 'Pour toutes questions, maplaceencrèche met à votre disposition un/e référent/e',
          cardMail: 'cecile.fleutiaux@maplaceencreche.com',
          cardNumber: '06 XX XX XX XX',
          cardTitle: 'Pour vous : ',
          conditions: 'Conditions d\'attribution',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
          subTitle: 'Votre service Ressources Humaines met à votre disposition des places en crèche.',
          subTitle2: 'Accueil alternatif',
          text1Bloc1: 'Il n\'y a pas de commission d\'attribution pour l\'instant. Ne tenez pas compte de la limite de date d\'inscription liée à l\'ancienne commission. Vous pouvez toujours vous inscrire. Vous serez alors enregistré en liste d\'attente et nous vous recontacterons en cas de libération d\'une place vous correspondant.',
          text1Bloc2: 'Seuls les salariés VINCI SA remplissant les conditions suivantes sont éligibles :',
          text2Bloc2: '<ul><li>Avoir un enfant de 3 ans ou moins ou attendre un enfant</li><li>Être salarié de VINCI SA en CDI, période d’essai passée</li><li>Demande d’inscription à temps plein ou au 4/5ème</li><li>Demande de place faite auprès de sa mairie et refusée ou liste d’attente (justificatif ?)</li><li>Ne pas être en préavis de départ</li></ul>',
          text3Bloc2: 'Les inscriptions communiquées après le 12 avril 2020 seront prises en compte en cas de nouvelles disponibilités au cours de l’année.',
          text4Bloc2: 'Les dossiers de candidature les plus complets permettront une meilleure attribution des places.',
          text5Bloc2: 'Les informations fournies par le salarié devront être justifiées au moment de l\'inscription définitive. Toute erreur ou omission importante pourra entraîner l\'annulation de la candidature.',
          text6Bloc2: 'Pour tout comprendre du fonctionnement d\'une commission d\'attribution découvrez notre guide',
          text7Bloc2: 'Fin des inscriptions le',
          text8Bloc2:'<strong>20 avril 2020</strong>',
          text1Bloc3: 'Favoriser l’autonomie, respecter le libre agir, privilégier l’organisation coopérative, encourager la créativité... autant de préférences éducatives que vous pouvez avoir en tant que parent. Or, votre adhésion à la pédagogie de la crèche est essentielle à votre sérénité. Il est donc important de pouvoir vous offrir la possibilité de trouver l’approche éducative qui vous correspond. Alors que vous souhaitiez que votre enfant bénéficie d’un accueil Montessori ou que vous aspiriez à un accueil à tendance Pickler-Loczy, nous vous proposons l\'accès à un panel de crèches aux projets innovants. 100% de parents satisfaits, c’est 100% d\'enfants heureux !',
          text2Bloc3: 'Discutez-en avec votre référente famille !',
          title: 'Places en crèches'
        }
      }
    }
  }
}

export { nurseryPlace }
