const conferenceInscription = {
  fr: {
    translation: {
      pages: {
        conferenceInscription: {
          activation: 'chargement de la page',
          physicalConditions: 'J\'autorise que mes données soient transmises à l\'organisateur de l\'événement afin de pouvoir y assister. Par ailleurs, je reconnais que ma présence à l\'événement physique est obligatoire.',
          remoteConditions: 'J\'autorise que mes données soient transmises à l\'organisateur de l\'événement afin de pouvoir y assister.',
          distance: 'à distance',
          physical: 'en physique',
          subtitle2: 'conférence animée par',
          subtitle3: 'Mardi 30 septembre 2020',
          text3: 'Les inscriptions sont ouvertes jusqu\'au {{dateOpening}}',
          text4: 'Places limitées aux {{inscriptions}} premiers inscrits',
          text5: 'La conférence aura lieu :',
          title: 's\'inscrire à la conférence',
          titleInfos: 'information sur la conférence',
          greyBlock: {
            closeRegistration: 'Les inscriptions sont ouvertes jusqu\'au {{closeRegistrationDate}}',
            closeRegistrationFuture: 'Les inscriptions seront ouvertes jusqu\'au {{closeRegistrationDate}}',
            confirmedRegister: 'inscription événement - confirmée',
            DDOMessage: 'Votre inscription a bien été prise en compte. Vous allez recevoir un email de notre part à l’adresse <a href="mailto:{{mail}}">{{mail}}</a>. Vous y trouverez les détails pour pouvoir participer à la conférence. Pensez à vérifier dans vos spams. Si besoin, contactez votre chargée de relation famille.',
            DDOSubject: '{{customerName}}: plus de place sur la conférence/l’atelier ',
            errorMessage: 'Vous devez certifier que vous avez été informé que votre présence à l\'évènement est obligatoire',
            eventFull: 'Il n’y a plus de place disponible pour la conférence. Vous avez été inscrit en liste d’attente. Nous reviendrons vers vous si des places se libèrent.',
            firstPart: 'première partie',
            limitedPersons: 'Places limitées aux {{limitedPersons}} premiers inscrits',
            physical: 'sur place',
            remote: 'à distance',
            secondPart: 'seconde partie',
            titleWorkshop: 'dates de l\'atelier',
            userAlreadySubscribe: 'Vous avez déjà bénéficié de ce service. Si besoin, contactez votre référente famille.',
            waitingList: 'inscription événement - en attente',
            workshop: 'l\'atelier'
          },
          workshopInscription: {
            animate: 'atelier animée par',
            participate: 'l’atelier aura lieu :',
            title: 's\'inscrire à l\'atelier',
            titleInfos: 'information sur l\'atelier'
          }
        }
      }
    }
  }
}

export { conferenceInscription }
