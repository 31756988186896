import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../Tools/translations'
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import OriginalCard from '../../Common/OriginalCard/OriginalCard'
import TitlePage from '../../Common/TitlePage/TitlePage'
import UnderTitle from '../../Common/UnderTitle/UnderTitle'
import './ParentsLife.scss'

const _ = require('lodash')

function ParentsLife () {
  const source = Axios.CancelToken.source()
  const [blocks, setBlocks] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const pageName = 'parentsLife'
  const { getRouteFor, getTextForPage, getTextInSpecificLanguage } = useMpecTranslations('parentsLife')

  useEffect(() => {
    fetchBlocks().then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allCards = () => {

    return (
      blocks.map((value) => {
        const categoryTranslate = getTextInSpecificLanguage('fr', 'paramsUrl', value.route)
        return <div key={uuidv4()} className="col-lg-4 mb-3">
          <OriginalCard
            route={'parentsLifeCategory'}
            routeParam={{ category: categoryTranslate }}
            imgBackground={value?.img}
            title={value?.title}
          />
        </div>
      })
    )
  }

  const fetchBlocks = async () => {
    try {
      const result = await Axios.post('/api/parents/blocks/get-data', null, {
        cancelToken: source.token
      })
      const results = result.data

      results.unshift(results.pop())
      setBlocks(results)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const links = [
    {
      link: '/',
      title: 'Accueil',
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: getRouteFor(pageName),
      title: _.upperFirst(getTextForPage('title')),
      icon: '',
      className: null,
      isActive: true,
      isLast: true
    }
  ]

  return (
    <div className="BodyParentsLife content-wrapper">
      <HeaderPage imgBannerBackground={'parentsLife'} classNameSup={'center'}/>
      <BreadCrumb links={links} className={'mt-4 mb-3 pl-4'}/>
      <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
      <UnderTitle text={_.upperFirst(getTextForPage('description'))}/>
      <div className="content">
        <div className="container-fluid">
          <div className={'row d-flex flex-row justify-content-around'}>
            {isLoad ? allCards() : <div></div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParentsLife
