import { Checkbox } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import H3 from '../../../../Common/HX/H3'
import useFormsTools from '../../../../Hooks/useFormsTools'
import FormDataStep2 from './FormDataStep2'

function Step2 (props) {
  const { getText, getTextForPage, getTextButton } = useMpecTranslations('register')
  const { allCheckBoxOrRadio, allInputs, mpecFormHandleChange } = useFormsTools()
  const { dataBirthDate } = FormDataStep2()
  const [contentTypes, setContentTypes] = useState([])
  const [genders, setGenders] = useState([])
  const [roles, setRoles] = useState([])
  const [slicesOfLife, setSlicesOfLife] = useState([])
  const [thematics, setThematics] = useState([])

  useEffect(() => {
    if (_.has(props, 'paramsForm')) {
      setContentTypes(_.get(props, 'paramsForm.contentTypes', []))
      setGenders(_.get(props, 'paramsForm.genders', []))
      setRoles(_.get(props, 'paramsForm.roles', []))
      setSlicesOfLife(_.get(props, 'paramsForm.slicesOfLife', []))
      setThematics(_.get(props, 'paramsForm.thematics', []))
    }
  }, [props])

  const renderDataForm = (
    classDiv,
    formData,
    title,
    classTile = 'categoryTitle',
    col1 = 'col-12',
    col2 = 'col-12'
  ) => {
    return <>
      <div className={`${col1}`}>
        <H3 text={_.upperFirst(getTextForPage(title))} className={`${classTile}`}/>
      </div>
      <div className={`${col2} mb-3`}>
        <div className={`row ${classDiv} col-12`}>
          {allCheckBoxOrRadio(formData)}
        </div>
      </div>
    </>
  }

  const renderCheckGender = () => {
    if (genders.length > 0) {
      const configs = []
      _.map(genders, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'genders-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            classLabel: 'categoryText',
            firstDiv: 'col-12 col-md-6 col-lg-4',
            id: id,
            type: 'radio',
            name: 'genders[]',
            text: _.upperFirst(getText('myProfile.inputInformation.'.concat(text))),
            value: parseInt(value),
            'handleChange': mpecFormHandleChange,
            formName: 'register'
          }
        )
      })
      return renderDataForm('', configs, 'iAm', 'page-subtitle-little-grey600', 'col-lg-3 col-sm-12', 'col-lg-9 col-sm-12')
    }
  }

  const renderCheckRole = () => {
    if (roles.length > 0) {
      const configs = []
      _.map(roles, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'roles-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            classLabel: 'categoryText',
            firstDiv: 'col-12 col-md-6 col-lg-4',
            id: id,
            type: 'checkbox',
            name: 'roles[]',
            text: _.upperFirst(getText('myProfile.inputInformation.'.concat(text))),
            value: parseInt(value),
            'handleChange': mpecFormHandleChange,
            formName: 'register',
            isArray: true
          }
        )
      })
      return renderDataForm('', configs, 'role', 'page-subtitle-little-grey600', 'col-lg-3 col-sm-12', 'col-9')
    }
  }

  const renderContentType = () => {
    if (contentTypes.length > 0) {
      const configs = []
      _.map(contentTypes, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'contentType-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            classLabel: 'categoryText',
            firstDiv: 'col-12 col-md-6 col-lg-4',
            id: id,
            type: 'checkbox',
            name: 'contentTypes[]',
            text: _.upperFirst(getText('myProfile.checkboxInformation.'.concat(text))),
            value: parseInt(value),
            'handleChange': mpecFormHandleChange,
            formName: 'register',
            isArray: true
          }
        )
      })
      return renderDataForm('', configs, 'step2.contentType', 'page-subtitle-little-grey600', 'col-lg-3 col-sm-12', 'col-9')
    }
  }

  const renderCheckAge = () => {
    if (slicesOfLife.length > 0) {
      const configs = []
      _.map(slicesOfLife, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'slicesOfLife-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            classLabel: 'categoryText',
            firstDiv: 'col-12 col-md-6 col-lg-4',
            id: id,
            type: 'checkbox',
            name: 'slicesOfLife[]',
            text: _.upperFirst(getText('myProfile.checkboxInformation.'.concat(text))),
            value: parseInt(value),
            'handleChange': mpecFormHandleChange,
            formName: 'register',
            isArray: true
          }
        )
      })
      return renderDataForm('', configs, 'step2.sliceOfLife', 'page-subtitle-little-grey600', 'col-lg-3 col-sm-12', 'col-9')
    }
  }

  const renderCheckThematic = () => {
    if (thematics.length > 0) {
      const configs = []
      _.map(thematics, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'thematics-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            classLabel: 'categoryText',
            firstDiv: 'col-12 col-md-6 col-lg-4',
            id: id,
            type: 'checkbox',
            name: 'thematics[]',
            text: _.upperFirst(getText('myProfile.checkboxInformation.'.concat(text))),
            value: parseInt(value),
            'handleChange': mpecFormHandleChange,
            formName: 'register',
            isArray: true
          }
        )
      })
      return renderDataForm('', configs, 'step2.theme', 'page-subtitle-little-grey600', 'col-lg-3 col-sm-12', 'col-9')
    }
  }

  return (
    <>
      <div className={'mb-4'}>
        <H3 text={_.upperFirst(getTextForPage('step2.subTitle'))} className={'fs-3 underTitle text-left'}/>
      </div>
      {allInputs(dataBirthDate)}
      <div className={'row'}>
        {renderCheckGender()}
      </div>
      <div className={'row'}>
        {renderCheckRole()}
      </div>
      <div className={'row'}>
        <div className={'col-12'}>
          <H3 text={_.upperFirst(getTextForPage('step2.interest'))} className={'font-weight-bold titleInterest fs-6 '}/>
        </div>
        {renderContentType()}
        {renderCheckAge()}
        {renderCheckThematic()}
      </div>
      <div className={'row pt-3'}>
        <div className={'col-12 d-flex align-items-start'}>
          <Checkbox
            className={'p-0 mr-2'}
            color={'primary'}
            id={'politicCheck'}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            required={true}
          />
          <p className={'fs-7 m-auto'}>
            {getTextForPage('step2.cgu')}
          </p>
        </div>
      </div>
      <div className={'row justify-content-center'}>
        <Button className={'btn p-3 mt-5 mb-5 btnGreenLight'}
                id={'submitStep2'}
                isButtonWithAction={true}
                name={'submitStep2'}
                title={_.toUpper(getTextButton('createAccount'))}
                type={'submit'}/>
      </div>
    </>
  )
}

Step2.propTypes = {
  formParams: PropTypes.object
}

export default Step2