const login = {
  fr: {
    translation: {
      pages: {
        login: {
          comingSoon: 'bientôt disponible !!!',
          forgotPassword: 'mot de passe oublié',
          firstConnection: 'première connexion',
          iHaveMyAccount: 'j\'ai déjà créé mon compte',
          subtitle: 'connectez-vous pour accéder à votre espace Les Parents Zens',
          title: 'bonjour !',
          underTitle: 'j\'ai déjà mon compte',
          activation: {
            titleAnErrorOccurred: 'une erreur s\'est produite',
            successfulActivationYouCanKnowConnectToTheApplication: 'Votre compte a été créé avec succès ! Bonne exploration de votre heyparentszen !',
            theGivenActivationCodeIsNotValidPleaseCheckYourMailbox: 'Oops ! Il semble que nous ayons rencontré une erreur lors de l\'activation de votre compte. Merci de nous contacter par mail ({{mailContact}}).',
            titleActivateAccount: 'compte activé',
            weActivateYourAccount: 'encore un peu de patience, nous activons votre compte.'
          },
          changeConfirm: {
            ifNotReceived: 'si vous ne l\'avez pas reçu, pensez à vérifier dans vos spams avant de contacter l\'équipe maplaceencrèche à contact@maplaceencreche.com.',
            title: 'j\'ai oublié mon mot de passe',
            weWillSendEmail: 'nous allons vous envoyer un mail sur votre professionnelle, avec un mot de passe temporaire.'
          },
          error: {
            badCredentials: 'les identifiants sont incorrects',
            noConfiguredEnvironment: 'votre compte est en anomalie, vous n\'avez aucun environnement de configuré. Contactez votre département rh',
            youAlreadyHaveAPendingRequestPleaseContactUsByMailYourMailContactYouDidntGetYourEmail: 'vous avez déjà une demande de changement de passe en cours, merci de nous contacter par mail ({{mailContact}}) si vous n\'avez pas reçu votre email',
            youAreNotAllowedToUseThisApplication: 'vous n\'êtes pas authorisé à utiliser cette application',
            youAreNotAllowedToUseThisApplicationYourAccountIsNotActive: 'vous n\'êtes pas authorisé à utiliser cette application, votre compte n\'est pas activé.'
          },
          forgot: {
            backToConnection: 'retour à la page de connexion',
            title: 'j\'ai oublié mon mot de passe',
            userMailLabel: 'merci de renseigner votre mail professionnel',
            validEmail: 'merci de renseigner un email valide'
          },
          formLogin: {
            userNameLabel: 'mon email professionnel',
            userPasswordLabel: 'mon mot de passe'
          },
          header: {
            login: 'connexion'
          },
          success: {
            welcomeBack: 'bon retour {{firstname}}'
          }
        }
      }
    }
  }
}

export { login }