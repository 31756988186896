import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './UnderTitle.scss'

function UnderTitle (props) {
  const [text] = useState(props.text)

  return (
    <div className={'underTitle text-center mb-5 ml-2 mr-2'}>
      {text}
    </div>
  )
}

UnderTitle.propTypes = {
  text: PropTypes.any
}

export default UnderTitle
