import _ from 'lodash'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useMpecTranslations from '../../../Tools/translations'
import TitlePage from '../../Common/TitlePage/TitlePage'
import FirstBlock from './partials/FirstBlock/FirstBlock'
import SecondBlock from './partials/SecondBlock/SecondBlock'
import './SearchResults.scss'

function SearchResults () {
  const { getTextForPage } = useMpecTranslations('searchResults')
  const [currentFilters, setCurrentFilters] = useState({})
  const location = useLocation()
  const searchInputHeader = location.state?.searchInput
  const [searchInput, setSearchInput] = useState(searchInputHeader || '')

  const addFilterOrRemove = (filter, isParentFilter, parentFilter) => {
    if (isParentFilter) {
      if (!_.has(currentFilters, filter)) {
        currentFilters[filter] = []
        setCurrentFilters(prevState => ({ ...prevState, ...currentFilters }))
      } else {
        delete currentFilters[filter]
        setCurrentFilters(prevState => ({ ...prevState, ...currentFilters }))
      }
    } else {
      if (currentFilters[parentFilter].includes(filter)) {
        const newCurrentFilters = currentFilters[parentFilter].filter(selectedFilter => selectedFilter !== filter)
        currentFilters[parentFilter] = newCurrentFilters
        setCurrentFilters(prevState => ({ ...prevState, ...currentFilters }))
      } else {
        if (!_.has(currentFilters, parentFilter)) {
          currentFilters[parentFilter] = []
        }
        const newCurrentFilters = currentFilters
        newCurrentFilters[parentFilter].push(filter)
        setCurrentFilters(prevState => ({ ...prevState, ...newCurrentFilters }))
      }
    }
  }

  return (
    <div className={'content-wrapper SearchResults'}>
      <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
      <div className="col-lg-10 row ml-auto mr-auto">
        <FirstBlock addFilterOrRemove={addFilterOrRemove} setSearch={setSearchInput} mySearch={searchInputHeader} />
        <SecondBlock currentFilters={currentFilters} searchInput={searchInput}/>
      </div>
    </div>
  )
}

export default SearchResults
