const blocParentsLife = {
  fr: {
    translation: {
      pages: {
        blocParentsLife: {
          thirdPanel: {
            loading: 'En chargement',
            audioComponent: {
              error: 'your browser does not support the <code>audio</code> element.'
            },
          },
          home: 'Accueil',
          moreArticles: 'VOIR PLUS D\'ARTICLES',
          moreActivities: 'VOIR PLUS D\'ACTIVITÉS',
          moreVideos: 'voir plus de vidéos',
          pregnancy: 'grossesse',
          noContent: "De nouveaux contenus sont à venir prochainement.",
          text1: 'Infos zens',
          text2: 'Moments zens',
          text3: 'médias zens',
          titleParentsLife: 'Vie de parents'
        }
      }
    }
  }
}

export { blocParentsLife }
