import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../Tools/translations'
import Button from '../../../Common/Button/Button'
import H2 from '../../../Common/HX/H2'
import H3 from '../../../Common/HX/H3'

function PageSelected (props) {
  const { getTextForPage } = useMpecTranslations('ourSelectedPartners')
  const [active] = useState(props.active || '')
  const [firstBlock] = useState(props.firstBlock)
  const [img] = useState(props.img)
  const [isMultipleImage] = useState(props.isMultipleImage)
  const [link] = useState(props.link)
  const [page] = useState(props.page)
  const [secondBlock] = useState(props.secondBlock)
  const [secondTitle] = useState(props.secondTitle)
  const [title] = useState(props.title)

  const displayFirstBlock = () => {
    return (
      <Fragment>
        {ReactHtmlParser(firstBlock)}
      </Fragment>
    )
  }

  const displayImg = () => {
    const imgAws = process.env.REACT_APP_LINK_IMG + img
    let image = <img src={imgAws} alt={img}/>
    if (isMultipleImage) {
      image = _.map(img, element => {
        const imgAws = process.env.REACT_APP_LINK_IMG + element
        return <img key={uuidv4()} src={imgAws} alt={element}/>
      })
    }

    return (
      <div className={'col-12 col-sm-4 d-flex justify-content-center align-items-center flex-sm-column text-center mt-2 mb-3'}>
        {image}
      </div>
    )
  }

  const displaySecondBlock = () => {
    return (
      <Fragment>
        <H2 className={'subtitleOther'} text={secondTitle}/>
        {ReactHtmlParser(secondBlock)}
      </Fragment>
    )
  }

  const displayPage = () => {
    if (isMultipleImage) {
      return (
        <div className="d-flex col-lg-12 justify-content-between align-items-center row">
          <div className={'col-lg-8'}>
            {displayFirstBlock()}
            {displaySecondBlock()}
          </div>
          {displayImg()}
        </div>
      )
    }

    return (
      <Fragment>
        <div className="d-flex col-lg-12 justify-content-between align-items-center row">
          <div className={'col-lg-8'}>
            {displayFirstBlock()}
          </div>
          {displayImg()}
        </div>
        {displaySecondBlock()}
      </Fragment>
    )
  }

  return (
    <div className={_.upperFirst(page) + ' tab-pane fade show container ' + active} id={'nav-' + page} role="tabpanel" aria-labelledby={'nav-' + page + '-tab'}>
      <H3 className={'text-center page-subtitle-middle-grey600 mb-4'} text={title}/>
      {displayPage()}
      <Button
        classSup={'btnGreen'}
        link={link}
        target={'_blank'}
        title={_.upperFirst(getTextForPage('discover'))}
      />
    </div>
  )
}

PageSelected.propTypes = {
  firstBlock: PropTypes.string,
  img: PropTypes.any,
  isMultipleImage: PropTypes.bool,
  link: PropTypes.string,
  page: PropTypes.string,
  secondBlock: PropTypes.string,
  secondTitle: PropTypes.string,
  title: PropTypes.string
}

export default PageSelected
