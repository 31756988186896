import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../../Tools/translations'

function LegalInformationArticle02 () {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'container article'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <h3 className={'col-12'}>{getTextForPage('article2.title')}</h3>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article2.editor'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article2.company'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article2.site'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article2.user'))}</p>
        </div>
      </div>
    </div>
  )
}

export default LegalInformationArticle02
