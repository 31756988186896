const activity = {
  fr: {
    translation: {
      pages: {
        activity: {
          activity: 'Activité',
          home: 'Accueil',
          titleParentsLife: 'Vie de parents',
        }
      }
    }
  }
}

export { activity }
