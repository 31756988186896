import React from 'react'
import './ReplayConference.scss'
import Replay from '../Replay'

function ReplayConference () {
  return (
    <div className={'ReplayConference'}>
      <Replay isWorkshop={false}/>
    </div>
  )
}

export default ReplayConference
