import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import validator from 'validator'
import useMpecSecure from '../../../../Tools/secure'
import useMpecTranslations from '../../../../Tools/translations'
import Button from '../../../Common/Button/Button'

function FormForgot ({ setRender }) {
  const [userEmail, setUserEmail] = useState('')
  const { getTextButton, getTextForPage } = useMpecTranslations('login')
  const { createChangeRequestPassword } = useMpecSecure()

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validator.isEmail(userEmail)) {
      const result = await createChangeRequestPassword(userEmail)
      if (_.has(result, 'hasError')) {
        toast.error(getTextForPage('error.'.concat(result.textToDisplay), { mailContact: `${process.env.REACT_APP_CONTACT_MAIL}` }), {
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-center',
          progress: undefined,
          theme: 'colored'
        })
      } else {
        setRender('changeConfirmation')
      }
    } else {
      toast.error(_.upperFirst(getTextForPage('forgot.validEmail')))
    }
  }

  const handleChangeUserMail = (event) => {
    event.preventDefault()
    setUserEmail(event.target.value)
  }

  return (
    <form className={'col-12 col-sm-6 d-flex align-items-start flex-column mx-auto'} onSubmit={event => handleSubmit(event)}>
      <div className="form-group col-12">
        <label htmlFor="userMail">{_.upperFirst(getTextForPage('forgot.userMailLabel'))}</label>
        <input
          className="form-control form-control-sm rounded"
          id={'userMail'}
          name={'userMail'}
          onChange={handleChangeUserMail}
          required
          value={userEmail}
          autoComplete={'true'}
        />
      </div>
      <div className={'center col d-flex flex-row justify-content-center mt-4 mb-4'}>
        <Button
          className={'btn btnGreenLight p-3'}
          isButtonWithAction={true}
          title={_.toUpper(getTextButton('forgot.sendTemporaryPassword'))}
          type={'submit'}
        />
      </div>
    </form>
  )
}

FormForgot.propTypes = {
  setRender: PropTypes.func.isRequired
}

export default FormForgot
