import React from 'react'
import useMpecTranslations from '../../../../Tools/translations'

function LegalInformationArticle01 () {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'container article'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <h3 className={'col-12'}>{getTextForPage('article1.title')}</h3>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{getTextForPage('article1.objectOfTheSite')}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{getTextForPage('article1.objectOfTheCompany')}</p>
        </div>
      </div>
    </div>
  )
}

export default LegalInformationArticle01
