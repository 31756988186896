import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'

function useCompaniesTools () {
  const [contactInformations, setContactInformations] = useState({})
  const [contactSalesInformation, setContactSalesInformation] = useState({})

  const getContactInformation = async (company) => {
    const contactInfos = await Axios.post('/api/contacts/ops', { company: _.lowerCase(company) })
    if (!_.isEmpty(contactInfos.data)) {
      setContactInformations(contactInfos.data)
    }

    return contactInfos
  }

  const getContactSalesInformation = async (company) => {
    const contactSalesInfos = await Axios.post('/api/contacts/sales', { company: _.lowerCase(company) })
    if (!_.isEmpty(contactSalesInfos.data)) {
      setContactSalesInformation(contactSalesInfos.data)
    }

    return contactSalesInfos
  }

  return {
    contactInformations,
    contactSalesInformation,
    getContactInformation,
    getContactSalesInformation,
    setContactInformations,
    setContactSalesInformation
  }
}

export default useCompaniesTools
