import { dom } from '@fortawesome/fontawesome-svg-core'
import { faCommentDots, faEnvelope, faImage, faStar, faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { Fragment, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecSecure from '../../../../Tools/secure'
import useMpecTranslations from '../../../../Tools/translations'
import useMpecServices from '../../../Hooks/useMpecServices'
import useNavigationTools from '../../../Hooks/useNavigationTools'
import usePicturesTools from '../../../Hooks/usePicturesTools'

function Aside() {
    const {getRouteFor, getTextForHeader, getTextParamsUrl} = useMpecTranslations()
    const {isActiveRoute, isOpen} = useNavigationTools()
    const {currentEnv} = useMpecServices()
    const {convertServiceName, getHeaderNameForService, getListOfServices, myServices} = useMpecServices()
    const {getUserData} = useMpecSecure()
    const {getProfilePicture, getCompanyLogo, companyLogoData} = usePicturesTools()
    const name = {
      firstname: getUserData('firstname'),
      lastname: _.upperFirst(getUserData('lastname'))
    }

    const getName = () => {
      const lengthName = `${name.firstname} ${name.lastname}`

      if (lengthName.length > 30) {
        return (
          <Fragment>
            <span className="d-block identity">{name.firstname}</span>
            <span className="d-block identity">{name.lastname}</span>
          </Fragment>
      )
      } else {
        return (
          <span
            className="d-block identity">{name.firstname} {name.lastname}</span>
        )
      }
    }

    useEffect(() => {
        getCompanyLogo(currentEnv).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEnv])

    const patchTreeview = () => {
        const $ = window.$
        const trees = $("ul[data-widget=\"treeview\"]")
        trees.Treeview("init")
    }

    useEffect(() => {
        patchTreeview()
        hideOpenMenuButton()
        const picture = getUserData("picture")
        getProfilePicture(picture).then()
        getListOfServices().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const displayProtectedMenu = () => {
        if (myServices) {
            if (myServices.length === 0) {
                return ""
            } else {
                const menuList = []
                menuList.push(
                    <li className="nav-item ml-4" key={uuidv4()}>
                        <a href={getRouteFor("myJourney", {}, true)}
                           className={"nav-link".concat(isActiveRoute(getRouteFor("myJourney", {}, true)))}>
                            <p>{getTextForHeader("myJourney")}</p>
                        </a>
                    </li>,
                )
                _.map(myServices, (service) => {
                    const nameConvert = convertServiceName(service._name)
                    menuList.push(
                        <li className="nav-item ml-4" key={uuidv4()}>
                            <a href={getRouteFor(nameConvert, {}, true)}
                               className={"nav-link".concat(isActiveRoute(getRouteFor(nameConvert, {}, true)))}>
                                <p>{_.upperFirst(getTextForHeader(getHeaderNameForService(service._name)))}</p>
                            </a>
                        </li>,
                    )
                })

                return (
                    <li className={"nav-item has-treeview".concat(isOpen(getRouteFor("livingParenthoodCompany", {}, true)))}>
              <span
                  className={"nav-link ".concat(isActiveRoute(getRouteFor("livingParenthoodCompany", {}, true), true))}>
                <p>
                  <FontAwesomeIcon icon={faStar} className={"nav-icon"}/>
                    {getTextForHeader("livingParenthoodCompany")}
                </p>
              </span>
                        <ul className="nav nav-treeview">
                            {menuList}
                        </ul>
                    </li>
                )
            }
        }
    }

    const showOpenMenuButton = () => {
        const $ = window.$
        $('#closeMenu').PushMenu('expand')
        $("#openMenu").show()
    }

    const hideOpenMenuButton = () => {
        const $ = window.$
        $(document).on('shown.lte.pushmenu', function () {
            $("#openMenu").hide()
        })
    }

    return (
        <aside className="main-sidebar sidebar-light-lime elevation-4 Aside">
            <div className={"row limitBorder"}>
                <div className="col-11 brand-link w-100 d-flex justify-content-center">
                    <img src={companyLogoData} alt="Parentszens" className="brand-image"/>
                </div>
                <div className={'col-1 pt-1 d-flex justify-content-end'}>
                    <span id={'closeMenu'} data-widget="pushmenu" role="button">
                        <FontAwesomeIcon className={'mr-1'} icon={faTimes} onClick={showOpenMenuButton}/>
                    </span>
                </div>
            </div>
            {/*<!-- Sidebar -->*/}
            <div className="sidebar row">
                {/*<!-- Sidebar user panel (optional) -->*/}
                <div className="user-panel mt-3 pb-3 mb-3 w-100 d-flex justify-content-center">
                    <div className="info text-center">
                      {getName()}
                    </div>
                </div>

                {/*<!-- Sidebar Menu -->*/}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="true">
                        {/*<!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->*/}
                        {displayProtectedMenu()}
                        <li className={"nav-item has-treeview".concat(isOpen(getRouteFor("parentsLife", {}, true)))}>
              <span className={"nav-link ".concat(isActiveRoute(getRouteFor("parentsLife", {}, true), true))}>
                <p>
                   <FontAwesomeIcon icon={faImage} className={"nav-icon"}/>
                    {getTextForHeader("parentingToolkit")}
                    {isActiveRoute(getRouteFor("parentsLife", {}, true), true)}
                </p>
              </span>
                            <ul className="nav nav-treeview">
                                {/*A supprimer lors de l'activation du fil d'arianne*/}
                                <li className="nav-item ml-4">
                                    <a href={getRouteFor("parentsLife", {}, true)}
                                       className={"nav-link".concat(isActiveRoute(getRouteFor("parentsLife", {}, true)))}>
                                        <p>{getTextForHeader("toolkit")}</p>
                                    </a>
                                </li>
                                {/*Fin de : A supprimer lors de l'activation du fil d'arianne*/}
                                <li className={'nav-item ml-4'.concat(isActiveRoute(getRouteFor('parentsLifeCategory', {category: getTextParamsUrl('pregnancy')}, true)))}>
                                    <a href={getRouteFor("parentsLifeCategory", {category: getTextParamsUrl("pregnancy")}, true)}
                                       className="nav-link">
                                        <p>{getTextForHeader("pregnancy")}</p>
                                    </a>
                                </li>
                                <li className={"nav-item ml-4".concat(isActiveRoute(getRouteFor("parentsLifeCategory", {category: "0-3"}, true)))}>
                                    <a href={getRouteFor("parentsLifeCategory", {category: "0-3"}, true)}
                                       className="nav-link">
                                        <p>{getTextForHeader("zeroThreeYear")}</p>
                                    </a>
                                </li>
                                <li className={"nav-item ml-4".concat(isActiveRoute(getRouteFor("parentsLifeCategory", {category: "3-6"}, true)))}>
                                    <a href={getRouteFor("parentsLifeCategory", {category: "3-6"}, true)}
                                       className="nav-link">
                                        <p>{getTextForHeader("threeSixYear")}</p>
                                    </a>
                                </li>
                                <li className={"nav-item ml-4".concat(isActiveRoute(getRouteFor("parentsLifeCategory", {category: "6-11"}, true)))}>
                                    <a href={getRouteFor("parentsLifeCategory", {category: "6-11"}, true)}
                                       className="nav-link">
                                        <p>{getTextForHeader("sixElevenYear")}</p>
                                    </a>
                                </li>
                                <li className={"nav-item ml-4".concat(isActiveRoute(getRouteFor("parentsLifeCategory", {category: "11-15"}, true)))}>
                                    <a href={getRouteFor("parentsLifeCategory", {category: "11-15"}, true)}
                                       className="nav-link">
                                        <p>{getTextForHeader("elevenFifteenYear")}</p>
                                    </a>
                                </li>
                                <li className={"nav-item ml-4".concat(isActiveRoute(getRouteFor("parentsLifeCategory", {category: "15-18"}, true)))}>
                                    <a href={getRouteFor("parentsLifeCategory", {category: "15-18"}, true)}
                                       className="nav-link">
                                        <p>{getTextForHeader("fifteenEighteenYear")}</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href={getRouteFor("inspirationalArticles", {}, true)}
                               className={"nav-link".concat(isActiveRoute(getRouteFor("inspirationalArticles", {}, true), true))}>
                                <p>
                                    <FontAwesomeIcon icon={faCommentDots} className={"nav-icon"}/>
                                    {getTextForHeader("inspirationalArticles")}
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={getRouteFor("contact", {}, true)}
                               className={"nav-link".concat(isActiveRoute(getRouteFor("contact", {}, true), true))}>
                                <FontAwesomeIcon icon={faEnvelope} className={"nav-icon"}/>
                                <p>
                                    {getTextForHeader("contact")}
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={getRouteFor("myProfile", {}, true)}
                               className={"nav-link".concat(isActiveRoute(getRouteFor("myProfile", {}, true), true))}>
                                <FontAwesomeIcon icon={faUserCircle} className={"nav-icon"}/>
                                <p>
                                    {getTextForHeader("myProfile")}
                                </p>
                            </a>
                        </li>
                    </ul>
                </nav>
                {/*<!-- /.sidebar-menu -->*/}
            </div>
            {/*<!-- /.sidebar -->*/}
        </aside>
    )
}

dom.watch()

export default Aside
