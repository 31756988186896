import _ from 'lodash'
import useMpecTranslations from '../../../../../Tools/translations'
import useFormsTools from '../../../../Hooks/useFormsTools'

function FormDataStep1 () {
  const { getTextFormsLabels } = useMpecTranslations('register')
  const { mpecFormHandleChange } = useFormsTools()

  const dataGeneralInformation = [
    {
      classLabel: 'required',
      id: 'inputLastname',
      required: true,
      text: _.upperFirst(getTextFormsLabels('lastname')),
      type: 'text',
      handleChange: mpecFormHandleChange,
      formName: 'register'
    },
    {
      classLabel: 'required',
      id: 'inputFirstName',
      required: true,
      text: _.upperFirst(getTextFormsLabels('firstName')),
      type: 'text',
      handleChange: mpecFormHandleChange,
      formName: 'register'
    },
    {
      classLabel: 'required',
      id: 'inputEmail',
      required: true,
      text: _.upperFirst(getTextFormsLabels('emailPro')),
      type: 'email',
      handleChange: mpecFormHandleChange,
      formName: 'register',
      refreshMultipleCompanies: true
    },
    {
      classLabel: 'required',
      id: 'multipleCompanies',
      required: true,
      text: _.upperFirst(getTextFormsLabels('emailPro')),
      type: 'radio',
      handleChange: mpecFormHandleChange,
      formName: 'register',
      classTitle: '',
      col1: 'col-120',
      col2: 'col-120'
    },
    {
      classLabel: 'required',
      id: 'inputTel',
      required: true,
      text: _.upperFirst(getTextFormsLabels('phoneNumber')),
      type: 'tel',
      handleChange: mpecFormHandleChange,
      formName: 'register'
    },
    {
      classLabel: 'requiredPassword',
      id: 'inputPassword',
      required: true,
      text: _.upperFirst(getTextFormsLabels('password')),
      type: 'password',
      handleChange: mpecFormHandleChange,
      formName: 'register'
    },
    {
      classLabel: 'required',
      id: 'inputConfirmPassword',
      required: true,
      text: _.upperFirst(getTextFormsLabels('confirmPassword')),
      type: 'password',
      handleChange: mpecFormHandleChange,
      formName: 'register'
    }
  ]

  return {
    dataGeneralInformation
  }
}

export default FormDataStep1