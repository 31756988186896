import React, { useState } from 'react'
import useMpecTranslations from '../../../../../../../Tools/translations'
import _ from 'lodash'
import './MediaComponent.scss'
import Button from "../../../../../../Common/Button/Button";

function MediaComponent (props) {
    const { getTextButton, getTextFilter } = useMpecTranslations('blocParentsLife')
    const [img] = useState(props.img || "photo_podcast")
    const [media] = useState(props.media)


    return (
        <div className="col-lg-4">
            <div className="MediaComponent card">
                <div className={`${img} picture contentAudio AudioContainer player`}>
                </div>
                <div className={'text-center mt-4'}>
                    <p className={'font-weight-bold'}>{_.upperFirst(media.title)}</p>
                    <p>{_.upperFirst(getTextFilter(media.type))}</p>
                    <Button title={_.upperFirst(getTextButton('listenPodcast'))} link={media.link} target={"_blank"}/>
                </div>
            </div>
        </div>
    )
}

export default MediaComponent
