const contact = {
  fr: {
    translation: {
      pages: {
        contact: {
          address: '87 rue La Boétie 75008 Paris',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu',
          mail: 'cecile.fleutiaux@maplaceencreche.com',
          message: 'message',
          name: 'Cécile Fleutiaux',
          number: '06 XX XX XX XX',
          subTitle: 'pour toutes questions, Les Parents Zens met à votre disposition un/e référent/e famille',
          subTitle2: 'ecrivez-moi directement',
          successfulSending: 'Votre message a été transmis à votre référente famille qui reviendra vers vous dans les plus brefs délais.',
          teamLPZ: 'L\'équipe lesparentszens',
          title: 'une question ? Contactez-moi !'
        }
      }
    }
  }
}

export { contact }