import React from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import _ from 'lodash'

function BlockPlus () {
  const { getTextForPage } = useMpecTranslations('coachingParentsZensPlus')
  return (
    <div className={'col-lg-7 mr-1 mb-5'}>
      <h2 className={'subtitleOther text-left mb-5'}>{_.upperFirst(getTextForPage('blockPlus.title'))}</h2>
      <p>{_.upperFirst(getTextForPage('blockPlus.text'))}</p>
    </div>
  )
}

export default BlockPlus
