const formsLabels = {
  fr: {
    translation: {
      formsLabels: {
        common: {
          birthDate: 'ma date de naissance',
          confirmPassword: 'confirmez votre mot de passe',
          emailPro: 'mon email professionnel',
          firstName: 'mon prénom',
          grandParent: 'grand-parent',
          lastname: 'mon nom',
          parent: 'parent',
          phoneNumber: 'mon numéro de téléphone',
          password: 'mot de passe',
          man: 'un homme',
          woman: 'une femme',
        }
      }
    }
  }
}

export { formsLabels }
