import React  from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../Tools/translations'
import "../../Common/Blocks/TextImgCard/TextImgCard.scss"
import './Gazouyi.scss'
import Text from '../../Common/Blocks/Text/Text'
import TextImgCard from '../../Common/Blocks/TextImgCard/TextImgCard'
import H3 from '../../Common/HX/H3'
import TitlePage from '../../Common/TitlePage/TitlePage'
import FormGazouyi from './partial/FormGazouyi'

function Gazouyi () {
  const { getTextForPage } = useMpecTranslations('gazouyi')
  const imgGazouyi = (process.env.REACT_APP_LINK_IMG + "logo_gazouyi.png")

  const text = ['contentText1','contentText2']
  const content = <div>
    {text.map(element => {
      return <p key={uuidv4()}>{getTextForPage(element)}</p>
    })}
  </div>

  const textli = ['content2Text1', 'content2Text2', 'content2Text3', 'content2Text4', 'content2Text5']
  const content2 = <div>
    <H3 text={getTextForPage('titleContent2')} className={'size-title regularGreenTitle'}/>
    <ul>
      {textli.map(element => {
        return <li key={uuidv4()}>{getTextForPage(element)}</li>
      })}
    </ul>
  </div>

  return (
    <div className="Gazouyi content-wrapper">
      <div className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 mt-5 mb-5">
              <TitlePage title={getTextForPage('titlePage')}/>
            </div>
          </div>
          <div className={"container"}>
            <TextImgCard
              img={imgGazouyi}
              classNameImg={"gazouyi"}
              content={content}
              classNameContent={'mb-lg-4 col-sm-12 col-md-12 col-lg-7'}
              classNameDivImg={'mb-4 p-0 col-sm-12 col-md-12 col-lg-5 align-self'}
              classNameBlockText={' '}
            />
            <Text content={content2}/>
            <FormGazouyi/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gazouyi
