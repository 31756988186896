const breadcrumb = {
  fr: {
    translation: {
      breadcrumb: {
        '0-3': '0/3 ans',
        '3-6': '3/6 ans',
        '6-11': '6/11 ans',
        '11-15': '11/15 ans',
        '15-18': '15/18 ans',
        grossesse: 'Grossesse',
        pregnancy: 'Grossesse'
      }
    }
  },
}

export { breadcrumb }
