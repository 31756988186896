import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'

function useArticlesTools () {
  const [activities, setActivities] = useState([])
  const [articles, setArticles] = useState([])
  const [categories, seCategories] = useState([])
  const [filters, setFilters] = useState([])
  const [filtersActivities, setFiltersActivities] = useState([])
  const [filtersArticles, setFiltersArticles] = useState([])
  const [filtersMultimedia, setFiltersMultimedia] = useState([])
  const [multimedia, setMultimedia] = useState([])
  const [noMoreElementsArticles, setNoMoreElementsArticles] = useState(false)
  const [noMoreElementsActivities, setNoMoreElementsActivities] = useState(false)
  const [noMoreElementsMultimedia, setNoMoreElementsMultimedia] = useState(false)
  const [order] = useState('desc')
  const source = Axios.CancelToken.source()

  const getAllActivities = () => {
    return activities
  }

  const getAllArticles = () => {
    return articles
  }

  const getAllFilters = (filtersData = filters) => {
    const result = []
    _.forEach(filtersData, (title, number) => {
      result.push(
        {
          id: 'filter' + _.upperFirst(title),
          value: 'option' + number,
          htmlFor: 'filter' + _.upperFirst(title),
          title
        }
      )
    })

    return result
  }

  const getCategories = () => {
    const arrayCategories = _.map(categories, (category) => {
      return category.route
    })
    return getAllFilters(arrayCategories)
  }

  const getAllFiltersActivities = () => {
    return getAllFilters(filtersActivities)
  }

  const getAllFiltersMultimedia = () => {
    return getAllFilters(filtersMultimedia)
  }

  const getAllFiltersArticles = () => {
    return getAllFilters(filtersArticles)
  }

  const getAllMultimedia = () => {
    return multimedia
  }

  const getNoMoreElementsActivities = () => {
    return noMoreElementsActivities
  }

  const getNoMoreElementsArticles = () => {
    return noMoreElementsArticles
  }

  const getNoMoreElementsMultimedia = () => {
    return noMoreElementsMultimedia
  }

  const fetchLimitedListOfActivities = async (limit) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/parents/activities/limit/get-data', { limit }, {
        cancelToken: source.token
      })
      const activities = result.data
      setActivities(activities)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfActivities = async (directoryName, currentFilters, options, idUser = null) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/parents/activities/get-data', { directoryName, type: 'activities', filters: currentFilters, options, idUser, order }, {
        cancelToken: source.token
      })
      const activities = result.data
      handleNoMoreElements(activities, setNoMoreElementsActivities)

      if (_.get(options, 'currentLimit')) {
        setActivities(oldActivities => [...oldActivities, ...activities])
      } else {
        setActivities(activities)
      }
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfArticles = async (directoryName, currentFilters, options) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/parents/articles/get-data', { directoryName, type: 'articles', filters: currentFilters, options, order }, {
        cancelToken: source.token
      })
      const articlesData = result.data
      handleNoMoreElements(articlesData, setNoMoreElementsArticles)

      if (_.get(options, 'currentLimit')) {
        setArticles(oldArticles => [...oldArticles, ...articlesData])
      } else {
        setArticles(articlesData)
      }
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfFilter = async (directoryName) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/parents/activities/get-data', { directoryName, type: 'filters', order }, {
        cancelToken: source.token
      })
      const filters = result.data
      setFilters(filters)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfFilterActivities = async (directoryName, type = 'filters') => {
    try {
      const result = await Axios.post('/api/parents/activities/get-data', { directoryName, type, order }, {
        cancelToken: source.token
      })
      const filters = result.data

      setFiltersActivities(filters)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfFilterArticles = async (directoryName, type = 'filters') => {
    try {
      const result = await Axios.post('/api/parents/articles/get-data', { directoryName, type, order }, {
        cancelToken: source.token
      })
      const filters = result.data

      setFiltersArticles(filters)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfFilterMultimedia = async (directoryName) => {
    try {
      const result = await Axios.post('/api/parents/multimedia/get-data', { directoryName, type: 'filters' }, {
        cancelToken: source.token
      })
      const filters = result.data
      setFiltersMultimedia(filters)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfMultimedia = async (directoryName, currentFilters, options) => {
    try {
      const result = await Axios.post('/api/parents/multimedia/get-data', { directoryName, type: 'multimedia', filters: currentFilters, options }, {
        cancelToken: source.token
      })
      const multimediaData = result.data
      handleNoMoreElements(multimediaData, setNoMoreElementsMultimedia)

      if(_.get(options, 'currentLimit')){
        setMultimedia(oldMultimedia => [...oldMultimedia, ...multimediaData])
      } else {
        setMultimedia(multimediaData)
      }
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchCategories = async () => {
    const source = Axios.CancelToken.source()

    try {
      const result = await Axios.post('/api/parents/blocks/get-data', null, {
        cancelToken: source.token
      })
      const results = result.data
      results.unshift(results.pop())
      seCategories(results)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const handleNoMoreElements = (data, setter) => {
    (_.find(data, { lastElement: true }) || _.isEmpty(data))
      ? setter(true)
      : setter(false)
  }

  return {
    fetchCategories,
    fetchLimitedListOfActivities,
    fetchListOfActivities,
    fetchListOfArticles,
    fetchListOfFilter,
    fetchListOfFilterActivities,
    fetchListOfFilterArticles,
    fetchListOfFilterMultimedia,
    fetchListOfMultimedia,
    getAllActivities,
    getAllArticles,
    getAllFilters,
    getAllFiltersActivities,
    getAllFiltersArticles,
    getAllFiltersMultimedia,
    getAllMultimedia,
    getCategories,
    getNoMoreElementsActivities,
    getNoMoreElementsArticles,
    getNoMoreElementsMultimedia
  }
}

export default useArticlesTools
