import $ from 'jquery'
import _ from 'lodash'
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../Tools/translations'

function useFormsTools () {
  const { getText, getTextCompaniesNames } = useMpecTranslations()

  const shouldICheckTheBox = (formName, fieldId, value, isArray) => {
    const valueFromRegister = initFromStorage(fieldId, formName)
    if (isArray) {
      return valueFromRegister.indexOf(value) > -1
    }
  }

  const allCheckBoxOrRadio = (data) => {
    return (
      data.map((value) => {
        const classLabel = value.classLabel
        const firstDiv = value.firstDiv || ''
        const id = value.id || ''
        const name = value.name || value.id || ''
        const required = value.required || ''
        const text = value.text || ''
        const type = value.type || ''
        const myValue = value.value || ''
        const handleChange = value.handleChange || defaultHandleChange
        const formName = value.formName || ''
        const isArray = value.isArray || false
        const multiCompanies = value.multiCompanies || false
        const isChecked = shouldICheckTheBox(formName, name, myValue, isArray)

        return <div className={`${firstDiv} form-check`} key={uuidv4()}>
          <input
            className="form-check-input"
            id={id}
            name={name}
            required={required}
            type={type}
            defaultValue={myValue}
            onChange={handleChange}
            data-lpz-formname={formName}
            data-lpz-array={isArray}
            data-lpz-multicompanies={multiCompanies}
            defaultChecked={isChecked}
          />
          <label
            className={`form-check-label ${classLabel}`}
            htmlFor={value?.id}
          >
            {text}
          </label>
        </div>
      })
    )
  }

  const defaultHandleChange = (event) => {
    return true
  }

  const initFromStorage = (fieldId, formname) => {
    try {
      const localStorageData = localStorage.getItem(formname)
      let jsonData = {}
      if (!_.isUndefined(localStorageData) && !_.isNull(localStorageData) && (localStorageData !== 'undefined')) {
        jsonData = JSON.parse(localStorageData)
      }
      if (!_.isEmpty(jsonData)) {
        return _.get(jsonData, fieldId, '')
      }
    } catch (error) {
      console.log({error})
      localStorage.removeItem(formname)
      initFromStorage()
    }
  }

  const clearLocalStorageData = (fieldName, formname) => {
    const jsonData = JSON.parse(localStorage.getItem(formname))
    if (jsonData) {
      if (fieldName === 'all') {
        localStorage.removeItem(formname)
      } else {
        _.unset(jsonData, fieldName)
        localStorage.setItem(formname, JSON.stringify(jsonData))
        return jsonData
      }
    }
  }

  const allInputs = (data, multiplesCompaniesByDomain) => {
    return (
      data.map((value) => {
        const autoComplete = value.autoComplete || 'off'
        const firstDiv = value.firstDiv || ''
        const classLabel = value.classLabel || ''
        const classInput = value.classInput || ''
        const id = value.id || ''
        const name = value.name || value.id || ''
        const required = value.required || ''
        const text = value.text || ''
        const type = value.type || ''
        const handleChange = value.handleChange || defaultHandleChange
        const handleBlur = value.handleBlur || defaultHandleChange
        const formName = value.formName || ''
        const myValue = initFromStorage(value.id, formName)
        const isArray = value.isArray || false
        const refreshMultipleCompanies = value.refreshMultipleCompanies || false

        switch (type) {
          case 'radio':
            if (id === 'multipleCompanies') {
              // display the only if he email contains a multiple domain
              const email = $('#inputEmail').val()
              let hideClass = ''
              let myOptions = []
              if (email) {
                const index = email.indexOf('@')
                const domain = email.substring(index)
                if (_.has(multiplesCompaniesByDomain, domain)) {
                  _.set(value, 'multiCompanies', multiplesCompaniesByDomain)
                  const options = _.get(multiplesCompaniesByDomain, domain, [])
                  _.forEach(options, data => {
                    const fieldData = {
                      id: `multipleCompanies-${data.idCompany}`,
                      name: 'multipleCompanies',
                      text: _.upperFirst(getTextCompaniesNames(data.companyName)),
                      type: 'radio',
                      value: data.idCompany,
                      'handleChange': mpecFormHandleChange,
                      formName: 'register',
                      required: required
                    }
                    myOptions.push(fieldData)
                  })
                } else {
                  hideClass = 'hide'
                  _.set(value, 'required', false)
                  myOptions = [value]
                }
              } else {
                hideClass = 'hide'
              }
              return (
                <div className={`form-group ${hideClass}`} id={'multiCompanies'} key={uuidv4()}>
                  <label className={value?.isRequired}>{_.upperFirst(getText('myProfile.inputInformation.myCompany'))}</label>
                  <div className={'d-flex flex-row justify-content-around'} id={`${name}-listRadio`}>
                    {allCheckBoxOrRadio(myOptions)}
                  </div>
                </div>
              )
            }
            return ''
          default:
            return <div className={`${firstDiv} form-group`} key={uuidv4()}>
              <label htmlFor={id} className={classLabel}>{text}</label>
              <input
                autoComplete={autoComplete}
                className={`form-control rounded ${classInput}`}
                id={id}
                name={name}
                required={required}
                type={type}
                onChange={event => handleChange(event, multiplesCompaniesByDomain)}
                onBlur={handleBlur}
                data-lpz-formname={formName}
                data-lpz-array={isArray}
                data-lpz-refreshmultiplecompanies={refreshMultipleCompanies}
                defaultValue={myValue}
              />
            </div>
        }
      })
    )
  }

  const mpecFormHandleChange = (event, multiCompanies) => {
    const field = $(event.target)
    const formName = field.attr('data-lpz-formname')
    const isArray = field.attr('data-lpz-array') === 'true'
    const refreshMultipleCompanies = field.attr('data-lpz-refreshmultiplecompanies') === 'true'
    const localStorageData = localStorage.getItem(formName)
    let data = {}
    if (!_.isUndefined(localStorageData) && !_.isNull(localStorageData) && (localStorageData !== 'undefined')) {
      data = JSON.parse(localStorageData)
    }

    const name = field.attr('name').replace(' ', '') || field.attr('id').replace(' ', '')
    const handleValue = parseInt(field.val())
    if (refreshMultipleCompanies) {
      const value = field.val()
      const index = value.indexOf('@')
      const domain = value.substring(index)

      if (_.has(multiCompanies, domain)) {
        $('#multiCompanies').removeClass('hide')
        $('#multipleCompanies').prop('required', true)
        const options = _.get(multiCompanies, domain, [])
        const myOptions = []
        _.forEach(options, data => {
          const option =
            <Fragment>
              <div className={'form-check'} key={uuidv4()}>
                <input type={'radio'} name={'multipleCompanies'}
                       id={`multipleCompanies-${data.idCompany}`}
                       className={'form-check-input'}
                       onChange={mpecFormHandleChange}
                       value={data.idCompany}
                       data-lpz-formname={formName}
                       data-lpz-refreshmultiplecompanies={false}
                       required={true}
                />
                <label htmlFor={`multipleCompanies-${data.idCompany}`} className={'form-check-label'}>{getTextCompaniesNames(data.companyName)}</label>
              </div>
            </Fragment>
          myOptions.push(option)
        })
        const idToReplace = 'multipleCompanies-listRadio'
        ReactDOM.render(myOptions, document.getElementById(idToReplace))
      } else {
        $('#multiCompanies').addClass('hide')
        $('#multipleCompanies').prop('required', false)
        data = clearLocalStorageData('multipleCompanies', formName)

      }
    }
    if (isArray) {
      let tmpArray = _.get(data, name, [])
      const indexToRemove = _.indexOf(tmpArray, handleValue)
      if (indexToRemove >= 0) {
        tmpArray = tmpArray.filter(item => item !== handleValue)
      } else {
        tmpArray.push(handleValue)
      }
      _.set(data, name, tmpArray)
    } else {
      _.set(data, name, field.val())
    }
    localStorage.setItem(formName, JSON.stringify(data))
  }

  const toastError = (message, options = {}) => {
    if (_.isEmpty(options)) {
      options = {
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: false,
        position: 'top-center',
        progress: undefined,
        theme: 'colored'
      }
    }
    toast.error(message, options)
  }

  const toastSuccess = (message, options = {}) => {
    const defaultOptions = {
      autoClose: true,
      closeOnClick: true,
      draggable: true,
      hideProgressBar: false,
      pauseOnHover: false,
      position: 'top-right',
      progress: undefined,
      theme: 'colored'
    }
    options = _.merge(defaultOptions, options)
    toast.success(message, options)
  }

  return {
    allCheckBoxOrRadio,
    allInputs,
    clearLocalStorageData,
    initFromStorage,
    mpecFormHandleChange,
    toastError,
    toastSuccess
  }
}

export default useFormsTools