import _ from 'lodash'
import moment from 'moment'

function useFunctionsTools () {
  /**
   * Allows you to retrieve an array of array with the desired number
   * @example
   * const array = ['a', 'b', 'c', 'd', 'e']
   * const arrayNumber = [2, 1]
   * chunking(array, arrayNumber)
   *    output => [['a', 'b'], ['c'], ['d', 'e']]
   *
   * @param myArray
   * @param arrayNumber {int[]}
   * @returns {*[]}
   */
  const chunking = (myArray, arrayNumber) => {
    const arrayToChunk = myArray.slice()
    const arrayResult = []
    let count = 0

    while (!_.isEmpty(arrayToChunk)) {
      if (count === arrayNumber.length) {
        count = 0
      }

      let actualNumber = arrayNumber[count]
      let actualArray = arrayToChunk.splice(0, actualNumber)
      arrayResult.push(actualArray)

      count++
    }

    return arrayResult
  }

  /**
   * convert date utc to date with timezone
   * @param date
   * @param createdAt
   * @param updatedAt
   * @param timezone
   * @returns {string}
   */
  const convertDateWithTimezone = (date, createdAt = null, updatedAt = null, timezone = null) => {
    const dateEvent = moment.utc(date)
    const cloneDate = dateEvent.clone()

    if (_.isNull(timezone)) {
      /** Retrieve the user's browser timezone **/
      timezone = moment.tz.guess()
    }

    /** We need a ref date to know when the date in bdd have been register (easter, winter) else we base on the actual date **/
    let refDate = date
    if (updatedAt) {
      refDate = updatedAt
    } else if (createdAt) {
      refDate = createdAt
    }

    const refDateUtc = moment.utc(refDate)
    const refDateUtcLocale = moment.utc(refDateUtc.tz(timezone).format('YYYY-MM-DD H:mm:ss'))
    const diffBetweenDate = refDateUtcLocale.diff(refDateUtc, 'seconds', true)

    return cloneDate.add(diffBetweenDate, 'seconds')
  }

  /**
   *
   * @param email
   * @returns {boolean}
   */
  const isEmail = (email) => {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regExp.test(String(email).toLowerCase())
  }

  return { chunking, convertDateWithTimezone, isEmail }
}

export default useFunctionsTools
