import Axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router'
import {useLocation} from 'react-router-dom'
import useMpecTranslations from '../../../Tools/translations'
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb'
import Button from '../../Common/Button/Button'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import H1 from '../../Common/HX/H1'
import usePagesTools from '../../Hooks/usePagesTools'
import './Articles.scss'

function Articles (props) {
  const {
      getRouteFor,
      getTextButton,
      getTextForBreadCrumb,
      getTextForPage,
      getTextInSpecificLanguage
  } = useMpecTranslations('articles')
  const { displayBlocks } = usePagesTools()
  const [blocks, setBlocks] = useState(false)
  const [displayImg, setDisplayImg] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [titleHeader, setTitleHeader] = useState('')
  const { category, title } = useParams()
  const history = useHistory()
  const location = useLocation()
  const source = Axios.CancelToken.source()

  useEffect(() => {
    fetchArticle()
    return () => {
      source.cancel('cancel')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchArticle = async () => {
    const categoryTranslate = getTextInSpecificLanguage('en', 'paramsUrl', category)

    try {
      const result = await Axios.post('/api/articles/get-data', { category: categoryTranslate, title }, {
        cancelToken: source.token
      })
      const articleData = result.data
      setTitleHeader(_.get(articleData, 'header.title', ''))
      setBlocks(_.get(articleData, 'blocks', []))
      setDisplayImg(_.get(articleData, 'img', ''))
      setIsLoading(false)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }

  }

  const getLinksObject = (link, title, icon, className, isActive, isLast) => {
    return { link, title, icon, className, isActive, isLast }

  }

  const getLinksForBreadCrumb = () => {
    if (location.pathname.includes('articles-inspirations')) {
      return linksArticlesInspirational

    }
    return links

  }

  const getOnclick = (event) => {
    event.preventDefault()
    if (!category) {
      redirect(getRouteFor('inspirationalArticles', {}, true))
      return true
    }

    redirect(getRouteFor('parentsLifeCategory', { category }, true))
  }

  const links = [
    getLinksObject('/', getTextForPage('home'), '', null, false, false),
    getLinksObject(getRouteFor('parentsLife', {}, true), getTextForPage('titleParentsLife'), '', null, false, false),
    getLinksObject(getRouteFor('parentsLifeCategory', { category: category }, true), getTextForBreadCrumb(category), '', null, false, false),
    getLinksObject('#', titleHeader, '', null, false, true)
  ]

  const linksArticlesInspirational = [
    getLinksObject('/', getTextForPage('home'), '', null, false, false),
    getLinksObject(getRouteFor('inspirationalArticles', {}, true), _.upperFirst(getTextForPage('titleInspirationalArticles')), '', null, false, false),
    getLinksObject('#', titleHeader, '', null, false, true)
  ]

  const redirect = (route) => {
    history.push(route)
  }

  if (!isLoading) {
    return (
      <div className="pageArticles content-wrapper">
        <HeaderPage isDisplayLogo={false} imgBannerBackground={displayImg}/>
        <BreadCrumb links={getLinksForBreadCrumb()} className={'pl-5 mt-4'}/>
        <div className="container">
          <div className="page-title-article mt-3 mb-4 d-flex row-responsive justify-content-center">
            <H1 text={titleHeader} className={'mb-5 mt-5'}/>
          </div>
          {displayBlocks(blocks)}
          <Button
            buttonFunction={getOnclick.bind(this)}
            classSup={'btnGreen'}
            isButtonWithAction={true}
            title={_.toUpper(getTextButton('seeAllArticles'))}
          />
        </div>
        <div className="content">
        </div>
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}

Articles.propTypes = {
  className: PropTypes.any,
  content: PropTypes.any,
  img: PropTypes.any
}

export default Articles
