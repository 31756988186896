const homePage = {
  fr: {
    translation: {
      pages: {
        homePage: {
          description: '{{company}} met à votre disposition des nouveaux services innovants pour vous accompagner dans votre parentalité au quotidien. Que vous attendiez votre premier enfant, que vous cherchiez à comprendre votre adolescent, ou que vous exploriez le champ des activités possibles à partager avec vos petits-enfants, retrouvez ici tous les outils, articles et services pour mener votre vie de super-parent !',
          title: 'bienvenue sur votre espace Les Parents Zens !'
        }
      }
    }
  }
}

export { homePage }
