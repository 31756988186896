import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'

function useEventsTools () {
  const [conference, setConference] = useState([])
  const [eventsInfo, setEventsInfo] = useState([])
  const [eventInfoIsLoading, setEventInfoIsLoading] = useState(true)
  const [futureEventsInfo, setFutureEventsInfo] = useState([])
  const [oneEvent, setOneEvent] = useState()
  const [passedEventsInfo, setPassedEventsInfo] = useState([])
  const [replay, setReplay] = useState([])
  const [workshop, setWorkshop] = useState([])

  const createEventRegistration = async (data, sessionId) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/events/create/event/registration', { data, sessionId }, {
        cancelToken: source.token
      })
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchEventsConference = async (currentCompany, sessionId, idConference) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/events/get-by-company', { currentCompany, sessionId, idType: idConference }, {
        cancelToken: source.token
      })
      setConference(result.data)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchEventsInfoFromApi = async (currentCompany, sessionId, idType = null, passedEvents = false, futureEvents = false) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/events/get-by-company', { currentCompany, sessionId, idType, passedEvents, futureEvents }, {
        cancelToken: source.token
      })
      setEventsInfo(result.data)
      setEventInfoIsLoading(false)
      if (passedEvents) {
        setPassedEventsInfo(result.data)
      } else if (futureEvents) {
        setFutureEventsInfo(result.data)
      }
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchEventsWorkshop = async (currentCompany, sessionId, idWorkshop) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/events/get-by-company', { currentCompany, sessionId, idType: idWorkshop }, {
        cancelToken: source.token
      })
      setWorkshop(result.data)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchGetDataAllReplays = async () => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/replays/get-all-data', null, {
        cancelToken: source.token
      })
      const replayData = result.data

      setReplay(replayData)
    } catch (error) {
      if(Axios.isCancel(error)){
      } else {
        throw error
      }
    }
  }

  const fetchOneEventByIdFromApi = async (idCompany, idEvent, sessionId) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/events/get-one-event', { idCompany, sessionId, idEvent }, {
        cancelToken: source.token
      })
      setOneEvent(result.data)
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getConference = () => {
    return conference
  }

  const getEventsInfo = () => {
    return eventsInfo
  }

  const getEventByName = (name) => {
    let actualEvent = {}

    _.forEach(eventsInfo, event => {
      if (event.eventType === name) {
        actualEvent = event
        return actualEvent
      }
    })

    return actualEvent
  }

  const getOneEvent = () => {
    return oneEvent
  }

  const getReplay = () => {
    return replay
  }

  const getWorkshop = () => {
    return workshop
  }

  const isEventFull = (event) => {
    return event?.remainingPlaces === 0
  }

  const isUserAlreadySubscribe = (event) => {
    if (_.isEmpty(event) || event?.limitPerPersons === 0) {
      return false
    }
    return event?.limitPerPersons === event?.nbOfRegistrationsActualUser
  }

  const isUserCanSubscribe = (eventType) => {
    if (eventsInfo) {
      const events = []
      _.forEach(eventsInfo, event => {
        if (event?.eventType === eventType
          && !isEventFull(event)
          && !isUserAlreadySubscribe(event)
        ) {
          events.push(event)
        }
      })

      return events.length > 0
    }

    return false
  }

  return {
    conference,
    createEventRegistration,
    eventsInfo,
    eventInfoIsLoading,
    fetchEventsConference,
    fetchEventsInfoFromApi,
    fetchGetDataAllReplays,
    fetchOneEventByIdFromApi,
    fetchEventsWorkshop,
    futureEventsInfo,
    getConference,
    getEventByName,
    getEventsInfo,
    getReplay,
    getOneEvent,
    getWorkshop,
    isEventFull,
    isUserAlreadySubscribe,
    isUserCanSubscribe,
    oneEvent,
    passedEventsInfo,
    workshop
  }
}

export default useEventsTools
