import _ from 'lodash'
import React, { useState } from 'react'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import H1 from '../../Common/HX/H1'
import H2 from '../../Common/HX/H2'
import UnderTitle from '../../Common/UnderTitle/UnderTitle'
import useMpecServices from '../../Hooks/useMpecServices'
import useWichTerminal from '../../Hooks/useWichTerminal'
import TimelineDesktop from './Partials/Timeline/TimelineDesktop/TimelineDesktop'
import TimelineMobile from './Partials/Timeline/TimelineMobile/TimelineMobile'

function MyJourney () {
  const { isMobile } = useWichTerminal()
  const { getTextCompaniesNames, getTextForHeader, getTextForPage } = useMpecTranslations('myJourney')
  const { getUserData } = useMpecSecure()
  const [company] = useState(getUserData('companyName'))
  const { convertServiceName, getHeaderNameForService, getListOfServices, myServices } = useMpecServices()

  const allData = [
    { key: 'nurseryPlace', title: getTextForPage('nurseryPlace'), img: 'mon_parcours_places_en_creches3.jpg' },
    { key: 'complementaryGuard', title: _.upperFirst(getTextForHeader(getHeaderNameForService('complementaryGuard'))), img: 'mon_parcours_garde_complementaire.png' },
    { key: 'complementaryGuardO2', title: _.upperFirst(getTextForHeader(getHeaderNameForService('complementaryGuardO2'))), img: 'mon_parcours_garde_complementaire.png' },
    { key: 'conferencesWorkshops', title: getTextForPage('conferencesAndWorkshops'), img: 'mon_parcours_conferences_ateliers.png' },
    { key: 'returnMaternityPaternityLeave', title: getTextForPage('returnFromPaternity'), img: 'mon_parcours_retours_congés_maternite_paternite_zens.png' },
    { key: 'coachingParentsZens', title: getTextForPage('coachParentsZen'), img: 'mon_parcours_coaching_parents_zens.png' },
    { key: 'gazouyi', title: getTextForPage('alternateWelcome'), img: 'mon_parcours_application_mobile_activites_simples_ludiques.png' },
    { key: 'complementaryGuardCera', title: getTextForPage('complementaryGuardsCera'), img: 'mon_parcours_garde_complementaire.png' },
    { key: 'myFamiliz', title: getTextForPage('myFamiliz'), img: 'myFamilyzLogo.png' },
    { key: 'maxicours', title: getTextForPage('maxicours'), img: 'maxicours.png' }

  ]

  const getProtectedData = () => {
    const protectedData = []
    const services = getServiceAvailableForUser()
    _.forEach(allData, data => {
      if (_.includes(services, data.key)) {
        protectedData.push(data)
      }
    })

    return protectedData
  }

  const getServiceAvailableForUser = () => {
    const services = []
    if (_.isEmpty(myServices)) {
      getListOfServices()
    }
    _.forEach(myServices, service => {
      services.push(convertServiceName(service._name))
    })

    return services
  }

  return (
    <div className={'MyJourney content-wrapper'}>
      <HeaderPage company={company}/>
      <div className="container">
        <div className="row mb-2">
          <div className="col-sm-8 m-auto">
            <H1 text={getTextForPage('title')}/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-6 m-auto">
            <H2 text={getTextForPage('smallTitle')}/>
          </div>
        </div>
        <UnderTitle text={getTextForPage('underTitle', { company: getTextCompaniesNames(company) })}/>
        <div className="container">
          {isMobile() ? <TimelineMobile allData={getProtectedData()} company={company}/> : <TimelineDesktop allData={getProtectedData()} company={company}/>}
        </div>
      </div>
    </div>
  )
}

export default MyJourney
