import React, { useState } from 'react'
import PropTypes from 'prop-types'

function Image (props) {
  const [className] = useState(props.className)
  const [divClassName] = useState(props.divClassName)
  const [img] = useState(props.img)
  const [sizes] = useState(props.sizes)

  return (
    <div className={divClassName}>
      <img srcSet={img} className={className} alt={'#'} sizes={sizes} decoding={'async'}/>
    </div>
  )
}

Image.propTypes = {
  className: PropTypes.any,
  divClassName: PropTypes.any,
  img: PropTypes.any,
  sizes: PropTypes.any
}

export default Image
