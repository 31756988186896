import Axios from 'axios'
import _ from 'lodash'
import React, {useState} from 'react'
import {v4 as uuidv4} from 'uuid'
import Card from '../Common/Card/Card'
import useRoutesTools from "./useRoutesTools";

function useInspirationalArticlesTools () {
  const { redirect } = useRoutesTools()
  const [filters, setFilters] = useState([])
  const [articles, setArticles] = useState([])
  const source = Axios.CancelToken.source()
  const [order] = useState('asc')

  const fetchAllArticles = async (currentFilters) => {
    try {
      const result = await Axios.post('/api/inspirational-articles/get-data', { type: 'articles', filters: currentFilters, order }, {
        cancelToken: source.token
      })
      const articles = result.data
      setArticles(articles)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchListOfFilter = async () => {
    try {
      const result = await Axios.post('/api/inspirational-articles/get-data', { type: 'filters', order }, {
        cancelToken: source.token
      })
      const filters = result.data
      setFilters(filters)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getAllArticles = () => {
    return articles
  }

  const getAllFilters = () => {
    const result = []
    _.forEach(filters, (title, number) => {
      result.push(
        {
          id: 'inlineCheckbox' + number,
          value: 'option' + number,
          htmlFor: 'inlineCheckbox' + number,
          title
        }
      )
    })

    return result
  }

  const getArticlesOrder = () => {
    return _.orderBy(articles, (articles) => {
      return _.deburr(articles.title)
    }, ['asc'])
  }

  const getLastElement = (lastElement) => {
    const text1 = { text: lastElement[0].title, className: 'text-center m-0' }

    if (lastElement.length === 2) {
      const text2 = { text: lastElement[1].title, className: 'text-center m-0' }
      return <div className="row" key={uuidv4()}>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text1}
                classSup={'h-28'}
                imgBackground={lastElement[0].img}
                redirect={redirect.bind(this)}
                route={'inspirationalArticlesView'}
                routeParam={{ title: _.kebabCase(lastElement[0]?.header?.title) }}
          />
        </div>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text2}
                classSup={'h-28'}
                imgBackground={lastElement[1].img}
                redirect={redirect.bind(this)}
                route={'inspirationalArticlesView'}
                routeParam={{ title: _.kebabCase(lastElement[1]?.header?.title) }}
          />
        </div>
      </div>
    } else {
      return <div className="row" key={uuidv4()}>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text1}
                classSup={'h-28'}
                imgBackground={lastElement[0].img}
                redirect={redirect.bind(this)}
                route={'inspirationalArticlesView'}
                routeParam={{ title: _.kebabCase(lastElement[0]?.header?.title) }}
          />
        </div>
      </div>
    }
  }

  const getTextFromArticlesGroup = (articlesGroup) => {
    const text = []
    _.forEach(articlesGroup, articles => {
      text.push({ text: articles.title, className: 'text-center m-0' })
    })

    return text
  }

  return {
    fetchAllArticles,
    fetchListOfFilter,
    getAllArticles,
    getAllFilters,
    getArticlesOrder,
    getLastElement,
    getTextFromArticlesGroup
  }
}

export default useInspirationalArticlesTools
