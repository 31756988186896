import 'bootstrap'
import * as $ from 'jquery'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import useMpecTranslations from '../../../../../Tools/translations'
import './SubMenu.scss'

function SubMenu () {
  const { getTextForPage } = useMpecTranslations('blocParentsLife')
  const location = useLocation()

  useEffect(() => {
    if (!_.isEmpty(location.hash)) {
      $(`${location.hash}-tab`).tab('show')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAnchor = (event) => {
    const href = event.target.href
    const hash = href.split('#').pop()
    window.location.href = '#' + hash
  }

  return (
    <nav className={'SubMenu'}>
      <div className="nav nav-tabs row mt-3 mb-3 justify-content-between" id="nav-tab" role="tablist">
        <a
          aria-controls="nav-infos"
          aria-selected="true"
          className="nav-link active text-center col-4"
          data-toggle="tab"
          href="#nav-infos"
          id="nav-infos-tab"
          onClick={handleAnchor}
          role="tab"
        >
          {getTextForPage('text1')}
        </a>
        <a
          aria-controls="nav-activity"
          aria-selected="false"
          className="nav-link text-center col-4"
          data-toggle="tab"
          href="#nav-activity"
          id="nav-activity-tab"
          onClick={handleAnchor}
          role="tab"
        >
          {getTextForPage('text2')}
        </a>
        <a
          aria-controls="nav-questions"
          aria-selected="false"
          className="nav-link text-center col-4"
          data-toggle="tab"
          href="#nav-questions"
          id="nav-questions-tab"
          onClick={handleAnchor}
          role="tab"
        >
          {_.upperFirst(getTextForPage('text3'))}
        </a>
      </div>
    </nav>
  )
}

export default SubMenu
