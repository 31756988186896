import React, { useState } from 'react'
import useMpecTranslations from '../../../../../../../Tools/translations'
import Bar from './Bar'
import Pause from './Pause'
import Play from './Play'
import useAudioPlayer from './useAudioPlayer'
import ReactHtmlParser from 'react-html-parser'
import _ from 'lodash'
import './AudioComponent.scss'

function AudioComponent (props) {
  const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer(props.id)
  const { getTextForPage, getTextFilter } = useMpecTranslations('blocParentsLife')
  const [id] = useState(props.id)
  const [audio] = useState(props.audio)
  const [img] = useState(props.img || "photo_podcast")

  return (
    <div className="col-lg-4">
      <div className="AudioComponent card">
        <div className={`${img} picture contentAudio AudioContainer player`}>
          <audio id={id}>
            <source src={process.env.REACT_APP_LINK_AUDIO + audio.src}/>
            {ReactHtmlParser(_.upperFirst(getTextForPage('thirdPanel.audioComponent.error')))}
          </audio>
          <div className={'text-center'}>
            {playing ?
              <Pause handleClick={() => setPlaying(false)}/> :
              <Play handleClick={() => setPlaying(true)}/>
            }
          </div>
          <Bar curTime={curTime} duration={duration} id={id} onTimeUpdate={(time) => setClickedTime(time)}/>
        </div>
        <div className={'text-center mt-4'}>
          <p className={'font-weight-bold'}>{_.upperFirst(audio.title)}</p>
          <p>{_.upperFirst(getTextFilter(audio.type))}</p>
        </div>
      </div>
    </div>
  )
}

export default AudioComponent
