import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../Tools/translations'
import Button from '../../../Common/Button/Button'
import H3 from '../../../Common/HX/H3'

function CheckboxInformation (props) {
  const { getTextForPage, getTextButton } = useMpecTranslations('MyProfile')
  const [contentTypes, setContentTypes] = useState([])
  const [myProfileData, setMyProfileData] = useState(props.myProfile || {})
  const [slicesOfLife, setSlicesOfLife] = useState([])
  const [thematics, setThematics] = useState([])
  const handleChange = props.handleChange
  const updateData = props.updateData

  useEffect(() => {
    if (_.has(props, 'formParams')) {
      setContentTypes(_.get(props, 'formParams.contentTypes', []))
      setSlicesOfLife(_.get(props, 'formParams.slicesOfLife', []))
      setThematics(_.get(props, 'formParams.thematics', []))
    }
    if (_.has(props, 'myProfile')) {
      setMyProfileData(_.get(props, 'myProfile'))
    }
  }, [props])

  const dataCheckType = () => {
    const configs = []
    if (contentTypes.length > 0) {
      _.map(contentTypes, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'contentType-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            htmlFor: id,
            name: 'contentTypes[]',
            text: 'checkboxInformation.'.concat(text),
            value: parseInt(value)
          }
        )
      })
    }
    return configs
  }

  const dataContentType = () => {
    const dataType = dataCheckType()
    return (
      <div className={'row'}>
        <div className={'col-12 col-md-6 col-lg-3'}>
          <label className="col-sm-12 col-form-label mb-2 p-0">{_.upperFirst(getTextForPage('checkboxInformation.contentType'))}</label>
        </div>
        <div className="mb-4 col-12 col-md-6 col-lg-9">
          <div className="row pl-xs-2">
            {dataType.map((value) => {
              const text = value.text
              return <div className="form-check col-12 col-md-6 col-lg-4" key={uuidv4()}>
                <label className="form-check-label" htmlFor={value.htmlFor}>
                  <input className="form-check-input"
                         type="checkbox"
                         name={value.name}
                         id={value.htmlFor}
                         onChange={handleChange}
                         value={value.value}
                         defaultChecked={isChecked(value.name, value.value)}
                  />
                  {_.upperFirst(getTextForPage(text))}
                </label>
              </div>
            })}
          </div>
        </div>
      </div>
    )
  }

  const dataCheckAge = () => {
    const configs = []
    if (slicesOfLife.length > 0) {

      _.map(slicesOfLife, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'sliceOfLife-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            htmlFor: id,
            name: 'slicesOfLife[]',
            text: 'checkboxInformation.'.concat(text),
            value: parseInt(value)
          }
        )
      })
    }
    return configs
  }

  const dataSliceOfLife = () => {
    const dataAge = dataCheckAge()
    return (
      <div className={'row'}>
        <div className={'col-12 col-md-6 col-lg-3'}>
          <label htmlFor="inputActivities" className="col-sm-12 col-form-label mb-2 p-0">{_.upperFirst(getTextForPage('checkboxInformation.sliceOfLife'))}</label>
        </div>
        <div className={'col-12 col-md-6 col-lg-9'}>
          <div className="mb-4">
            <div className="row pl-xs-2">
              {dataAge.map((value) => {
                const text = value.text
                return <div className="form-check col-12 col-md-6 col-lg-4" key={uuidv4()}>
                  <label className="form-check-label" htmlFor={value.htmlFor}>
                    <input className="form-check-input"
                           defaultChecked={isChecked(value.name, value.value)}
                           id={value.htmlFor}
                           name={value.name}
                           onChange={handleChange}
                           type="checkbox"
                           value={value.value}
                    />
                    {_.upperFirst(getTextForPage(text))}
                  </label>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const dataCheckThematic = () => {
    const configs = []
    if (thematics.length > 0) {
      _.map(thematics, (doc) => {
        const text = _.camelCase(_.values(doc)[0])
        const id = 'thematics-'.concat(text)
        const value = _.lowerCase(_.keys(doc)[0])
        configs.push(
          {
            htmlFor: id,
            name: 'thematics[]',
            text: 'checkboxInformation.'.concat(text),
            value: parseInt(value)
          }
        )
      })
    }
    return configs
  }

  const dataThematic = () => {
    const dataTheme = dataCheckThematic()
    return (
      <div className={'row'}>
        <div className={'col-12 col-md-6 col-lg-3'}>
          <label htmlFor="inputActivities" className="col-sm-12 col-form-label mb-2 p-0">{_.upperFirst(getTextForPage('checkboxInformation.theme'))}</label>
        </div>
        <div className={'col-12 col-md-6 col-lg-9'}>
          <div className="mb-2">
            <div className="row pl-xs-2">
              {dataTheme.map((value) => {
                const text = value.text
                return <div className="form-check col-12 col-md-6 col-lg-4 mb-2" key={uuidv4()}>
                  <label className="form-check-label" htmlFor={value.htmlFor}>
                    <input
                      className="form-check-input"
                      defaultChecked={isChecked(value.name, value.value)}
                      id={value.htmlFor}
                      name={value.name}
                      onChange={handleChange}
                      type="checkbox"
                      value={value.value}
                    />
                    {_.upperFirst(getTextForPage(text))}
                  </label>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isChecked = (field, value) => {
    const testValue = _.get(myProfileData, '_'.concat(field).replace('[]', ''), '')
    let isChecked = false
    let isArray = false
    if (_.isArray(testValue)) {
      isChecked = _.indexOf(testValue, value) >= 0
      isArray = true
    } else {
      isChecked = testValue === value
    }
    if (isChecked && !isArray) {
      _.set(updateData, field, value)
    } else if (isChecked && isArray) {
      let vals = _.get(updateData, field, [])
      if (_.isString(vals)) {
        vals =  _.get(updateData, field, []).split()
      }
      vals.push(value)
      _.set(updateData, field, _.uniq(vals))
    }
    return isChecked
  }

  return (
    <div>
      <div className={'mb-4'}>
        <H3 text={_.upperFirst(getTextForPage('checkboxInformation.interest'))} className={'page-subtitle-blueAzure400 fs-6'}/>
      </div>
      {/*Start centre d'interet*/}
      {dataContentType()}
      {/*End centre d'interet*/}
      {/*Start Tranche de vie*/}
      {dataSliceOfLife()}
      {/*End Tranche de vie*/}
      {/*Start Thématique*/}
      {dataThematic()}
      {/*End Thématique*/}
      <p className={'mt-3'}>{getTextForPage('checkboxInformation.informations')}</p>
      <div className={'justify-content-center row'}>
        <Button
          classSup={'btnGreenLight'}
          isButtonWithAction={true}
          title={_.toUpper(getTextButton('saveChanges'))}
          type={'submit'}
        />
      </div>
    </div>
  )
}

CheckboxInformation.propTypes = {
  formParams: PropTypes.object.isRequired,
  myProfile: PropTypes.object.isRequired
}

export default CheckboxInformation
