const articles = {
  fr: {
    translation: {
      pages: {
        articles: {
          age: ' ans',
          button: 'VOIR LES AUTRES ARTICLES',
          home: 'Accueil',
          inspirationalArticlesTitle: 'Des articles pour m\'inspirer',
          infosZens: 'Infos Zens',
          pregnancy: 'grossesse',
          recommends: 'On vous recommande',
          titleInspirationalArticles: 'des articles pour m\'inspirer',
          titleParentsLife: 'Vie de parents'
        }
      }
    }
  }
}

export { articles }
