import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import useFunctionsTools from '../../../utils/useFunctionsTools'
import SubMenu from '../../Common/SubMenu/SubMenu'
import TitlePage from '../../Common/TitlePage/TitlePage'
import UnderTitle from '../../Common/UnderTitle/UnderTitle'
import useEventsTools from '../../Hooks/useEventsTools'
import useParamsTools from '../../Hooks/useParamsTools'
import './ConferencesWorkshops.scss'
import FirstPanel from './partials/FirstPanel/FirstPanel'
import ImgBlock from './partials/ImgBlock/ImgBlock'
import SecondPanel from './partials/SecondPanel/SecondPanel'

function ConferencesWorkshops () {
  const getLinksObject = (title, id, href, ariaControls) => {
    return { title, id, href, ariaControls }
  }
  const { fetchEventsInfoFromApi, futureEventsInfo, passedEventsInfo } = useEventsTools()
  const { convertDateWithTimezone } = useFunctionsTools()
  const { getStorage, getUserData } = useMpecSecure()
  const { getTextForPage } = useMpecTranslations('conferencesWorkshops')
  const { fetchParamsData, getIdTypeParamByTypeAndName, params } = useParamsTools()
  const [eventsLoad, setEventsLoad] = useState(false)
  const sessionId = getStorage().getItem('sessionId')
  const links = [
    getLinksObject(_.upperFirst(getTextForPage('event')), 'nav-event-tab', '#nav-event', 'nav-event'),
    getLinksObject(_.upperFirst(getTextForPage('passedEvent')), 'nav-replay-tab', '#nav-replay', 'nav-replay')
  ]

  useEffect(() => {
    fetchParamsData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (params) {
      prepareEvents().then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const displayBlocks = (event) => {
    return <ImgBlock key={uuidv4()} {...event}/>
  }

  const getAllImgBlocks = (passedEvents = false, futureEvents = false) => {
    return _.map(getBlocksInfos(passedEvents, futureEvents), group => {
      return displayBlocks(group)
    })
  }

  const getBlocksInfos = (passedEvents, futureEvents) => {
    const eventSchedules = getEventsDataByIdEvent(passedEvents, futureEvents)
    const blockInfos = []
    _.forEach(eventSchedules, scheduleData => {
      blockInfos.push(getOneBlock(scheduleData))
    })

    return blockInfos
  }

  const getEventsDataByIdEvent = (passedEvents, futureEvents) => {
    let result = {}
    let events = []
    if (passedEvents) {
      events = passedEventsInfo
    } else if (futureEvents) {
      events = futureEventsInfo
    }
    if (!_.isEmpty(events)) {
      _.forEach(events, event => {
        if (_.isEmpty(result[event.idEvent])) {
          result[event.idEvent] = []
        }
        result[event.idEvent].push(event)
      })
    }

    return result
  }

  const getOneBlock = (schedules) => {
    const isVariousParts = schedules.length > 1
    const scheduleData1 = _.head(schedules)
    const scheduleData2 = _.last(schedules)
    const openRegistrationDate = convertDateWithTimezone(scheduleData1.openRegistrationDate, scheduleData1.createdDate, scheduleData1.updatedDate)
    const closeRegistrationDate = convertDateWithTimezone(scheduleData1.closeRegistration, scheduleData1.createdDate, scheduleData1.updatedDate)

    const oneBlock = {
      idEvent: scheduleData1.idEvent,
      title: scheduleData1.title,
      resume: scheduleData1.resume,
      limitedPersons: scheduleData1.limit,
      category: scheduleData1.eventType,
      classSubTitleImg: scheduleData1.eventType === 'workshop' ? 'subTitleBlue' : 'subTitleGreen',
      closeRegistrationDate,
      imgTitle: _.upperFirst(getTextForPage(scheduleData1.eventType)),
      isLimited: scheduleData1.limit > 0,
      isRemote: scheduleData1.isRemote,
      isVariousParts: isVariousParts,
      openRegistrationDate,
      room: scheduleData1.room
    }

    const date1 = convertDateWithTimezone(scheduleData1.date, scheduleData1.createdDate, scheduleData1.updatedDate)
    const newHour1 = date1.clone().add(scheduleData1.duration, 'm').format(' à HH[h]mm')
    const date2 = convertDateWithTimezone(scheduleData2.date, scheduleData2.createdDate, scheduleData2.updatedDate)
    const newHour2 = date2.clone().add(scheduleData2.duration, 'm').format(' à HH[h]mm')
    const now = moment()

    if (isVariousParts) {
      oneBlock['roomDesc1'] = date1.format('DD MMMM Y [de] HH[h]mm') + newHour1
      oneBlock['roomDesc2'] = date2.format('DD MMMM Y [de] HH[h]mm') + newHour2
      oneBlock['room'] = date2.format('DD MMMM Y [de] HH[h]mm') + newHour2
      oneBlock['isPast'] = date2 < now
    } else {
      oneBlock['isPast'] = date1 < now
      oneBlock['roomDesc'] = date1.format('DD MMMM Y [de] HH[h]mm') + newHour1
    }

    return oneBlock
  }

  const prepareEvents = async () => {
    const idConference = parseInt(getIdTypeParamByTypeAndName('_eventsType', 'conference'))
    const idWorkshop = parseInt(getIdTypeParamByTypeAndName('_eventsType', 'workshop'))
    if (idWorkshop && idConference) {
      await fetchEventsInfoFromApi(getUserData().idCompany, sessionId, [idWorkshop, idConference], true).then()
      await fetchEventsInfoFromApi(getUserData().idCompany, sessionId, [idWorkshop, idConference], false, true).then()
      setEventsLoad(true)
    }
  }

  return (
    <div className={'ConferencesWorkshops content-wrapper'}>
      <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
      <div className="content container">
        <UnderTitle text={_.upperFirst(getTextForPage('description'))}/>
        <SubMenu links={links} col={'col-6'} colDiv={'col-lg-6 col-sm-12'}/>
        <div className="tab-content" id="nav-tabContent">
          <FirstPanel events={futureEventsInfo} eventsLoad={eventsLoad} getAllImgBlocks={getAllImgBlocks.bind(this)}/>
          <SecondPanel events={passedEventsInfo} eventsLoad={eventsLoad} getAllImgBlocks={getAllImgBlocks.bind(this)}/>
        </div>
      </div>
    </div>
  )
}

export default ConferencesWorkshops
