import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'

function GreyBlock (props) {
  const { getTextForPage, getTextButton } = useMpecTranslations('coachingParentsZens')
  const [title] = useState(props.title || getTextForPage('greyBlock.titleBlock'))
  const radios = [
    { text: 'phone', id: 'phone' },
    { text: 'visio', id: 'visio' },
    { text: 'notPreference', id: 'notPreference' }
  ]
  const clicked = props.clicked
  const displayErrorMessage = props.displayErrorMessage
  const handleChangeConditions = props.handleChangeConditions
  const handleChangeFavorite = props.handleChangeFavorite
  const handleChangeSubject = props.handleChangeSubject

  const displayRadios = () => {
    return _.map(radios, (radio, index) => {
      return (
        <div key={index} className="form-check">
          <input
            onChange={handleChangeFavorite}
            className="form-check-input"
            type="radio"
            name="favorite"
            id={radio.id}
          />
          <label className="form-check-label" htmlFor={radio.id}>
            {getTextForPage(`greyBlock.${radio.text}`)}
          </label>
        </div>
      )
    })
  }

  return (
    <div className={'col-lg-5 greyBlock mb-5'}>
      <h2 className={'page-subtitle mb-5'}>{title}</h2>
      <p className={'favoriteLabel'}>{getTextForPage('greyBlock.text1Block')}</p>
      {displayRadios()}
      <p className={'mb-0 mt-5'}>{getTextForPage('greyBlock.text5Block')}</p>
      <p className={'smallText'}>{getTextForPage('greyBlock.text6Block')}</p>
      <div className="textarea-radius mt-5">
        <textarea onChange={(event) => {handleChangeSubject(event)}} id="subjectArea" className={'d-block ml-auto mr-auto mb-5'}/>
        <p>{getTextForPage('greyBlock.text7Block')}</p>
        <div className="custom-control custom-checkbox mb-3">
          <input
            type="checkbox" className="custom-control-input mr-3"
            id="inscription" value="inscription"
            onChange={(event) => {
              handleChangeConditions('inscription', event)
            }}
          />
          <label className="inscriptionLabel custom-control-label ml-5 pl-4"
                 htmlFor="inscription">
            {getTextForPage('greyBlock.text8Block')}</label>
        </div>
        {displayErrorMessage()}
        <Button
          title={_.upperFirst(getTextButton('inscription'))}
          classSup={'btnGreen pr-5 pl-5'}
          classSupDiv={'mr-auto ml-auto'}
          isButtonWithAction={true}
          buttonFunction={clicked.bind(this)}
        />
        <p className={'font-weight-bold font-italic text-center'}>{getTextForPage('greyBlock.text9Block')}</p>
      </div>
    </div>
  )
}

GreyBlock.propTypes = {
  title: PropTypes.string
}

export default GreyBlock