import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import useMpecTranslations from '../../../../Tools/translations'

moment.locale('fr')

function Introduction () {
  const { getTextForPage } = useMpecTranslations('legalInformation')
  let lastUpdateDate = moment().set({ year: 2021, month: 8, date: 17 })

  return (
    <div className="container">
      <div className={'row'}>
        <div className={'col-12 d-flex flex-rows'}>
          <p className={'text-bold mr-2 col-4'}>{_.upperFirst(getTextForPage('introduction.lastUpdateDate'))}:</p>
          <p>{lastUpdateDate.format('D/M/Y')}</p>
        </div>
        <div className={'col-12 d-flex flex-rows'}>
          <p className={'text-bold mr-2 col-4'}>{_.upperFirst(getTextForPage('introduction.directorOfPublication.title'))}:</p>
          <p>{_.upperFirst(getTextForPage('introduction.directorOfPublication.value'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{_.upperFirst(getTextForPage('introduction.dataCollected'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{_.upperFirst(getTextForPage('introduction.dataRecipients'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{_.upperFirst(getTextForPage('introduction.dataProcessingAndFreedom'))}:</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{_.upperFirst(getTextForPage('introduction.companyName'))}</p>
          <p className={'col-12 mb-0'}><a href={`${process.env.REACT_APP_LINK_MPEC}`} target={'_blank'} rel={'noreferrer'}>{getTextForPage('introduction.companyWebsite')}</a></p>
          <p className={'col-12 mb-0'}>{_.upperFirst(getTextForPage('introduction.companyStreet'))}</p>
          <p className={'col-12'}>{_.upperFirst(getTextForPage('introduction.zipCodeCity'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{_.upperFirst(getTextForPage('introduction.oppositionToDataProcessing'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{_.upperFirst(getTextForPage('introduction.websiteInformation'))}</p>
        </div>
      </div>
    </div>
  )
}

export default Introduction
