import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TitlePage from '../../../Common/TitlePage/TitlePage'
import usePagesTools from '../../../Hooks/usePagesTools'
import FirstBlock from './partials/FirstBlock/FirstBlock'

function BabysitorMainPage (props) {
  const { fetchPageData, pageData, spinner } = usePagesTools()
  const [isCera] = useState(props.isCera || false)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    fetchPageData('complementaryGuard').then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'ComplementaryGuard content-wrapper'}>
      {isLoad ?
        <div className="content smallContainer">
          <TitlePage title={pageData.title}/>
          <div className="container">
            <div className="tab-content" id="nav-tabContent">
              <FirstBlock isCera={isCera} pageData={pageData}/>
            </div>
          </div>
        </div> :
        spinner()
      }
    </div>
  )
}

BabysitorMainPage.propTypes = {
  isCera: PropTypes.bool
}

export default BabysitorMainPage