import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import useMpecTranslations from '../../../Tools/translations'
import useWichTerminal from '../../Hooks/useWichTerminal'
import './Footer.scss'

function Footer (props) {
  const { getTextForPage, getRouteFor } = useMpecTranslations('footer')
  const { isMobile } = useWichTerminal()
  const [mode] = useState(props.mode || 'site')

  const displayMainFooter = () => {
    return (
      <footer className="Footer main-footer">
        <div className="row justify-content-center ml-sm-5 mr-sm-5 align-items-center">
          <div className={'spacing-footer'}>
            <strong className={'col-sm-4'}>
              {getTextForPage('copy')} &copy;{getTextForPage('year')}
              <a href="/"> {getTextForPage('parentszens')}</a>.
            </strong>
            {getTextForPage('right')}
          </div>
          <span className={'text-center col-sm-4 spacing-footer'}>
              {_.upperFirst(getTextForPage('withLove.beforeHeart'))}
            <FontAwesomeIcon icon={faHeart} className={'nav-icon '}/>
            {getTextForPage('withLove.afterHeart')}
            </span>
          <div className={'row text-center col-sm-12 col-lg-4 align-items-center'}>
            <a href={getRouteFor('legalInformation', {}, true)} className={'col-sm-12 col-lg-5 spacing-footer'}>{_.upperFirst(getTextForPage('legalMentions'))}</a>
            <a href={`${process.env.REACT_APP_LINK_MPEC}`} className={'col-sm-12 col-lg-7'}>{getTextForPage('return')}</a>
          </div>
        </div>
      </footer>
    )
  }

  const displayFooter = () => {
    switch (mode) {
      case 'login':
        return isMobile() ? displayLoginFooterMobile() : displayLoginFooter()
      case 'site':
      default:
        return displayMainFooter()
    }
  }

  const displayLoginFooter = () => {
    return (
      <footer className="login-footer d-flex flex-column margin-mobile mt-auto">
        <div className={'row justify-content-center ml-sm-5 mr-sm-5 align-items-center'}>
          <div className={'text-center col-sm-4 p-0 spacing-footer'}>
            <strong>
              {getTextForPage('copy')} &copy;{getTextForPage('year')}
              &nbsp;<a href="/">{getTextForPage('parentszens')}</a>.
            </strong>
            &nbsp;{getTextForPage('right')}
          </div>
          <span className={'text-center col-sm-4 spacing-footer'}>
            {_.upperFirst(getTextForPage('withLove.beforeHeart'))}
            <FontAwesomeIcon icon={faHeart} color={'red'} className={'nav-icon'}/>
            {getTextForPage('withLove.afterHeart')}
          </span>
          <div className={'row text-center col-sm-12 col-lg-4'}>
            <a href={`${process.env.REACT_APP_LINK_MPEC}`} className={'col-sm-12 col-lg-7'}>{getTextForPage('return')}</a>
          </div>
        </div>
      </footer>
    )
  }

  const displayLoginFooterMobile = () => {
    return (
      <footer className={'Footer'}>
        <div className="overflow-hidden h-100 w-100">
          <div className="round-blue"></div>
        </div>
      </footer>
    )
  }

  return (
    displayFooter()
  )
}

Footer.propTypes = {
  mode: PropTypes.string
}

export default Footer
