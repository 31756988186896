import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import WebFontLoader from 'webfontloader'
import App from './App'
import WindowDimensionsProvider from './Components/Providers/WindowDimensionsProvider'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import './scss/AdminLTE.scss'

ReactDOM.render(
  <WindowDimensionsProvider>
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </I18nextProvider>
  </WindowDimensionsProvider>,
  document.getElementById('root')
)

// load font
WebFontLoader.load(
  {
    google: {
      families: [
        'Roboto:400,500,700:latin-ext'
      ]
    }
  }
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
