import Axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useState } from 'react'
import useFunctionsTools from '../utils/useFunctionsTools'

function useMpecSecure () {
  const { isEmail } = useFunctionsTools()

  const getStorage = () => {
    return sessionStorage
  }

  // have to on top to avoid error of using function before declration
  const getToken = () => {
    const tokenString = getStorage().getItem('token')
    const userToken = JSON.parse(tokenString)
    return userToken
  }

  const getUserData = (value = 'all') => {
    const userData = JSON.parse(getStorage().getItem('userData'))
    if (value === 'all') {
      return userData
    } else {
      return _.get(userData, value, '')
    }
  }

  const [token, setToken] = useState(getToken())
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const source = Axios.CancelToken.source()

  const disconnectUser = () => {
    getStorage().removeItem('token')
    setToken(false)
    document.location.href = '/'
  }

  const handleChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleChangeUserName = (event) => {
    setUserName(event.target.value)
  }

  const login = async () => {
    try {
      const result = await Axios.post('/api/auth', { username: userName, password: Buffer.from(password).toString('base64') }, {
        cancelToken: source.token
      })

      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const comparePasswords = (newPassword, confirmation) => {
    return newPassword === confirmation
  }

  const isValidPassword = (password) => {
    const reg = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9!@#%*+=._-]{6,}$/g
    return reg.test(password)
  }

  const createChangeRequestPassword = async (email) => {
    try {
      const result = await Axios.post('/api/auth/create-change-password-request', { email }, {
        cancelToken: source.token
      })
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getOnePhoneNumber = (phones) => {
    const firstKeyPhone = Object.keys(phones[0])[0]
    let phoneNumber

    _.each(phones, (phone, key) => {
      if (_.isObject(phone)) {
        const phoneDefault = _.find(phone, (phoneDetail) => {
          return phoneDetail.default
        })
        if (_.get(phoneDefault, 'default')) {
          phoneNumber = phoneDefault.phone
        }
      }
    })

    return (phoneNumber) ? phoneNumber : _.head(phones)[firstKeyPhone].phone
  }

  const setMyToken = (data) => {
    if (_.has(data, 'token')) {
      getStorage().setItem('token', JSON.stringify(_.get(data, 'token')))
      setToken(_.get(data, 'token'))
    }

    if (_.has(data, 'session')) {
      const endSessionDate = moment.tz(_.get(data, 'session.end.date'), _.get(data, 'session.end.timezone'))
      getStorage().setItem('sessionEnd', endSessionDate.unix())
      getStorage().setItem('sessionId', _.get(data, 'session.id'))
    }

    const email = isEmail(data?.user?.login) ? data.user.login : _.head(data?.userData?.email)
    data.userData.emails.push(email)
    data.userData.phones.push(_.head(data?.userData?.phone))
    if (_.has(data, 'user')) {
      let photo = _.get(data, 'user.photo', null)
      const companyName = Object.keys(_.get(data, 'userData.services', {}))[0]
      const idCompany = data?.userData?.idsCompanies[companyName]
      const idUser = data?.user?.id
      const email = _.head(_.get(data, 'userData.emails', []))
      const defaultEmail = _.get(data, 'userData.defaultEmail', false)
      const firstname = _.get(data, 'user.firstname')
      const lastname = _.lowerCase(_.get(data, 'user.lastname'))
      const phone = getOnePhoneNumber(_.get(data, 'userData.phones', []))

      localStorage.setItem('envs', JSON.stringify(Object.keys(_.get(data, 'userData.services', {}))))

      if (_.isNull(photo)) {
        photo = _.lowerCase(firstname[0].concat(lastname)).concat('.jpg')
      }
      const userData = {}
      _.set(userData, 'companyName', companyName)
      _.set(userData, 'idCompany', idCompany)
      _.set(userData, 'email', email)
      _.set(userData, 'defaultEmail', defaultEmail)
      _.set(userData, 'firstname', firstname)
      _.set(userData, 'lastname', lastname)
      _.set(userData, 'phone', phone)
      _.set(userData, 'picture', photo)
      _.set(userData, 'idUser', idUser)
      getStorage().setItem('userData', JSON.stringify(userData))
    }
  }

  return {
    comparePasswords,
    createChangeRequestPassword,
    disconnectUser,
    getStorage,
    getToken,
    getUserData,
    handleChangePassword,
    handleChangeUserName,
    isValidPassword,
    login,
    password,
    token,
    setPassword,
    setMyToken,
    setUserName,
    userName
  }
}

export default useMpecSecure
