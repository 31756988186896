import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'
import { useHistory } from 'react-router'

function useNavigationTools () {
  const history = useHistory()
  const [searchData, setSearchData] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const getSearchData = () => {
    return searchData
  }

  const fetchSearch = async (search, filters) => {
    const source = Axios.CancelToken.source()

    try {
      const result = await Axios.post('/api/search/', { terms: search, filters, currentPage }, {
        cancelToken: source.token
      })

      setSearchData(result.data)
      setTotalPages(result.data.numberOfPage.totalPages)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const isActiveRoute = (route, menu = false) => {
    const regExp = document.location.pathname
    const match = regExp.match(route)
    const matchMenu = route.match(regExp)
    if (menu && _.has(matchMenu, 0)) {
      return matchMenu[0] === route ? ' active' : ''
    }
    return _.isNull(match) ? '' : ' active'
  }

  const isOpen = (route) => {
    const regExp = document.location.pathname
    const match = regExp.match(route)
    return _.isNull(match) ? '' : ' menu-open'
  }

  const redirectToRoute = (route, state) => {
    history.push(route, state)
  }

  return {
    currentPage,
    fetchSearch,
    getSearchData,
    isActiveRoute,
    isOpen,
    redirectToRoute,
    setCurrentPage,
    totalPages
  }

}

export default useNavigationTools
