import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useInspirationalArticlesTools from '../../Hooks/useInspirationalArticlesTools'
import useRoutesTools from '../../Hooks/useRoutesTools'
import Card from '../Card/Card'
import './ThreeBlockCards.scss'

function ThreeBlockCards (props) {
  const { getLastElement, getTextFromArticlesGroup } = useInspirationalArticlesTools()
  const { redirect } = useRoutesTools()
  const [allDataArticles, setAllDataArticles] = useState(props.allDataArticles)

  useEffect(() => {
    if (props.allDataArticles) {
      setAllDataArticles(props.allDataArticles)
    }
  }, [props])

  const displayAllCards = () => {
    const lastElementComponent = []

    const allDataArticlesGroup = _.chunk(allDataArticles, 3)
    const last = _.last(allDataArticlesGroup)

    if (last.length < 3) {
      const lastElement = allDataArticlesGroup.pop()
      lastElementComponent.push(getLastElement(lastElement))
    }

    const allCards = []
    _.forEach(allDataArticlesGroup, (articleGroup, index) => {
      if (index % 2 === 0) {
        allCards.push(displayOneCardTwoCard(articleGroup, uuidv4()))
      } else {
        allCards.push(displayTwoCardOneCard(articleGroup, uuidv4()))
      }
    })

    allCards.push(lastElementComponent)

    return allCards
  }
  const displayOneCardTwoCard = (articlesGroup, index) => {
    const text = getTextFromArticlesGroup(articlesGroup)
    return (
      <div className="row" key={index}>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text[0]}
                imgBackground={articlesGroup[0].img}
                classSup={'h-28'}
                space={'bigSpace'}
                isRoute={true}
                redirect={redirect.bind(this)}
                routeParam={{ title: _.kebabCase(articlesGroup[0]?.header?.title) }}
                route={'inspirationalArticlesView'}
          />
        </div>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text[1]}
                imgBackground={articlesGroup[1].img}
                classSup={'h-13'}
                isRoute={true}
                redirect={redirect.bind(this)}
                routeParam={{ title: _.kebabCase(articlesGroup[1]?.header?.title) }}
                route={'inspirationalArticlesView'}
          />
          <Card key={uuidv4()}
                allTexts={text[2]}
                imgBackground={articlesGroup[2].img}
                classSup={'h-13'}
                isRoute={true}
                redirect={redirect.bind(this)}
                routeParam={{ title: _.kebabCase(articlesGroup[2]?.header?.title) }}
                route={'inspirationalArticlesView'}
          />
        </div>
      </div>
    )
  }
  const displayTwoCardOneCard = (articlesGroup, index) => {
    const text = getTextFromArticlesGroup(articlesGroup)
    return (
      <div className="row" key={index}>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text[0]}
                imgBackground={articlesGroup[0].img}
                classSup={'h-13'}
                isRoute={true}
                redirect={redirect.bind(this)}
                routeParam={{ title: _.kebabCase(articlesGroup[0]?.header?.title) }}
                route={'inspirationalArticlesView'}
          />
          <Card key={uuidv4()}
                allTexts={text[1]}
                imgBackground={articlesGroup[1].img}
                classSup={'h-13'}
                isRoute={true}
                redirect={redirect.bind(this)}
                routeParam={{ title: _.kebabCase(articlesGroup[1]?.header?.title) }}
                route={'inspirationalArticlesView'}
          />
        </div>
        <div className="col-lg-6">
          <Card key={uuidv4()}
                allTexts={text[2]}
                imgBackground={articlesGroup[2].img}
                classSup={'h-28'}
                space={'bigSpace'}
                isRoute={true}
                redirect={redirect.bind(this)}
                routeParam={{ title: _.kebabCase(articlesGroup[2]?.header?.title) }}
                route={'inspirationalArticlesView'}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      {displayAllCards()}
    </div>
  )
}

ThreeBlockCards.propTypes = {
  allDataArticles: PropTypes.array.isRequired
}
export default ThreeBlockCards
