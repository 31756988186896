import Axios from 'axios'
import _ from 'lodash'

function useDownloadTools () {
  const source = Axios.CancelToken.source()

  const download = async (data) => {
    try {
      const contentType = getFileContentType(_.get(data, 'fileToGet', false))
      const result = await Axios.post('/api/download', data, {
        cancelToken: source.token,
        responseType: 'blob'
      })
      const fileBlob = new Blob([result.data], {
        type: contentType
      })
      require('downloadjs')(fileBlob, data.fileToGet, contentType)
      return fileBlob
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getExtension = (filename) => {
    if (filename) {
      return filename.split('.').pop()
    }
    return false
  }

  const getFileContentType = (filename) => {
    const extension = getExtension(filename)
    let fileContentType = ''
    switch (extension) {
      case 'gif':
        fileContentType = 'image/gif'
        break
      case 'jpeg':
      case 'jpg':
        fileContentType = 'image/jpg'
        break
      case 'txt':
        fileContentType = 'text/plain'
        break
      case 'html':
        fileContentType = 'text/html'
        break
      case 'png':
        fileContentType = 'image/png'
        break
      case 'svg':
        fileContentType = 'image/svg+xml'
        break
      case 'xml':
        fileContentType = 'application/xml'
        break
      case 'pdf':
      default:
        fileContentType = 'application/pdf'
    }
    return fileContentType
  }

  return {
    download,
    getExtension
  }
}

export default useDownloadTools
