import _ from 'lodash'
import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { v4 as uuidv4 } from 'uuid'
import H2 from '../../HX/H2'
import H3 from '../../HX/H3'
import H4 from '../../HX/H4'

function GenerateText (props) {
  const [title] = useState(props.title || false)
  const [message] = useState(props.message || [])
  const [messageDiv] = useState(props.messageDiv || '')
  const [multipleMessage] = useState(props.multipleMessage || false)

  const createTitle = (type, text, className) => {
    switch (type) {
      case 'h2':
        return <H2 key={uuidv4()} text={text} className={className}/>
      case 'h3':
        return <H3 key={uuidv4()} text={text} className={className}/>
      case 'h4':
        return <H4 key={uuidv4()} text={text} className={className}/>
      default:
        return ''
    }
  }

  const createText = (value) => {
    return ReactHtmlParser(value)
  }

  const getContent = () => {
    const content = []
    if (title) {
      content.push(createTitle(title.type, title.text, title.className))
    }

    if (multipleMessage && message) {
      _.forEach(message, (text) => {
        let elements = []
        _.map(text.elements, (element) => {
          elements.push(createText(element))
        })
        content.push(
          <div className={text.classDiv} key={uuidv4()}>
            {elements}
            {
              text.isAttributionCondition === true ?
                <div>
                  <p>Les dossiers de candidature les plus complets permettront une meilleure attribution des places.</p>
                  <p>Les informations fournies par le salarié devront être justifiées au moment de l'inscription définitive. Toute erreur ou omission importante pourra entraîner
                    l'annulation de la candidature.</p>
                </div> : ''
            }
          </div>
        )
      })
    } else if (message) {
      _.forEach(message, (text) => {
        content.push(createText(text))
      })
    }

    return _.isEmpty(messageDiv) ? content : <div className={messageDiv}>{content}</div>
  }

  return getContent()
}

export default GenerateText
