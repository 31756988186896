const myProfile = {
  fr: {
    translation: {
      pages: {
        myProfile: {
          title: 'mon profil',
          information: 'mes informations',
          checkboxInformation: {
            accompanyTheChildren: 'accompagner les enfants dans leur apprentissage',
            accompanyingChildrenInTheirLearning: 'accompagner les enfants dans leur apprentissage',
            activities: 'activités',
            adviceAndCoaching: 'conseils et coaching',
            articles: 'articles',
            balanceLife: 'equilibre vie pro/vie perso',
            boostingParentingSkills: 'booster les compétences parentales',
            conference: 'conférences et ateliers',
            conferencesAndWorkshops: 'conférences et ateliers',
            corporateParenting: 'parentalité et entreprise',
            parenthoodAndBusiness: 'parentalité et entreprise',
            contentType: 'type de contenu',
            developingSkills: 'développer les compétences relationnelles',
            developInterpersonalSkills: 'développer les compétences relationnelles',
            elevenFifteenYear: '11/15 ans',
            elevenToFifteen: '11/15 ans',
            fifteenEighteenYear: '15/18 ans',
            fifteenToEighteen: '15/18 ans',
            health: 'santé / Bien-être',
            healthWellness: 'santé / Bien-être',
            informations: '*Ces informations sont indispensables pour l\'accès à l\'ensemble de vos services',
            interest: 'mes centres d\'intérêt',
            pregnancy: 'grossesse',
            pregnancyAndBackToTheOffice: 'grossesse',
            sixElevenYear: '6/11 ans',
            sixToEleven: '6/11 ans',
            sliceOfLife: 'tranche de vie',
            threeSixYear: '3/6 ans',
            threeToSix: '3/6 ans',
            theme: 'thématique',
            workLifeBalance: 'equilibre vie pro/vie perso',
            zeroThreeYear: '0/3 ans',
            zeroToThree: '0/3 ans'
          },
          inputInformation: {
            birthDate: 'ma date de naissance',
            emailPro: 'mon email professionnel*',
            firstName: 'mon Prénom *',
            grandParent: 'grand-parent',
            greatParent: 'grand-parent',
            iAm: 'je suis',
            m: 'un homme',
            man: 'un homme',
            me: 'une femme',
            mlle: 'une jeune femme',
            myCompany: 'mon entreprise',
            name: 'mon nom *',
            parent: 'parent',
            phoneNumber: 'mon numéro de téléphone*',
            role: 'mon rôle',
            woman: 'une femme'
          },
          inputPassword: {
            actualPassword: 'mon mot de passe actuel',
            confirmPassword: 'confirmer mon mot de passe',
            modifyPassword: 'modifier mon mot de passe',
            newPassword: 'nouveau mot de passe**',
            passwordInfo: '**Votre mot de passe doit comprendre au moins une majuscule, une minuscule et un chiffre et peut contenir des caractères spéciaux parmi la liste suivante ',
            passwordSpecialCharacters: '! @ # $ % ^ & * ) ( + = . _ - = '
          },
          error: {
            newMustDifferFromActual: 'le nouveau mot de passe ne peut être identique à l\'actuel !!',
            noMatchingPassword: 'le nouveau mot de passe et sa confirmation ne sont pas identiques',
            passwordNotConformToTheRule: 'votre mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre et au moins 6 caractères de long, merci de corriger votre saisie',
            theGivenActualPasswordIsNotValidPleaseRetryWithAnAnotherPassword: 'le mot de passe actuel n\'est pas celui enregistré dans notre système, merci de vérifier votre saisie'
          },
          success: {
            weChangeYourPasswordWeAreGoingToDisconnectYou: 'votre mot de passe a bien été changé, nous allons vous déconnecter'
          }
        }
      }
    }
  }
}

export { myProfile }
