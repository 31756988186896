import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../Tools/translations'
import H3 from '../HX/H3'
import './Filter.scss'

function Filter (props) {
  const addFilterOrRemove = props.addFilterOrRemove
  const { getTextFilter } = useMpecTranslations()
  const [allFilters, setAllFilters] = useState(props.allFilters || [])

  useEffect(() => {
    if (props.allFilters) {
      setAllFilters(props.allFilters)
    }
  }, [props])

  const setCurrentFilter = (event) => {
    const name = event.target.name
    addFilterOrRemove(name)
  }

  const displayInput = () => {
    return allFilters.map((value, index) => {
      return <div key={index} className="form-check form-check-inline">
        <input className="form-check-input" name={value.title} onClick={setCurrentFilter} id={value.id} type="checkbox" value={value.value}/>
        <label className="form-check-label" htmlFor={value.htmlFor}>{_.upperFirst(getTextFilter(value.title))}</label>
      </div>
    })
  }

  return (
    <div className={'Filter'}>
      <H3 text={'Filtres'} className={'size-title'}/>
      {displayInput()}
    </div>

  )
}

Filter.propTypes = {
  addFilterOrRemove: PropTypes.func,
  allFilters: PropTypes.array
}

export default Filter
