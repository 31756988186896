import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import useArticlesTools from '../../../../Hooks/useArticlesTools'
import useWichTerminal from '../../../../Hooks/useWichTerminal'

function FirstBlock (props) {
  const { getTextForPage } = useMpecTranslations('searchResults')
  const {
    fetchCategories,
    fetchListOfFilterActivities,
    fetchListOfFilterArticles,
    getAllFiltersActivities,
    getAllFiltersArticles,
    getCategories
  } = useArticlesTools()
  const { isMobile } = useWichTerminal()
  const [toggle, setToggle] = useState(false)
  const [mySearch] = useState(props.mySearch)
  const addFilterOrRemove = props.addFilterOrRemove
  const setSearch = props.setSearch

  useEffect(() => {
    fetchListOfFilterArticles('', 'filtersSearch').then()
    fetchListOfFilterActivities('', 'filtersSearch').then()
    fetchCategories().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupsFilters = () => {
    const filtersArticles = getAllFiltersArticles()
    const filtersActivities = getAllFiltersActivities()
    const filterCategories = getCategories()

    return {
      activities: filtersActivities,
      articles: filtersArticles,
      age: filterCategories
      //events: [],
      //services: []
    }
  }

  const getOnClick = () => {
    setToggle(!toggle)
  }

  const toggleDiv = (event, category) => {
    const activity = $(`.${category}`)
    const activityId = $(`#${category}`)

    if (activity.hasClass('active')) {
      activity.removeClass('active')
      activityId.removeClass('active')
    } else {
      activity.addClass('active')
      activityId.addClass('active')
    }
  }

  const setCurrentFilter = (event, isParentFilter = false, parentFilter) => {
    const name = event.target.name

    addFilterOrRemove(name, isParentFilter, parentFilter)
  }

  const displayFirstFormControlWithToggle = () => {
    return _.map(groupsFilters(), (filters, key) => {
      return (
        <div className="category">
          <div className="heading">
            <FormControlLabel
              control={<Checkbox name={key} color="primary"/>}
              id={key.concat('-label')}
              label={_.upperFirst(getTextForPage(`firstBlock.${key}`))}
              onChange={(event) => {
                toggleDiv(event, key)
                setCurrentFilter(event, true)
              }}
            />
          </div>
          <div className={`${key} content ml-5 d-flex flex-column`}>
            {
              _.map(filters, (filter) => {
                return <FormControlLabel
                  control={<Checkbox id={filter.title} name={filter.title} color="primary" value={filter.title}/>}
                  key={filter.title}
                  label={_.upperFirst(getTextForPage('firstBlock.' + filter.title) + ' ')}
                  onChange={(event) => {
                    setCurrentFilter(event, false, key)
                  }}
                />
              })
            }
          </div>
        </div>
      )
    })
  }

  const displayMobile = () => {
    return (
      <div className="col-lg-3 mr-3 pl-4 pr-4 pt-3 firstBlock mb-5">
        <div className="d-flex" onClick={(event) => {
          getOnClick(event)
        }}>
          <FontAwesomeIcon icon={faFilter} className={'nav-icon'}/>
          <p className={'font-weight-bold'}>{_.upperFirst(getTextForPage('firstBlock.filterBy'))}</p>
        </div>
      </div>
    )
  }

  const displayDesktop = () => {
    return (
      <div className="col-lg-3 mr-3 pb-4 pl-4 pr-4 pt-5 firstBlock mb-5">
        <form className="search d-flex pb-3 pt-3 mb-5">
          <FontAwesomeIcon icon={faSearch} className={'nav-icon'}/>
          <input
            className={'p-0'}
            onChange={event => setSearch(event.target.value)}
            placeholder={_.upperFirst(getTextForPage('firstBlock.searchExample'))}
            type={'text'}
            defaultValue={mySearch}
          />
        </form>
        <div className="d-flex justify-content-center" onClick={(event) => {
          getOnClick(event)
        }}>
          <FontAwesomeIcon icon={faFilter} className={'nav-icon'}/>
          <p className={'font-weight-bold'}>{_.upperFirst(getTextForPage('firstBlock.filterBy'))}</p>
        </div>
        {displayFirstFormControlWithToggle()}
      </div>
    )
  }

  const display = () => {
    if (isMobile() && toggle) {
      return displayDesktop()
    } else if (isMobile()) {
      return displayMobile()
    }

    return displayDesktop()
  }

  return (
    display()
  )
}

export default FirstBlock
