import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function useMpecTranslations (pageNameForTranslation) {
  const { t, i18n } = useTranslation()
  const [pageName] = useState(pageNameForTranslation)

  const getPageNameForTranslation = () => {
    return _.camelCase(pageName)
  }

  const getRouteFor = (part, object, fromRoot = false) => {
    const rootPath = fromRoot ? '/' : ''
    if (!_.isEmpty(object)) {
      let baseRoute = t(`routes.${part}`, { returnObjects: true })
      const newRoute = getRouteTransform(baseRoute, object)
      return rootPath.concat(t(newRoute, { returnObjects: true, ...object }))
    }

    const exist = i18n.exists(`routes.${part}`)
    if (exist) {
      return rootPath.concat(t(`routes.${part}`, { returnObjects: true }))
    } else {
      return '#'
    }
  }

  const getRouteTransform = (route, object) => {
    const keys = _.keys(object)
    let newRoute = route
    _.forEach(keys, key => {
      newRoute = _.replace(newRoute, ':' + key, '{{' + key + '}}')
    })

    return newRoute
  }

  const getTextButton = (part, object) => {
    return t(`button.${part}`, { returnObjects: true, ...object })
  }

  const getTextCompaniesNames = (part) => {
    return t(`companiesNames.${part}`)
  }

  const getTextInSpecificLanguage = (lng, prefix, part) => {
    if (lng && prefix && part) {
      const tmpTranslator = i18n.getFixedT(lng, null, prefix)
      return tmpTranslator(part)
    }
  }

  const getTextParamsUrl = (part) => {
    return t(`paramsUrl.${part}`)
  }

  const getTextFilter = (part) => {
    const translation = t(`filter.${part}`, { returnObjects: true })
    if (isTranslationExist(translation, 'filter')) {
      return translation
    }

    return part
  }

  const getTextForPage = (part, variable = {}) => {
    const translation = t(`pages.${getPageNameForTranslation()}.${part}`, { returnObjects: true, ...variable })
    if (isTranslationExist(translation, 'pages')) {
      return translation
    }
    return part
  }

  const getText = (part, variable = {}) => {
    const translation = t(`pages.${part}`, { returnObjects: true, ...variable })
    if (isTranslationExist(translation, 'pages')) {
      return translation
    }
    return part
  }

  const getTextForHeader = (part) => {
    const translation = t(`header.${part}`, { returnObjects: true })
    if (isTranslationExist(translation, 'header')) {
      return translation
    }
    return part
  }

  const getTextFormsLabels = (labelName, formName) => {
    return (_.isEmpty(formName)) ? t(`formsLabels.common.${labelName}`) : t(`formsLabels.${formName}.${labelName}`)
  }

  const getTextForBreadCrumb = (part) => {
    return t(`breadcrumb.${part}`)
  }

  const isTranslationExist = (translation, key) => {
    return !_.includes(translation, key + '.')
  }

  return {
    getRouteFor,
    getText,
    getTextButton,
    getTextCompaniesNames,
    getTextFilter,
    getTextForHeader,
    getTextFormsLabels,
    getTextInSpecificLanguage,
    getTextForBreadCrumb,
    getTextForPage,
    getTextParamsUrl
  }
}

export default useMpecTranslations
