const searchResults = {
  fr: {
    translation: {
      pages: {
        searchResults: {
          activity: 'Activité',
          bille: 'Le sac de billes',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
          seePage: 'voir la page ',
          searchExample: 'Recherche "Exemple"',
          firstBlock: {
            '0-3': '0-3 ans',
            '3-6': '3-6 ans',
            '6-11': '6-11 ans',
            '11-15': '11-15 ans',
            '15-18': '15-18 ans',
            activities: 'activités',
            age: 'âge',
            articles: 'articles',
            audioBook: 'livre audio',
            beautyMode: 'mode et beauté',
            college: 'collège',
            coloring: 'coloriages',
            culture: 'culture',
            development: 'développement',
            drawning: 'dessin',
            events: 'évènements',
            familyLife: 'vie de famille',
            filterBy: 'filtrer Par :',
            food: 'nourriture',
            gardening: 'jardinage',
            health: 'santé',
            highSchool: 'lycée',
            nursery: 'crèche',
            podcast: 'podcast',
            point: '...',
            pregnancy: 'grossesse',
            recipes: 'recettes',
            school: 'école',
            searchExample: 'recherche "Exemple"',
            services: 'services',
            song: 'chanson',
            sport: 'sport'
          },
          title: 'résultats de votre recherche',
          secondBlock: {
            activities: 'activité',
            activity: 'activité',
            article: 'article',
            coloring: 'coloriage',
            seePage: 'voir la page '
          }
        }
      }
    }
  }
}

export { searchResults }