import _ from 'lodash'
import React, { useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import GreyBlock from '../../../CoachingParentsZens/partials/GreyBlock/GreyBlock'
import ThanksBlock from '../../../CoachingParentsZens/partials/ThanksBlock/ThanksBlock'

function Form () {
  const { getTextForPage } = useMpecTranslations('coachingParentsZensPlus')
  const [isSignedUp, setIsSignedUp] = useState(false)

  const signedUp = () => {
    setIsSignedUp(true)
  }

  const displayGreyBlock = () => {
    if (isSignedUp) {
      return <ThanksBlock title={_.upperFirst(getTextForPage('blockPlus.greenBlock.title'))}/>
    }

    return <GreyBlock signedUp={signedUp.bind(this)} title={_.upperFirst(getTextForPage('blockPlus.greenBlock.title'))}/>
  }

  return (
    (displayGreyBlock())
  )
}

export default Form
