import _ from 'lodash'
import { useEffect } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import useFormsTools from '../../../../Hooks/useFormsTools'

function ThanksPage () {
  const { getRouteFor, getTextForPage, getTextButton } = useMpecTranslations('register')
  const { clearLocalStorageData, initFromStorage } = useFormsTools()

  const redirectToLogin = (event) => {
    document.location.href = getRouteFor('homepage')
  }

  useEffect(() => {
    clearLocalStorageData('all', 'register')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'row'}>
      <div className={'col-12'}>
        <p className={'text-break-space'}>{getTextForPage('messageThanksPage', { login: initFromStorage('inputEmail', 'register') })}</p>
      </div>
      <div className={'col-12 d-flex justify-content-center'}>
        <Button
          isButtonWithAction={true}
          title={_.toUpper(getTextButton('redirectLoginScreen'))}
          buttonFunction={redirectToLogin}
        />
      </div>
    </div>
  )
}

export default ThanksPage
