import {faEnvelope, faMapMarkerAlt, faPhone} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Axios from "axios"
import _ from "lodash"
import React, {Fragment, useEffect, useState} from "react"
import {v4 as uuidv4} from "uuid"
import useMpecSecure from "../../../Tools/secure"
import useMpecTranslations from "../../../Tools/translations"
import Button from "../../Common/Button/Button"
import HeaderPage from "../../Common/HeaderPage/HeaderPage"
import TitlePage from "../../Common/TitlePage/TitlePage"
import useCompaniesTools from "../../Hooks/useCompaniesTools"
import useMailerTools from "../../Hooks/useMailerTools"
import useMpecServices from "../../Hooks/useMpecServices"
import useWichTerminal from "../../Hooks/useWichTerminal"
import "./Contact.scss"

function Contact() {
    const {contactInformations, getContactInformation} = useCompaniesTools()
    const {getEmailData, handleEmailTo, sendEmailForEvent} = useMailerTools()
    const {getUserData} = useMpecSecure()
    const {currentEnv} = useMpecServices()
    const {getTextButton, getTextCompaniesNames, getTextForPage} = useMpecTranslations("contact")
    const {isMobile} = useWichTerminal()
    const [company] = useState(getUserData("companyName"))
    const [successfulSending, setSuccessfulSending] = useState(false)

    useEffect(() => {
        getContactInformation(currentEnv).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEnv])

    const divData = [
        {text: "name", icon: false, class: "name mt-2 mb-0"},
        {text: "mobile", icon: faPhone, class: "phone mt-2 mb-0"},
        {text: "email", icon: faEnvelope, class: "email mb-2"},
        {text: "address", icon: faMapMarkerAlt, class: "address mb-2"},
        {text: "text", icon: false, class: "mb-2"},
    ]

    const renderContactInformation = (data, contact) => {
        const classNameMobile = isMobile() ? "mb-0 " : ""
        const icon = data.icon
        const text = _.get(contact, data.text, "")
        let className = "mycontent-left " + classNameMobile + _.get(data, "class", "")
        let divClassName = "col-8 col-lg-5 d-flex align-items-center"
        if (data.text === "text") {
            divClassName = "col-8 col-lg-12 d-flex align-items-center"
        }

        let result

        if (data.text === "mobile" && icon !== false) {
            result = <div className={className + " d-flex align-items-center"}>
                <FontAwesomeIcon size={"1x"} icon={icon} className={"nav-icon fontAwesome mr-2"}/>
                <a className={"m-0"} href={`tel:${text}`}>{text}</a>
            </div>
        } else if (icon !== false) {
            result = <div className={className + " d-flex align-items-center"}>
                <FontAwesomeIcon size={"1x"} icon={icon} className={"nav-icon fontAwesome  mr-2"}/>
                <p className={"m-0 ws-pre"}>{text}</p>
            </div>
        } else {
            result = <p className={className}>{text}</p>
        }

        return <div className={divClassName}>
            {result}
        </div>
    }

    const contactPart = (contact) => {
        return _.map(divData, (data, index) => {
            return (
                <Fragment key={uuidv4()}>
                    {renderContactInformation(data, contact)}
                </Fragment>
            )
        })
    }

    const displayContact = () => {
        return _.map(contactInformations, (contact, index) => {
            return (
                <div className={"row mt-2"} key={index}>
                    <div className="round-img col-12 col-lg-2 d-flex justify-content-center">
                        <img src={contact.img} alt="Parentszens" className={"rounded-circle ml-auto mr-auto"}/>
                    </div>
                    <div className={"col-12 col-lg-10 d-flex flex-wrap contactInfo"}>
                        {contactPart(contact)}
                    </div>
                </div>
            )
        })
    }

    const form = () => {
        return (<form className="col-lg-12 ml-lg-5 message" onSubmit={handleSubmit}>
            <h2 className={"subTitle"}>{_.upperFirst(getTextForPage("subTitle2"))}</h2>
            <p className={"smallSubtitle"}>{_.upperFirst(getTextForPage("message"))}</p>
            <textarea class="contactMessage" name={"message"} rows={"5"}></textarea>
            <Button
                className={"btn btn-sm pl-3 pr-3"}
                classDiv={"ButtonComponent buttonSend text-center mt-4 mb-4"}
                classSup={'btnGreenLight'}
                isButtonWithAction={true}
                title={_.upperFirst(getTextButton("send"))}
                type={"submit"}
            />
        </form>)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const userData = getUserData()
        const companyName = _.upperFirst(getTextCompaniesNames(userData.companyName))
        const emailTo = handleEmailTo(contactInformations)
        const subject = `[LesParentsZens] ${userData.email} ${userData.lastname} ${userData.firstname}`
        const variables = {
            "NomSociete": companyName,
            "Nom": userData.lastname,
            "Prénom": userData.firstname,
            "téléphone": userData.phone,
            "email": userData.email,
            "Message": event.target.message.value,
        }
        const data = getEmailData(3192917, userData, variables, subject, emailTo)

        try {
            const result = await sendEmailForEvent(data)
            setSuccessfulSending(result)
        } catch (error) {
            // eslint-disable-next-line no-empty
            if (Axios.isCancel(error)) {
            } else {
                throw error
            }
        }
    }

    const messageSuccessfulSending = () => {
        return (
            <div className={"row"}>
                <div className={"col-12 ml-auto mr-5 col-lg-10 mb-5 text-center"}>
                    <p>{getTextForPage("successfulSending")}</p>
                    <p>{getTextForPage("teamLPZ")}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="Contact content-wrapper">
            <HeaderPage company={company} imgBannerBackground={"picture-contactPage"}/>
            <div className={"container"}>
                <TitlePage title={_.upperFirst(getTextForPage("title"))}/>
                <h2 className={"subTitle text-center mb-3"}>{_.upperFirst(getTextForPage("subTitle"))}</h2>
                {displayContact()}
                <p className={"p-3 mb-2 contactText"}>{_.get(contactInformations, "text", "")}</p>
                {!successfulSending ? form() : messageSuccessfulSending()}
            </div>
        </div>
    )
}

export default Contact
