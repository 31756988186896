import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

function HiddenText (props) {
  const [messages] = useState(props.messages)
  const [visibility, setVisibility] = useState(props.hiddenTextState.visibility)

  useEffect(() => {
    setVisibility(props.hiddenTextState.visibility)
  }, [props.hiddenTextState.visibility])

  const createText = (value) => {
    return ReactHtmlParser(value)
  }

  const getContent = () => {
    const content = []

    if (messages) {
      _.forEach(messages, (text) => {
        content.push(createText(text))
      })
    }

    return content
  }

  return (
    <>
      {visibility &&
      <div>{getContent()}</div>
      }
    </>
  )
}

export default HiddenText
