import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'

function useParamsTools () {
  const [params, setParams] = useState([])

  const fetchParamsData = async (sessionId) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/params/get-data', { sessionId }, {
        cancelToken: source.token
      })
      setParams(result.data)
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getParams = () => {
    return params
  }

  const getIdTypeParamByTypeAndName = (type, name, paramsFromParent = null) => {
    let actualParams = [...params]
    if (!_.isNull(paramsFromParent)) {
      actualParams = paramsFromParent
    }

    if (!_.isUndefined(actualParams) && !_.isUndefined(actualParams[0])) {
      const paramsTypeData = actualParams[0][type]
      let result = null
      _.forEach(paramsTypeData, (param) => {
        const nameFromParam = _.head(_.values(param))
        const keyFromParam = _.head(_.keys(param))
        if (nameFromParam === name) {
          result = keyFromParam
          return true
        }
      })

      return result
    }
  }

  return {
    fetchParamsData,
    getIdTypeParamByTypeAndName,
    getParams,
    params
  }
}

export default useParamsTools