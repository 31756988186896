import _ from 'lodash'
import { filter } from '../Components/Common/Filter/translations'
import { footer } from '../Components/Common/Footer/translation'
import { header } from '../Components/Common/Header/translations'
import { notAllowed } from '../Components/Common/NotAllowed/translations'
import { activity } from '../Components/Pages/Activity/translation'
import { articles } from '../Components/Pages/Articles/translation'
import { blocParentsLife } from '../Components/Pages/BlocParentsLife/translations'
import { coachingParentsZens } from '../Components/Pages/CoachingParentsZens/translations'
import { coachingParentsZensPlus } from '../Components/Pages/CoachingParentsZensPlus/translations'
import { conferenceInscription } from '../Components/Pages/ConferenceInscription/translations'
import { conferencesWorkshops } from '../Components/Pages/ConferencesWorkshops/translations'
import { contact } from '../Components/Pages/Contact/translations'
import { gazouyi } from '../Components/Pages/Gazouyi/translation'
import { homePage } from '../Components/Pages/HomePage/translations'
import { inspirationalArticles } from '../Components/Pages/InspirationalArticles/translations'
import { legalInformation } from '../Components/Pages/LegalInformation/translations'
import { login } from '../Components/Pages/Login/translations'
import { myJourney } from '../Components/Pages/MyJourney/translations'
import { myProfile } from '../Components/Pages/MyProfile/translation'
import { nurseryPlace } from '../Components/Pages/NurseryPlace/translations'
import { ourSelectedPartners } from '../Components/Pages/OurSelectedPartners/translations'
import { parentsLife } from '../Components/Pages/ParentsLife/translations'
import { register } from '../Components/Pages/Register/translations'
import { replay } from '../Components/Pages/Replay/translations'
import { returnMaternityPaternityLeave } from '../Components/Pages/ReturnMaternityPaternityLeave/translations'
import { searchResults } from '../Components/Pages/SearchResults/translations'
import { breadcrumb } from './breadcrumb'
import { buttons } from './buttons'
import { companiesNames } from './companiesNames'
import { formsLabels } from './formsLabels'
import { paramsUrl } from './paramsUrl'

const globals = _.merge(
  activity,
  articles,
  blocParentsLife,
  breadcrumb,
  buttons,
  coachingParentsZens,
  coachingParentsZensPlus,
  companiesNames,
  conferencesWorkshops,
  conferenceInscription,
  contact,
  filter,
  footer,
  formsLabels,
  gazouyi,
  header,
  homePage,
  inspirationalArticles,
  legalInformation,
  login,
  myJourney,
  myProfile,
  notAllowed,
  nurseryPlace,
  ourSelectedPartners,
  paramsUrl,
  parentsLife,
  register,
  replay,
  returnMaternityPaternityLeave,
  searchResults
)

export { globals }
