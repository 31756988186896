import PropTypes from 'prop-types'
import React, { useState } from 'react'
import FourSmall from './FourSmall/FourSmall'
import TwoSmall from './TwoSmall/TwoSmall'

function GroupCards (props) {
  const [isFourSmall] = useState(props.isFourSmall || false)

  const displayAllCards = () => {
    if (isFourSmall) {
      return <FourSmall
        {...props}
      />
    }

    return <TwoSmall {...props}/>

  }

  return displayAllCards()
}

GroupCards.propTypes = {
  allTexts1: PropTypes.object,
  allTexts2: PropTypes.object,
  allTexts3: PropTypes.object,
  allTexts4: PropTypes.object,
  imgBackground1: PropTypes.string,
  imgBackground2: PropTypes.string,
  imgBackground3: PropTypes.string,
  imgBackground4: PropTypes.string,
  isFourSmall: PropTypes.bool,
  isRoute: PropTypes.bool,
  redirect: PropTypes.func,
  category: PropTypes.string
}

export default GroupCards
