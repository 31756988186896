import $ from 'jquery'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { v4 as uuidv4 } from 'uuid'
import useMpecSecure from '../../../../../Tools/secure'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import useCompaniesTools from '../../../../Hooks/useCompaniesTools'
import useEventsTools from '../../../../Hooks/useEventsTools'
import useMailerTools from '../../../../Hooks/useMailerTools'
import useMpecServices from '../../../../Hooks/useMpecServices'

function GreyBlock (props) {
  moment.locale('fr')
  const { contactInformations, contactSalesInformation, getContactInformation, getContactSalesInformation } = useCompaniesTools()
  const { createEventRegistration, isEventFull, isUserAlreadySubscribe } = useEventsTools()
  const { currentEnv } = useMpecServices()
  const { getEmailData, handleEmailTo, sendEmailForEvent } = useMailerTools()
  const { getStorage, getUserData } = useMpecSecure()
  const { getTextButton, getTextForPage } = useMpecTranslations('conferenceInscription')
  const [conferences] = useState(props.conferences)
  const [isConditionsTrue, setIsConditionsTrue] = useState(false)
  const [isDisplayError, setIsDisplayError] = useState(false)
  const [idInProgress] = useState(props.idInProgress)
  const [isValidate, setIsValidate] = useState(false)
  const [idWaitingList] = useState(props.idWaitingList)
  const [isWorkshop] = useState(props.isWorkshop || false)
  const [textParticipate] = useState(props.isWorkshop ? _.upperFirst(getTextForPage('workshopInscription.participate')) : getTextForPage('text5'))
  const [workshop] = useState(props.isWorkshop ? 'workshop' : '')
  const sessionId = getStorage().getItem('sessionId')

  useEffect(() => {
    getContactInformation(currentEnv).then()
    getContactSalesInformation(currentEnv).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnv])

  const allDate = () => {
    if (isWorkshop && conferences.isVariousParts) {
      return (
        <div className={'d-flex justify-content-between mt-5'}>
          <div>
            <p className={'blue200 font-weight-bold mb-0'}>{_.upperFirst(getTextForPage('greyBlock.firstPart'))}</p>
            <p className={'mb-0'}>{conferences.roomDesc}</p>
            {!conferences.isRemote ?
              <div>
                <p className={'mb-0'}>{conferences.location}</p>
                <p className={'mb-0'}>{conferences.room}</p>
              </div>
              : null}
          </div>
          <p className={'lineVertical mb-0'}></p>
          <div>
            <p className={'blue200 font-weight-bold mb-0'}>{_.upperFirst(getTextForPage('greyBlock.secondPart'))}</p>
            <p className={'mb-0'}>{conferences.roomDesc2}</p>
            {!conferences.isRemote ?
              <div>
                <p className={'mb-0'}>{conferences.location2}</p>
                <p className={'mb-0'}>{conferences.room2}</p>
              </div>
              : null}
          </div>
        </div>
      )
    }
  }

  const buttonClass = () => {
    return isWorkshop ? 'btnBlueDarkWithoutBorder' : 'btnGreen'
  }

  const closeRegistration = () => {
    let closeRegistrationDate = conferences.date
    if (!_.isNull(conferences.closeRegistrationDate1)) {
      closeRegistrationDate = conferences.closeRegistrationDate1.format('DD MMMM Y')
    }
    let part = 'greyBlock.closeRegistration'
    if (!isEventOpen()) {
      part = 'greyBlock.closeRegistrationFuture'
    }

    return <p className={'mb-0'}>{getTextForPage(part, { closeRegistrationDate: closeRegistrationDate })}</p>
  }

  const displayButton = () => {
    if (!isValidate) {
      if (!isEventOpen()) {
        return <Button
          classSup={'pr-5 pl-5 ' + buttonClass()}
          classSupDiv={'mr-auto ml-auto'}
          title={_.toUpper(getTextButton('activeInscription', { date: conferences.inscriptionDate.format('DD MMMM Y') }))}
        />
      }

      if (isInscriptionEnd()) {
        return null
      }

      return <Button
        buttonFunction={getOnclick.bind(this)}
        isButtonWithAction={true}
        classSup={'pr-5 pl-5 ' + buttonClass()}
        classSupDiv={'mr-auto ml-auto'}
        title={_.toUpper(getTextButton('inscription'))}
      />
    }
  }

  const displayTexts = () => {
    if (!conferences.isVariousParts) {
      return _.map(texts, text => {
        return <p key={uuidv4()} className={text.className}>{getTextForPage(text.text, text.variable)}</p>
      })
    }
  }

  const displayTitle = () => {
    let title = conferences.date
    if (isWorkshop && conferences.isVariousParts) {
      title = _.upperFirst(getTextForPage('greyBlock.titleWorkshop'))
    }

    return <h2 className={'subtitleOther mb-4 ' + workshop}>{title}</h2>
  }

  const getObject = (text, className, variable = {}) => {
    return { text, className, variable }
  }
  const texts = [
    getObject(conferences.time, 'text-center mb-0'),
    conferences.room ? getObject(conferences.room + ' - ' + conferences.location, 'text-center') : ''
  ]

  const confirmMessage = () => {
    if (isValidate) {
      if (isUserAlreadySubscribe(conferences)) {
        return <p className={'font-italic text-center waitingList'}>
          {getTextForPage('greyBlock.userAlreadySubscribe')}
        </p>
      }

      if (isEventFull(conferences)) {
        return <p className={'font-italic text-center waitingList'}>
          {getTextForPage('greyBlock.eventFull')}
        </p>
      }

      return <p className={'font-italic text-center confirmMessage'}>
        {ReactHtmlParser(getTextForPage('greyBlock.DDOMessage', { mail: process.env.REACT_APP_CONTACT_MAIL }))}
      </p>
    }
  }

  const isEventOpen = () => {
    const date = conferences.inscriptionDate
    const now = moment()

    return date <= now
  }

  const isInscriptionEnd = () => {
    if (!_.isNull(conferences.closeRegistrationDate1)) {
      const closeRegistrationDate = conferences.closeRegistrationDate1
      const now = moment()

      return now > closeRegistrationDate
    }

    return false
  }

  const displayCheckbox = () => {
    if (isEventOpen() && !isInscriptionEnd()) {
      return <div className="custom-control custom-checkbox mb-3 mt-3">
        <input
          type="checkbox" className="custom-control-input mr-3"
          id="inscriptionConference" value="inscriptionConference"
          onChange={(event) => {
            handleChangeConditions(event)
          }}
        />
        <label className="custom-control-label pl-4 inscriptionConference"
               htmlFor="inscriptionConference">
          {physicalConditions()}
        </label>
      </div>
    }
    return ''
  }

  const displayBlockMessage = () => {
    if (isValidate) {
      return confirmMessage()
    } else {
      return <Fragment>
        {closeRegistration()}
        {limitedPlace()}
        {displayCheckbox()}
      </Fragment>
    }
  }

  const displayErrorMessage = () => {
    if (isDisplayError) {
      $('.inscriptionConference').addClass('error')
      return <p className={'error warning font-italic text-center'}>
        {getTextForPage('greyBlock.errorMessage')}
      </p>
    } else {
      $('.inscriptionConference').removeClass('error')
    }
  }

  const getOnclick = async () => {
    if (isConditionsTrue && !isUserAlreadySubscribe(conferences)) {
      setIsValidate(true)
      setIsDisplayError(false)
      if (isEventFull(conferences)) {
        await registerInEvent(idWaitingList)
        await sendEmailForEventWaitingList()
      } else {
        await registerInEvent(idInProgress, true)
        await sendEmailForEventSuccess()
      }
    } else if (isConditionsTrue && isUserAlreadySubscribe(conferences)) {
      setIsValidate(true)
      setIsDisplayError(false)
    } else {
      setIsValidate(false)
      setIsDisplayError(true)
    }
  }

  const handleChangeConditions = (event) => {
    const myClickField = $(event.target)
    if (myClickField.is(':checked')) {
      setIsConditionsTrue(true)
      $('.inscriptionConference').removeClass('error')
    } else {
      setIsConditionsTrue(false)
    }
  }

  const limitedPlace = () => {
    if (conferences.limitOfParticipants > 0) {
      return <p className={'font-italic font-weight-bold'}>
        {getTextForPage('greyBlock.limitedPersons', { limitedPersons: conferences.limitOfParticipants })}
      </p>
    }
  }

  const physicalRemote = () => {
    return conferences.isRemote ? _.upperFirst(getTextForPage('distance')) : _.upperFirst(getTextForPage('physical'))
  }

  const physicalConditions = () => {
    return conferences.isRemote ? _.upperFirst(getTextForPage('remoteConditions')) : _.upperFirst(getTextForPage(('physicalConditions')))
  }

  const registerInEvent = async (idStatus, checkIfIsFull = false) => {
    if (conferences) {
      const placesRemaining = conferences.remainingPlaces
      const isLastPlaceRemaining = placesRemaining === 1

      const data = {
        idStatus: idStatus,
        idEventSchedule: conferences?.idEventSchedule,
        idUser: conferences?.idUser,
        isActive: true
      }
      await createEventRegistration(data, sessionId)

      if (conferences.isVariousParts) {
        const data2 = { ...data }
        data2['idEventSchedule'] = conferences?.idEventSchedule2
        await createEventRegistration(data2, sessionId)
      }

      if (checkIfIsFull) {
        if (isLastPlaceRemaining) {
          await sendEmailForEventFullDDO()
        }
      }
    }
  }

  const sendEmailForEventFullDDO = async () => {
    const userData = getUserData()
    const variables = {
      'conférenceatelier': _.upperFirst(getTextForPage('greyBlock.workshop')),
      'Nomévènement': conferences.title,
      'Nomclient': getUserData().companyName
    }

    const ddoAndSalesContact = _.concat(contactInformations, contactSalesInformation)

    const emailData = getEmailData(3194139, userData, variables, _.upperFirst(getTextForPage('greyBlock.DDOSubject', { customerName: getUserData().companyName })), handleEmailTo(ddoAndSalesContact))
    await sendEmailForEvent(emailData)
  }

  const sendEmailForEventSuccess = async () => {
    const userData = getUserData()
    const variables = {
      'HPZprenom': userData.firstname,
      'NomEvenement': conferences.title,
      'dateEvenement': conferences.date,
      'DescriptionParticipation': conferences.isRemote ? _.upperFirst(getTextForPage('greyBlock.remote')) : _.upperFirst(getTextForPage('greyBlock.physical'))
    }
    const emailData = getEmailData(3187738, userData, variables, _.upperFirst(getTextForPage('greyBlock.confirmedRegister')), getUserData('defaultEmail'))
    await sendEmailForEvent(emailData)
  }

  const sendEmailForEventWaitingList = async () => {
    const userData = getUserData()
    const variables = {
      'HPZprenom': userData.firstname,
      'Nomevenement': conferences.title,
      'dateevenement': conferences.date
    }
    const emailData = getEmailData(3189629, userData, variables, _.upperFirst(getTextForPage('greyBlock.waitingList')), getUserData('defaultEmail'))
    await sendEmailForEvent(emailData)
  }

  return (
    <div className={'col-lg-5 greyBlock mb-5'}>
      {displayTitle()}
      {displayTexts()}
      <div className={'mb-4'}>
        {allDate()}
      </div>
      <p className={'text-center'}>{textParticipate} {physicalRemote()}</p>
      <div>
        {displayBlockMessage()}
      </div>
      {displayErrorMessage()}
      {displayButton()}
    </div>
  )
}

GreyBlock.propTypes = {
  conferences: PropTypes.object,
  isWorkshop: PropTypes.bool,
  textParticipate: PropTypes.string
}

export default GreyBlock
