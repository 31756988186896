import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useLocation } from 'react-router-dom'
import useMpecSecure from '../../../../../../Tools/secure'
import useMpecTranslations from '../../../../../../Tools/translations'
import Button from '../../../../../Common/Button/Button'
import H2 from '../../../../../Common/HX/H2'
import useCodeTools from '../../../../../Hooks/useCodeTools'
import useCompaniesTools from '../../../../../Hooks/useCompaniesTools'
import useEventsTools from '../../../../../Hooks/useEventsTools'
import useMailerTools from '../../../../../Hooks/useMailerTools'
import useMpecServices from '../../../../../Hooks/useMpecServices'
import useParamsTools from '../../../../../Hooks/useParamsTools'

function FirstBlock (props) {
  const { codeLoad, createCodeLink, getCodeAvailableForService, getCodeNameForCompanyAndService, getMyCode } = useCodeTools()
  const {
    contactInformations,
    contactSalesInformation,
    getContactInformation,
    getContactSalesInformation
  } = useCompaniesTools()
  const {
    createEventRegistration,
    fetchEventsInfoFromApi,
    getEventByName,
    isEventFull,
    isUserAlreadySubscribe
  } = useEventsTools()
  const {
    getEmailData,
    handleEmailTo,
    sendEmailForEvent
  } = useMailerTools()
  const { getStorage, getUserData } = useMpecSecure()
  const {
    currentEnv,
    getCurrentServiceName,
    getListOfServices,
    myServices,
    servicesLoad
  } = useMpecServices()
  const { getTextCompaniesNames } = useMpecTranslations('complementaryGuard')
  const { fetchParamsData, getIdTypeParamByTypeAndName, params } = useParamsTools()
  const [actualService, setActualService] = useState('')
  const [buttonObtainCodeIsClicked, setButtonObtainCodeIsClicked] = useState(false)
  const [checkboxIsClicked, setCheckboxIsClicked] = useState(false)
  const [code, setCode] = useState(props.pageData.ceraPageInfos.code || '')
  const [displayCode, setDisplayCode] = useState(false)
  const [error, setError] = useState(false)
  const [isCera] = useState(props.isCera || false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageData] = useState(props.pageData)
  const [textToDisplay, setTextToDisplay] = useState('')
  const [textVisible] = useState('d-none' || 'd-block')
  const [warning, setWarning] = useState('')
  const location = useLocation().pathname
  const sessionId = getStorage().getItem('sessionId')

  useEffect(() => {
    getListOfServices().then((services) => {
      const service = getCurrentServiceName(services, location)
      setActualService(service)
    }).catch((reason) => {
      console.log({ reason })
    })
    fetchParamsData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!_.isEmpty(actualService)) {
      getMyCode(sessionId).then((res) => {
        const resultCode = getCodeNameForCompanyAndService(getUserData().idCompany, actualService._id, res)
        setCode(resultCode)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualService])


  useEffect(() => {
    getContactInformation(currentEnv).then()
    getContactSalesInformation(currentEnv).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnv])

  useEffect(() => {
    if (params) {
      prepareEventBabysitor()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const checkIfWithCode = () => {
    // @todo : Faire une route permettant de savoir si le service est code ou non : Rustine pour le moment
    const envWithCode = ['MAZARS', 'MAZARS SA (Entité Bordeaux)', 'Eiffage Construction Rhône Loire', 'URBASOLAR']
    return _.indexOf(envWithCode, currentEnv) > -1
  }

  const checkIfHasToCreateEventRegistration = () => {
    const event = getEventByName('babysitor')
    if (isUserAlreadySubscribe(event)) {
      const sentenceWithCode = pageData.ceraPageInfos.alreadyParticipate.replace('{{ code }}', code)
      setTextToDisplay(sentenceWithCode)
      setDisplayCode(false)
      setWarning('warning')
      return false
    }

    if (isEventFull(event)) {
      setTextToDisplay(pageData.ceraPageInfos.eventFull)
      setDisplayCode(false)
      setWarning('warning')
      return false
    }

    return true
  }

  const clickObtainCode = async () => {
    const checkboxValue = document.getElementById('checkbox').checked
    if (checkboxValue) {
      if (checkIfHasToCreateEventRegistration()) {
        await createEventRegistrationForBabysitor()
        setDisplayCode(true)
        setTextToDisplay(pageData.ceraPageInfos.codeMessage)
      }
      setButtonObtainCodeIsClicked(true)
    } else {
      setError(true)
      setCheckboxIsClicked(true)
    }
  }

  const createEventRegistrationForBabysitor = async () => {
    setIsLoading(true)
    const event = getEventByName('babysitor')
    if (event) {
      const placesRemaining = event?.remainingPlaces
      const isLastPlaceRemaining = placesRemaining === 1

      const data = {
        idStatus: event?.idInscriptionInProgress,
        idEventSchedule: event?.idEventSchedule,
        idUser: event?.idUser,
        isActive: true
      }

      const idCompany = getUserData().idCompany
      await createEventRegistration(data, sessionId)

      if (isCera) {
        const dataCode = {
          idCompany,
          'idService': getCurrentServiceName(myServices, location)._id,
          'code': 'CERA'
        }
        await createCodeLink(dataCode, sessionId)
        setCode(pageData.ceraPageInfos.code)
      } else {
        const dataForAvailableCode = {
          idCompany,
          idService: getCurrentServiceName(myServices, location)._id
        }
        const resultCode = await getCodeAvailableForService(dataForAvailableCode, sessionId)
        setCode(resultCode._code)
      }

      setIsLoading(false)

      if (isLastPlaceRemaining) {
        try {
          await sendEmailForLastPlaceRemaining()
        } catch (error) {
          console.log({ error })
        }
      }
    }
  }

  const displayButtonOrCode = () => {
    if (buttonObtainCodeIsClicked) {
      return (
        <div className={'mt-4 mb-4 greenText'}>
          {displayCode ?
            <p className="mb-0 text-center font-weight-bold">{_.toUpper(code)}</p>
            : ''
          }
          <p className={'font-italic text-center font-weight-bold ' + warning}>{_.upperFirst(textToDisplay)}</p>
        </div>
      )
    }

    return( <Fragment>
        {(codeLoad && servicesLoad) ? <Button
          buttonFunction={clickObtainCode.bind(this)}
          className={'btn btnGreenLight pr-5 pl-5 mb-4 mt-4'}
          disabled={checkboxIsClicked}
          isButtonWithAction={true}
          isLoading={isLoading}
          title={_.upperFirst(pageData.ceraPageInfos.button)}
        /> : <div className="text-center mb-4 mt-4">
          <FontAwesomeIcon icon={faSpinner} className={'fa-pulse'}/>
        </div>}
    </Fragment>
  )
  }

  const displayLastPart = () => {
    const userName = ' ' + _.upperFirst(getUserData('lastname')) + ' ' + getUserData('firstname')
    const userData = getUserData()
    const companyName = _.upperFirst(getTextCompaniesNames(userData.companyName))
    if (isCera || checkIfWithCode()) {
      return (
        <Fragment>
          {ReactHtmlParser(pageData.ceraPageInfos.info1)}
          <div>
            <input id={'checkbox'} type={'checkbox'} onClick={removeDisabled}/>
            <span className={`${error ? 'inputTextError' : ''}`}>{ReactHtmlParser(_.replace(userName + pageData.ceraPageInfos.checkbox, '{{nomduclient}}', companyName))}</span>
          </div>
          <div className={'center row d-flex flex-row justify-content-around'}>
            {displayButtonOrCode()}
          </div>
          {ReactHtmlParser(pageData.ceraPageInfos.info2)}
        </Fragment>
      )
    }

    return ReactHtmlParser(pageData.thirdBlock)
  }

  const getEmailDataForLastPlace = (templateId, userData) => {
    const companyName = _.upperFirst(getTextCompaniesNames(userData.companyName))
    const variables = {
      'Nomclient': companyName,
      'Nomservice': _.upperFirst(pageData.title)
    }
    const str = pageData.ceraPageInfos.eventFullMail
    const subject = str.replace('{{nomduclient}}', companyName)
    const ddoAndSalesContact = _.concat(contactInformations, contactSalesInformation)
    return getEmailData(templateId, userData, variables, subject, handleEmailTo(ddoAndSalesContact))
  }

  const prepareEventBabysitor = () => {
    const idBabysitor = parseInt(getIdTypeParamByTypeAndName('_eventsType', 'babysitor'))
    if (idBabysitor) {
      fetchEventsInfoFromApi(getUserData().idCompany, sessionId, idBabysitor, true, false).then()
    }
  }

  const removeDisabled = () => {
    if (checkboxIsClicked) {
      setCheckboxIsClicked(false)
    }
  }

  const sendEmailForLastPlaceRemaining = async () => {
    const emailData = getEmailDataForLastPlace(3194170, getUserData())
    await sendEmailForEvent(emailData)
  }

  return (
    <div className="tab-pane fade show active" id="nav-punctualNeed" role="tabpanel"
         aria-labelledby="nav-infos-tab">
      <p className={`${error ? 'inputTextError textVisible' : ''}` + textVisible}>{ReactHtmlParser(pageData.errorMessage)}</p>
      {ReactHtmlParser(pageData.firstBlock)}
      <div className={'col-lg-12 d-flex row'}>
        <div className="col-lg-6">
          <H2 className={'subtitleOther text-left'} text={pageData.title1}/>
          <div>{ReactHtmlParser(pageData.secondBlock)}</div>
        </div>
        <div className="col-lg-2 ml-auto divImgLogo">
          <img alt={pageData.img} src={process.env.REACT_APP_LINK_IMG + pageData.img}/>
        </div>
      </div>
      <H2 className={'subtitleOther text-left'} text={pageData.title2}/>
      {displayLastPart()}
    </div>
  )
}

FirstBlock.propTypes = {
  isCera: PropTypes.bool,
  pageData: PropTypes.object
}

export default FirstBlock
