import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../Tools/translations'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import SubMenu from '../../Common/SubMenu/SubMenu'
import TitlePage from '../../Common/TitlePage/TitlePage'
import './OurSelectedPartners.scss'
import PageSelected from './partials/PageSelected'

function OurSelectedPartners () {
  const { getTextForPage } = useMpecTranslations('ourSelectedPartners')
  const [selectedPartners, setSelectedPartners] = useState([])

  useEffect(() => {
    fetchDataSelectedPartners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayAllPages = () => {
    if (!_.isEmpty(selectedPartners)) {
      return _.map(selectedPartners, (element, index) => {
        if (element.page === 'gazouyi') {
          element.active = 'active'
        }

        return <PageSelected key={uuidv4()} {...element} />
      })
    }
  }

  const fetchDataSelectedPartners = async () => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/selectedPartners/get-data', null, {
        cancelToken: source.token
      })

      setSelectedPartners(result.data)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getLinksObject = (title, id, href, ariaControls, col = false) => {
    return { title, id, href, ariaControls, col }
  }

  const links = [
    getLinksObject(_.upperFirst(getTextForPage('gazouyi')), 'nav-gazouyi-tab', '#nav-gazouyi', 'nav-gazouyi'),
    getLinksObject(_.upperFirst(getTextForPage('maxicours')), 'nav-maxicours-tab', '#nav-maxicours', 'nav-maxicours'),
    getLinksObject(_.upperFirst(getTextForPage('myFamiliz')), 'nav-myFamiliz-tab', '#nav-myFamiliz', 'nav-myFamiliz'),
    getLinksObject(_.upperFirst(getTextForPage('babysittor')), 'nav-babysittor-tab', '#nav-babysittor', 'nav-babysittor'),
    getLinksObject(_.upperFirst(getTextForPage('workshopFutursParent')), 'nav-workshopFutursParent-tab', '#nav-workshopFutursParent', 'nav-workshopFutursParent', '')
  ]

  return (
    <div className="OurSelectedPartners content-wrapper">
      <HeaderPage imgBannerBackground={'banniere-nos-partenaires-selectionnes'}/>
      <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
      <div className="tab-content container mt-5" id="nav-tabContent">
        <SubMenu links={links} col={''} colDiv={'col-12 '}/>
        {displayAllPages()}
      </div>
    </div>
  )
}

export default OurSelectedPartners
