import PropTypes from 'prop-types'
import React, { useState } from 'react'

function Text (props) {
  const [content] = useState(props.content)
  const {className} = useState(props.className)

  return (
    <div className={className}>
      {content}
    </div>
  )
}

Text.propTypes = {
  content: PropTypes.any,
  className: PropTypes.any
}

export default Text
