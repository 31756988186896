import Axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import useMpecTranslations from '../../../Tools/translations'
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb'
import Button from '../../Common/Button/Button'
import Like from '../../Common/Like/Like'
import TitlePage from '../../Common/TitlePage/TitlePage'
import usePagesTools from '../../Hooks/usePagesTools'
import './Activity.scss'
import { useLocation } from 'react-router-dom';

function Activity (props) {
  const {
    getRouteFor,
    getTextButton,
    getTextForBreadCrumb,
    getTextForPage
  } = useMpecTranslations('activity')
  const { displayBlocks } = usePagesTools()
  const { category, title } = useParams()
  const [blocks, setBlocks] = useState(false)
  const [titleHeader, setTitleHeader] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const source = Axios.CancelToken.source()
  const location = useLocation();

  const links = [
    {
      link: '/',
      title: getTextForPage('home'),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: getRouteFor('parentsLife', {}, true),
      title: getTextForPage('titleParentsLife'),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: getRouteFor('parentsLifeCategory', { category: category }, true),
      title: getTextForBreadCrumb(category),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: getRouteFor('parentsLifeCategory', { category: category }, true).concat('#nav-activity'),
      title: getTextForPage('activity'),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: '#',
      title: titleHeader,
      icon: '',
      className: null,
      isActive: false,
      isLast: true
    }
  ]
  const fetchActivities = async () => {
    try {
      const result = await Axios.post('/api/activities/get-data', { title, category }, {
        cancelToken: source.token
      })
      const articleData = result.data
      setTitleHeader(_.get(articleData, 'header.title', ''))
      setIsLoading(false)
      setBlocks(_.get(articleData, 'blocks', []))

    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  useEffect(() => {
    setIsLoading(true)
    fetchActivities()
    return () => {
      source.cancel('cancel')
    }
  }, [title]) // eslint-disable-line react-hooks/exhaustive-deps

  const print = (event) => {
    window.print()
  }

  if (!isLoading) {
    return (
      <div className="pageActivity content-wrapper">
        <div className="content">
          <BreadCrumb links={links} className={'mt-4 mb-4 pl-4'}/>
          <div className="container">
            <div>
              <TitlePage title={titleHeader}/>
              <div className={'d-flex justify-content-end mb-5'}>
                <Like type={'activities'} page={location.pathname}/>
              </div>
            </div>
            {displayBlocks(blocks)}
          </div>
          <div className={'center row d-flex flex-row justify-content-around'}>
            <Button isButtonWithAction={true} buttonFunction={print.bind(this)} classSup={'btnBleuAzure400'} title={_.toUpper(getTextButton('print'))}/>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>loading</div>
    )
  }
}

Activity.propTypes = {
  img: PropTypes.any,
  content: PropTypes.any,
  className: PropTypes.any
}

export default Activity
