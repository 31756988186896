import _ from 'lodash'
import { globals } from './Translations/globals'
import { routes } from './Translations/routes'

const resources = _.merge(
  routes,
  globals
)

export { resources }
