import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { toast } from 'react-toastify'
import useMpecSecure from '../../../../Tools/secure'
import useMpecTranslations from '../../../../Tools/translations'
import Button from '../../../Common/Button/Button'

function FormLogin ({ setToken }) {
  const { handleChangePassword, handleChangeUserName, login, password, userName } = useMpecSecure()
  const { getTextButton, getTextForPage } = useMpecTranslations('login')

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await login()
    if (_.has(result, 'hasError')) {
      toast.error(_.upperFirst(getTextForPage('error.'.concat(result.textToDisplay))), {
        autoClose: false,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-center',
        progress: undefined,
        theme: 'colored'
      })
    } else {
      // display welcome back
      toast.success(
        _.upperFirst(getTextForPage('success.welcomeBack', { firstname: result.user.firstname })),
        {
          onClose: props => setToken(result),
          icon: <img width={20} height={20} src='https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/logos/validLpz.png'/>
        }
      )
    }
  }

  return (
    <form className={'col-12 col-sm-6 d-flex align-items-start flex-column mx-auto'} onSubmit={handleSubmit}>
      <div className="form-group col-12">
        <label htmlFor="username-login">{_.upperFirst(getTextForPage('formLogin.userNameLabel'))}</label>
        <input
          className="form-control form-control-sm rounded"
          id={'username-login'}
          name={'username'}
          onChange={handleChangeUserName}
          required
          value={userName}
        />
      </div>
      <div className="form-group col-12">
        <label htmlFor="username-password">{_.upperFirst(getTextForPage('formLogin.userPasswordLabel'))}</label>
        <input
          autoComplete={'false'}
          className="form-control form-control-sm rounded"
          id={'username-password'}
          name={'password'}
          onChange={handleChangePassword}
          required
          type={'password'}
          value={password}
        />
      </div>
      <div className={'center col d-flex flex-row justify-content-center'}>
        <Button
          className={'btn sizeLoginSpecific btnJade'}
          isButtonWithAction={true}
          title={_.toUpper(getTextButton('login'))}
          type={'submit'}
        />
      </div>
    </form>
  )
}

FormLogin.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default FormLogin
