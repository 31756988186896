import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'

const useLikeTools = () => {
  const [likes, setLikes] = useState([])

  const fetchLikesByIdUser = async (idUser) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post(
        '/api/like/get-data', { idUser },
        {
          cancelToken: source.token
        }
      )
      setLikes(result.data)
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const hasLike = (likes, type) => {
    if (_.has(likes, type)) {
      return !_.isEmpty(likes[type])
    }

    return false
  }

  const isPageLike = (likes, page, type) => {
    if (_.has(likes, type)) {
      return likes[type].includes(page)
    }

    return false
  }

  const sendLike = async (isLike, idUser, type, page) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post(
        '/api/like/update',
        {
          idUser,
          type,
          page,
          isLike
        },
        {
          cancelToken: source.token
        }
      )
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  return {
    fetchLikesByIdUser,
    hasLike,
    isPageLike,
    likes,
    sendLike
  }
}

export default useLikeTools