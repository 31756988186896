import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Card from '../../../../../../../Common/Card/Card'

function TwoSmall (props) {
  const [allTexts1] = useState(props.allTexts1)
  const [allTexts2] = useState(props.allTexts2)
  const [allTexts3] = useState(props.allTexts3)
  const [allTexts4] = useState(props.allTexts4)
  const [category] = useState(props.category)
  const [imgBackground1] = useState(props.imgBackground1)
  const [imgBackground2] = useState(props.imgBackground2)
  const [imgBackground3] = useState(props.imgBackground3)
  const [imgBackground4] = useState(props.imgBackground4)
  const [isRoute] = useState(props.isRoute)
  const redirect = props.redirect


  const renderCard = (number) => {
    switch (number) {
      case 1 :
        if (allTexts1 && imgBackground1) {
          return <Card
            key={uuidv4()}
            allTexts={allTexts1}
            imgBackground={imgBackground1}
            className={'col-xs-12 col-sm-4'}
            isRoute={isRoute}
            redirect={redirect.bind(this)}
            routeParam={{ title: _.kebabCase(allTexts1.text), category }}
            route={'articles'}
            fromRoute={true}
          />
        }
        break
      case 2 :
        if (allTexts2 && imgBackground2) {
          return <Card
            key={uuidv4()}
            allTexts={allTexts2}
            imgBackground={imgBackground2}
            classSup='small-card'
            isRoute={isRoute}
            redirect={redirect.bind(this)}
            routeParam={{ title: _.kebabCase(allTexts2.text), category }}
            route={'articles'}
            fromRoute={true}
          />
        }
        break
      case 3 :
        if (allTexts3 && imgBackground3) {
          return <Card
            key={uuidv4()}
            allTexts={allTexts3}
            imgBackground={imgBackground3}
            classSup='small-card'
            isRoute={isRoute}
            redirect={redirect.bind(this)}
            routeParam={{ title: _.kebabCase(allTexts3.text), category }}
            route={'articles'}
            fromRoute={true}
          />
        }
        break
      case 4 :
        if (allTexts4 && imgBackground4) {
          return <Card
            key={uuidv4()}
            allTexts={allTexts4}
            imgBackground={imgBackground4}
            className={'col-xs-12 col-sm-4'}
            isRoute={isRoute}
            redirect={redirect.bind(this)}
            routeParam={{ title: _.kebabCase(allTexts4.text), category }}
            route={'articles'}
            fromRoute={true}
          />
        }
        break
      default:
        return ''
    }
  }

  return (
    <div className={'row'}>
      {renderCard(1)}
      <div className={'col-sm-4'}>
        {renderCard(2)}
        {renderCard(3)}
      </div>
      {renderCard(4)}
    </div>
  )
}

TwoSmall.propTypes = {
  allTexts1: PropTypes.object,
  allTexts2: PropTypes.object,
  allTexts3: PropTypes.object,
  allTexts4: PropTypes.object,
  category: PropTypes.string,
  imgBackground1: PropTypes.string,
  imgBackground2: PropTypes.string,
  imgBackground3: PropTypes.string,
  imgBackground4: PropTypes.string,
  isRoute: PropTypes.bool,
  redirect: PropTypes.func
}

export default TwoSmall
