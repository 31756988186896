import React, { useState } from 'react'
import PropTypes from 'prop-types'

function BreadCrumbLink (props) {
  const [link] = useState(props.link)
  const [title] = useState(props.title)
  const [icon] = useState(props.icon)
  const [className] = useState(props.className)
  const [isActive] = useState(props.isActive)
  const [isLast] = useState(props.isLast)
  const [separator] = useState(props.separator || '>')

  const getClassName = () => {
    let activeClass = ''
    if (isActive) {
      activeClass = 'bc-active'
    }

    return className + ' ' + activeClass
  }

  const getSeparator = () => {
    if (isLast) {
      return ''
    }

    return ' ' + separator + ' '
  }

  const getIcon = () => {
    if (icon) {
      return <i>{icon + ' '}</i>
    }
    return ''
  }

  return (
    <span>
      <a href={link} className={`w-decoration ${getClassName()}`}>
        {getIcon()}
        {title}
      </a>
      {getSeparator()}
    </span>
  )
}

BreadCrumbLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isLast: PropTypes.bool,
  separator: PropTypes.string
}

export default BreadCrumbLink
