import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../../Tools/translations'

function LegalInformationarticle10 () {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'container article'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <h3 className={'col-12'}>{getTextForPage('article10.title')}</h3>
        </div>
        <div className={'col-12 text-bold'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.whatIsACookie'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.cookieWhat'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.cookieDetails'))}</p>
        </div>
        <div className={'col-12 text-bold'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.howWeUseThem'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{ReactHtmlParser(getTextForPage('article10.howWeUseThemDetails.text'))}</p>
        </div>
        <ul className={''}>
          <li>{getTextForPage('article10.howWeUseThemDetails.li01')}</li>
          <li>{getTextForPage('article10.howWeUseThemDetails.li02')}</li>
          <li>{getTextForPage('article10.howWeUseThemDetails.li03')}</li>
        </ul>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.notMandatoryBut'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.dataNotIdentify'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.googleAnalytics'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article10.googleAnlyticsWhy'))}</p>
        </div>
      </div>
    </div>
  )
}

export default LegalInformationarticle10
