import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecSecure from '../../../Tools/secure'
import ContentPageContext from '../../Context/ContentPageContext'
import useCodeTools from '../../Hooks/useCodeTools'
import useEventsTools from '../../Hooks/useEventsTools'

function ConditionMessages (props) {
  const { getMyCode, getCodeNameForCompanyAndService } = useCodeTools()
  const { eventsInfo, code, actualService, noMoreCode } = useContext(ContentPageContext)
  const { isUserAlreadySubscribe, isEventFull } = useEventsTools()
  const { getStorage, getUserData } = useMpecSecure()
  const [category] = useState(props.category)
  const [currentCode, setCurrentCode] = useState('')
  const [messages] = useState(props.messages)
  const [visibility, setVisibility] = useState(props.visibility)
  const sessionId = getStorage().getItem('sessionId')

  useEffect(() => {
    getMyCode(sessionId).then((res) => {
      const resultCode = getCodeNameForCompanyAndService(getUserData().idCompany, actualService._id, res)
      setCurrentCode(resultCode)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setVisibility(props.visibility)
  }, [props.visibility])

  const handleMessages = () => {
    switch (category) {
      case 'event':
        return handleEventMessages()
      default:
        return false
    }
  }

  const handleEventMessages = () => {
    return <div className={'mt-4 mb-4 greenText'}>
      {
        _.map(messages, message => {
          if (isUserAlreadySubscribe(eventsInfo)) {
            return message.type === 'alreadyParticipate' ? <p className={'font-italic text-center font-weight-bold ' + message.class} key={uuidv4()}>{
              (message.text).replace('*code*', currentCode)
            }</p> : ''
          } else if (isEventFull(eventsInfo)) {
            return message.type === 'limitReached' ? <p className={'font-italic text-center font-weight-bold ' + message.class} key={uuidv4()}>{message.text}</p> : ''
          } else if (noMoreCode) {
            return message.type === 'noMoreCode' ? <p className={'font-italic text-center font-weight-bold ' + message.class} key={uuidv4()}>{message.text}</p> : ''
          } else {
            return message.type === 'succeed' ?
              <Fragment key={uuidv4()}>
                <p className="mb-0 text-center font-weight-bold">{code}</p>
                <p className={'font-italic text-center font-weight-bold ' + message.class}>{message.text}</p>
              </Fragment>
              : ''
          }
        })
      }
    </div>
  }
  return (
    visibility ? handleMessages() : ''
  )
}

ConditionMessages.propTypes = {
  category: PropTypes.string,
  messages: PropTypes.array
}

export default ConditionMessages
