import _ from 'lodash'
import React, { useState } from 'react'
import useMpecTranslations from '../../../../../../../Tools/translations'
import './VideoComponent.scss'

function VideoComponent (props) {
  const { getTextFilter } = useMpecTranslations('blocParentsLife')
  const [video] = useState(props.video)

  return (
    <div className="col-lg-4">
      <div className={'VideoComponent card'}>
        <iframe
          width="100%" height="257px"
          src={video.src}
          title={'title'}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
        </iframe>
        <div className={'text-center mt-4'}>
          <p className={'font-weight-bold'}>{_.upperFirst(video.title)}</p>
          <p>{_.upperFirst(getTextFilter(video.type))}</p>
        </div>
      </div>
    </div>
  )
}

export default VideoComponent
