import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../Tools/translations'
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb'
import Filter from '../../Common/Filter/Filter'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import H1 from '../../Common/HX/H1'
import ThreeBlockCards from '../../Common/ThreeBlockCards/ThreeBlockCards'
import UnderTitle from '../../Common/UnderTitle/UnderTitle'
import useInspirationalArticlesTools from '../../Hooks/useInspirationalArticlesTools'

function InspirationalArticles () {
  const { getTextForPage } = useMpecTranslations('inspirationalArticles')
  const { getAllFilters, fetchListOfFilter, fetchAllArticles, getArticlesOrder } = useInspirationalArticlesTools()
  const [currentFilters, setCurrentFilters] = useState([])
  const [isArticlesLoad, setIsArticlesLoad] = useState(false)

  useEffect(() => {
    fetchListOfFilter().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchAllArticles(currentFilters).then(() => {
      setIsArticlesLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters])

  const links = [
    {
      link: '/',
      title: getTextForPage('home'),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: '',
      title: getTextForPage('title'),
      icon: '',
      className: null,
      isActive: false,
      isLast: true
    }
  ]

  const addFilterOrRemove = (filter) => {
    if (currentFilters.includes(filter)) {
      const newCurrentFilters = currentFilters.filter(selectedFilter => selectedFilter !== filter)
      setCurrentFilters(newCurrentFilters)
    } else {
      setCurrentFilters([...currentFilters, filter])
    }
  }

  return (
    <div className={'InspirationalArticles content-wrapper'}>
      <HeaderPage isDisplayLogo={true}/>
      <BreadCrumb links={links} className={'mt-3 pl-3'}/>

      <div className="container">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6 m-auto">
                <H1 text={getTextForPage('title')} className={'page-subtitle mt-3 mb-3 text-nowrap'}/>
              </div>
            </div>
            <UnderTitle text={getTextForPage('underTitle')}/>
            <Filter
              addFilterOrRemove={addFilterOrRemove}
              allFilters={getAllFilters()}
            />
            <div className={'mt-5'}>
              {isArticlesLoad ? <ThreeBlockCards allDataArticles={getArticlesOrder()}/> : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InspirationalArticles
