import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useReducer, useState } from 'react'
import { useLocation } from 'react-router'
import useMpecSecure from '../../../Tools/secure'
import ContentPageContext from '../../Context/ContentPageContext'
import useEventsTools from '../../Hooks/useEventsTools'
import useMpecServices from '../../Hooks/useMpecServices'
import usePagesTools from '../../Hooks/usePagesTools'
import useParamsTools from '../../Hooks/useParamsTools'

function reducer (state, item) {
  switch (item.type) {
    case 'content':
      return [...item.data]
    case 'code':
      return item.data
    default:
      return
  }
}

function ContentPage (props) {
  const { eventInfoIsLoading, fetchEventsInfoFromApi, getEventByName } = useEventsTools()
  const { getStorage, getUserData } = useMpecSecure()
  const { getCurrentServiceName, getListOfServices } = useMpecServices()
  const { displayBlocks, spinner } = usePagesTools()
  const { getIdTypeParamByTypeAndName, fetchParamsData, params } = useParamsTools()
  const [code, setCode] = useReducer(reducer, '')
  const [content, setContent] = useReducer(reducer, [])
  const [noMoreCode, setNoMoreCode] = useReducer(reducer, false)
  const [actualService, setActualService] = useState('')
  const [pageIsLoading, setPageIsLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    fetchParamsData().then()
    getListOfServices().then((services) => {
      const path = location.pathname
      const service = getCurrentServiceName(services, path)
      setActualService(service)
    }).catch((reason) => {
      console.log({ reason })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchContentPage().then()
    if (params) {
      fetchEvent().then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, actualService])

  const fetchContentPage = async () => {
    const source = Axios.CancelToken.source()

    try {
      const result = await Axios.post('/api/contentsPages/get-data', { title: actualService._name }, {
        cancelToken: source.token
      })
      setContent({ type: 'content', data: _.get(result.data, 'blocks', []) })
      setPageIsLoading(false)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchEvent = async () => {
    switch (actualService._name) {
      case 'maxicours':
        await prepareEventBabysitor()
        break
      case 'myFamiliz':
        await prepareEventFamiliz()
        break
      default:
        return
    }
  }

  const prepareEventFamiliz = async () => {
    const sessionId = getStorage().getItem('sessionId')
    const idFamiliz = parseInt(getIdTypeParamByTypeAndName('_eventsType', 'myFamiliz'))
    if (idFamiliz) {
      await fetchEventsInfoFromApi(getUserData().idCompany, sessionId, idFamiliz)
    }
  }

  const prepareEventBabysitor = async () => {
    const sessionId = getStorage().getItem('sessionId')
    const idMaxicours = parseInt(getIdTypeParamByTypeAndName('_eventsType', 'maxicours'))
    if (idMaxicours) {
      await fetchEventsInfoFromApi(getUserData().idCompany, sessionId, idMaxicours)
    }
  }

  return (
    pageIsLoading ? spinner() :
      <ContentPageContext.Provider value={{
        actualService,
        code,
        content,
        setCode,
        setContent,
        eventsInfo: getEventByName(actualService._name),
        eventInfoIsLoading,
        noMoreCode,
        setNoMoreCode
      }}>
        <div className="contentPage content-wrapper">
          <div className="content">
            <div className="container">
              {displayBlocks(content)}
            </div>
          </div>
        </div>
      </ContentPageContext.Provider>
  )
}

export default ContentPage