import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import ContentPageContext from '../../Context/ContentPageContext'
import useButtonTools from '../../Hooks/useButtonTools'
import ConditionMessages from '../ConditionMessages/ConditionMessages'
import './Button.scss'

function ButtonContextual (props) {
  const defaultClassName = 'btn p-3 mt-5 mb-5'
  const { content, eventsInfo, eventInfoIsLoading, actualService } = useContext(ContentPageContext)
  const { handleFunction } = useButtonTools(props.typeFunctionContentPage, props.buttonState)
  const [buttonFunction, setButtonFunction] = useState(props.buttonFunction)
  const [className] = useState(props.className || defaultClassName)
  const [classSup] = useState(props.classSup || 'btnBlueLight')
  const [classDiv] = useState(props.classDiv || 'ButtonComponent center ')
  const [classSupDiv] = useState(props.classSupDiv || '')
  const [displayContentOnClick] = useState(props.displayContentOnClick)
  const [id] = useState(props.id || '')
  const [isAsyncFunction] = useState(props.isAsyncFunction)
  const [isButtonWithAction] = useState(props.isButtonWithAction || false)
  const [isLoading, setIsLoading] = useState(false)
  const [link] = useState(props.link || '#')
  const [name] = useState(props.name || '')
  const [target] = useState(props.target || '')
  const [title] = useState(props.title)
  const [type] = useState(props.type || 'button')
  const [visibility, setVisibility] = useState(_.isUndefined(props.visibility) ? true : props.visibility)

  const displayNewContent = () => {
    if (!_.isUndefined(displayContentOnClick)) {
      switch (displayContentOnClick.type) {
        case 'conditionMessages':
          return <ConditionMessages
            visibility={true}
            category={displayContentOnClick.content.category}
            messages={displayContentOnClick.content.messages}
          />
        default:
          return <p>ceci est un test </p>
      }
    }

  }

  useEffect(() => {

    if (!_.isUndefined(props.visibility)) {
      setVisibility(props.visibility)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visibility])

  useEffect(() => {
    if (_.has(props, 'isLoading')) {
      setIsLoading(props.isLoading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading])

  useEffect(() => {
    if (!_.isEmpty(props.typeFunctionContentPage) && !_.isEmpty(content)) {
      setButtonFunction(handleFunction)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.typeFunctionContentPage, content])

  useEffect(() => {
    if ((actualService._name === 'maxicours') || actualService._name === 'myFamiliz') {
      setIsLoading(eventInfoIsLoading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfoIsLoading])

  const displayButtonWithAction = () => {
    if (isLoading) {
      return <button
        className={className + ' ' + classSup}
        id={id}
        name={name}
        type={type}
        key={"my-key"}
      >
        {
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} className={'fa-pulse'}/>
          </div>
        }
      </button>
    }
    return <button
      className={className + ' ' + classSup}
      id={id}
      name={name}
      onClick={handleFunctionForButton}
      type={type}>
      {title}
    </button>
  }

  const handleFunctionForButton = () => {
    setIsLoading(true)
    if (isAsyncFunction) {
      buttonFunction(eventsInfo).then((res, err) => {
        setVisibility(!res)
      })
    } else {
      buttonFunction()
    }
  }
  return (
    visibility ?
      <div className={classDiv + classSupDiv}>
        {isButtonWithAction ? displayButtonWithAction() :
          <a href={link} target={target} className={className + ' ' + classSup}>{title}</a>
        }
      </div> : displayNewContent()
  )
}

ButtonContextual.propTypes = {
  buttonFunction: PropTypes.func,
  className: PropTypes.string,
  classSup: PropTypes.string,
  classDiv: PropTypes.string,
  classSupDiv: PropTypes.string,
  displayContentOnClick: PropTypes.object,
  isAsyncFunction: PropTypes.bool,
  isButtonWithAction: PropTypes.bool,
  isLoading: PropTypes.bool,
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  typeFunctionContentPage: PropTypes.string
}

export default ButtonContextual