const routes = {
  fr: {
    translation: {
      routes: {
        activity: 'vie-de-parents/:category/activite/:title',
        articles: 'vie-de-parents/:category/articles/:title',
        blocParentsLife: 'bloc-vie-de-parents',
        coachingParentsZens: 'vivre-ma-parentalite-en-entreprise/coaching-parents-zens',
        coachingParentsZensPlus: 'coaching-parents-zens-plus',
        complementaryGuard: 'vivre-ma-parentalite-en-entreprise/garde-complementaire-babysittor',
        complementaryGuardCera: 'vivre-ma-parentalite-en-entreprise/garde-complementaire-bbs',
        complementaryGuardO2: 'vivre-ma-parentalite-en-entreprise/garde-complementaire-o2',
        conferenceInscription: 'vivre-ma-parentalite-en-entreprise/conferences-ateliers/inscription-conférence/:variable/:id',
        conferencesWorkshops: 'vivre-ma-parentalite-en-entreprise/conferences-ateliers',
        contact: 'contactez-moi',
        gazouyi: 'vivre-ma-parentalite-en-entreprise/application-mobile-gazouyi',
        homepage: '',
        inspirationalArticles: 'articles-inspirations',
        inspirationalArticlesView: 'articles-inspirations/:title',
        legalInformation: 'mentions-legales',
        livingParenthoodCompany: 'vivre-ma-parentalite-en-entreprise',
        maxicours: 'vivre-ma-parentalite-en-entreprise/maxicours',
        nurseryPlace: 'vivre-ma-parentalite-en-entreprise/places-en-creches',
        myFamiliz: "vivre-ma-parentalite-en-entreprise/my-famyliz",
        myJourney: 'vivre-ma-parentalite-en-entreprise/mon-parcours',
        myProfile: 'mon-profil',
        ourSelectedPartners: 'vivre-ma-parentalite-en-entreprise/nos-partenaires-selectionnes',
        parentsLife: 'vie-de-parents',
        parentsLifeCategory: 'vie-de-parents/:category',
        register: 'inscription_heyparentszens',
        replayWorkshop: 'vivre-ma-parentalite-en-entreprise/conferences-ateliers/voir-replay-atelier/:title',
        replayConference: 'vivre-ma-parentalite-en-entreprise/conferences-ateliers/voir-replay-conference/:title',
        returnMaternityPaternityLeave: 'vivre-ma-parentalite-en-entreprise/retours-conges-maternite-paternite-zens',
        searchResults: 'resultat-recherche',
        workshopInscription: 'vivre-ma-parentalite-en-entreprise/conferences-ateliers/inscription-atelier/:variable/:id'
      }
    }
  }
}

export { routes }
