import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'

function ActivationActivate (props) {
  const { getTextButton, getTextForPage } = useMpecTranslations('login')
  const [message] = useState(props.message)
  const setFormToRender = props.setFormToRender
  
  return (
    <Fragment>
      <div className={'text-center'}>
        <h2>{_.upperFirst(getTextForPage('activation.titleActivateAccount'))}</h2>
      </div>
      <div className={'text-center'}>
        {_.upperFirst(getTextForPage('activation.'.concat(message.message)))}
      </div>
      <div className={'text-center'}>
        <FontAwesomeIcon icon={faThumbsUp} className={'text-green'} size={'4x'}/>
      </div>
      <div className={'text-center mt-3'}>
        <Button
          className={'btn btn-sm pl-5 pr-5'}
          buttonFunction={event => setFormToRender('login')}
          isButtonWithAction={true}
          title={_.toUpper(getTextButton('forgot.backToConnection'))}
        />
      </div>
    </Fragment>
  )
}

ActivationActivate.propTypes = {
  message: PropTypes.object,
  setFormToRender: PropTypes.func
}

export default ActivationActivate
