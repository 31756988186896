import Axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import TitlePage from '../../Common/TitlePage/TitlePage'
import useCompaniesTools from '../../Hooks/useCompaniesTools'
import useEventsTools from '../../Hooks/useEventsTools'
import useMailerTools from '../../Hooks/useMailerTools'
import useMpecServices from '../../Hooks/useMpecServices'
import usePagesTools from '../../Hooks/usePagesTools'
import AlreadyParticipate from '../ReturnMaternityPaternityLeave/partials/AlreadyParticipate/AlreadyParticipate'
import FullBlock from '../ReturnMaternityPaternityLeave/partials/FullBlock/FullBlock'
import './CoachingParentsZens.scss'
import GreyBlock from './partials/GreyBlock/GreyBlock'
import ThanksBlock from './partials/ThanksBlock/ThanksBlock'

function CoachingParentsZens () {
  const { contactInformations, contactSalesInformation, getContactInformation, getContactSalesInformation } = useCompaniesTools()
  const {
    createEventRegistration,
    fetchEventsInfoFromApi,
    getEventByName,
    isEventFull,
    isUserAlreadySubscribe,
    isUserCanSubscribe
  } = useEventsTools()
  const { getEmailData, handleEmailTo } = useMailerTools()
  const { getStorage, getUserData } = useMpecSecure()
  const { currentEnv } = useMpecServices()
  const { getTextCompaniesNames, getTextForPage } = useMpecTranslations('coachingParentsZens')
  const { fetchPageData, pageData, spinner } = usePagesTools()
  const [currentStep, setCurrentStep] = useState(1)
  const [favorite, setFavorite] = useState(null)
  const [isConditionsTrue, setIsConditionsTrue] = useState(false)
  const [isDisplayError, setIsDisplayError] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [particularSubject, setParticularSubject] = useState('')
  const sessionId = getStorage().getItem('sessionId')
  const source = Axios.CancelToken.source()

  useEffect(() => {
    fetchPageData('coachingParentsZens').then(() => {
      setIsLoad(true)
    })
    fetchEventsInfoFromApi(getUserData().idCompany, sessionId).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getContactInformation(currentEnv).then()
    getContactSalesInformation(currentEnv).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnv])

  const displayGreyBlock = () => {
    const event = getEventByName('coaching')

    if (isUserAlreadySubscribe(event)) {
      return <AlreadyParticipate/>
    }

    if (isEventFull(event)) {
      return <FullBlock/>
    }

    if (currentStep === 2) {
      return <ThanksBlock
        title={_.upperFirst(getTextForPage('thanksBlock.title'))}
      />
    }

    return <GreyBlock
      displayErrorMessage={displayErrorMessage.bind(this)}
      clicked={onClick.bind(this)}
      handleChangeSubject={handleChangeSubject.bind(this)}
      handleChangeFavorite={handleChangeFavorite.bind(this)}
      handleChangeConditions={handleChangeConditions.bind(this)}
    />
  }

  const getEmailDataForLastPlace = (templateId, userData) => {
    const variables = {
      'Nomclient': _.upperFirst(getTextCompaniesNames(userData.companyName)),
      'Nomservice': pageData.title
    }
    return getEmailData(templateId, userData, variables, getTextForPage('subjectMail2'))
  }

  const getEmailForEvent = (templateId, userData) => {
    const companyName = _.upperFirst(getTextCompaniesNames(userData.companyName))
    const emailTo = userData.defaultEmail
    const phone = userData.phone
    const variables = {
      'NomService': pageData.title,
      'Societe': companyName,
      'Nom': userData.lastname,
      'Prénom': userData.firstname,
      'téléphone': phone,
      'email': emailTo
    }

    return getEmailData(templateId, userData, variables, getTextForPage('subjectMail'), handleEmailTo(contactInformations))
  }

  const handleChangeFavorite = (event) => {
    setFavorite(event.target.id)
  }

  const displayErrorMessage = () => {
    if (isDisplayError) {
      $('.inscriptionLabel').addClass('error')
      return <p className={'error warning font-italic'}>{_.upperFirst(getTextForPage('greyBlock.warning'))}</p>
    } else {
      $('.inscriptionLabel').removeClass('error')
    }
  }

  const handleChangeConditions = (id, event) => {
    const myClickField = $(event.target)
    if (myClickField.is(':checked')) {
      setIsConditionsTrue(true)
      $('.inscriptionLabel').removeClass('error')
    } else {
      setIsConditionsTrue(false)
    }
  }

  const handleChangeSubject = (event) => {
    setParticularSubject(event.target.value)
  }

  const onClick = async (event) => {
    if (_.isNull(favorite)) {
      $('.favoriteLabel').addClass('error')
    } else {
      $('.favoriteLabel').removeClass('error')
    }

    if (!isConditionsTrue || _.isNull(favorite)) {
      setIsDisplayError(true)
    } else {
      setIsDisplayError(false)
    }

    if (isConditionsTrue && isUserCanSubscribe('coaching')) {
      await sendEmailForEvent(event)
      await registerUserToEvent()
    }
  }

  const registerUserToEvent = async () => {
    const event = getEventByName('coaching')

    if (event) {
      const placesRemaining = event?.remainingPlaces
      const isLastPlaceRemaining = placesRemaining === 1

      const data = {
        idStatus: event?.idInscriptionInProgress,
        idEventSchedule: event?.idEventSchedule,
        idUser: event?.idUser,
        moreInfos: {
          particularSubject,
          preferenceType: favorite
        },
        isActive: true
      }
      await createEventRegistration(data, sessionId)

      if (isLastPlaceRemaining) {
        await sendEmailForLastPlaceRemaining()
      }
    }
  }

  const sendEmailForEvent = async (event) => {
    event.preventDefault()
    const emailData = getEmailForEvent(3194158, getUserData())
    try {
      await Axios.post('/api/mpecMailer', emailData, {
        cancelToken: source.token
      })
      const newStep = currentStep + 1
      setCurrentStep(newStep)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const sendEmailForLastPlaceRemaining = async () => {
    const emailData = getEmailDataForLastPlace(3194170, getUserData())
    const ddoAndSalesContact = _.concat(contactInformations, contactSalesInformation)

    _.set(emailData, 'emailTo', handleEmailTo(ddoAndSalesContact))
    try {
      await Axios.post('/api/mpecMailer', emailData, {
        cancelToken: source.token
      })
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const displayAll = () => {
    return (
      <div className="container">
        <TitlePage title={pageData.title}/>
        <div className="d-flex justify-content-between content col-lg-11 row">
          <div className={'col-lg-6 mr-1 mb-5'}>
            <h2 className={'subtitleOther text-left mb-5'}>{pageData.subTitle}</h2>
            {ReactHtmlParser(pageData.pageContent)}
          </div>
          {displayGreyBlock()}
        </div>
      </div>
    )
  }

  return (
    <div className={'CoachingParentsZens content-wrapper'}>
      <HeaderPage isDisplayLogo={false} imgBannerBackground={'coachingParents'}/>
      {isLoad ? displayAll() : spinner()}
    </div>
  )
}

export default CoachingParentsZens
