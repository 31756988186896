import _ from 'lodash'
import React from 'react'
import { useParams } from 'react-router'
import useMpecTranslations from '../../../Tools/translations'
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import H1 from '../../Common/HX/H1'
import './BlocParentsLife.scss'
import FirstPanel from './partial/FirstPanel/FirstPanel'
import SecondPanel from './partial/SecondPanel/SecondPanel'
import SubMenu from './partial/SubMenu/SubMenu'
import ThirdPanel from './partial/ThirdPanel/ThirdPanel'

function BlocParentsLife () {
  const { category } = useParams() || null
  const { getRouteFor, getTextForHeader, getTextForPage } = useMpecTranslations('blocParentsLife')

  const links = [
    {
      link: '/',
      title: getTextForPage('home'),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: getRouteFor('parentsLife', {}, true),
      title: getTextForPage('titleParentsLife'),
      icon: '',
      className: null,
      isActive: false,
      isLast: false
    },
    {
      link: '',
      title: _.upperFirst(getTextForHeader(category)),
      icon: '',
      className: null,
      isActive: true,
      isLast: true
    }
  ]

  return (
    <div className="BodyParentsLife content-wrapper">
      <HeaderPage
        imgBannerBackground={'blondGirl'}
        classNameSup={'thirty'}
      />
      <BreadCrumb links={links} className={'mt-3 pl-3'}/>
      <div className="container">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 mt-5 mb-5">
                <H1 className={'m-0 text-center m-auto'} text={_.upperFirst(getTextForHeader(category))}/>
              </div>
            </div>
            <div>
              <SubMenu/>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="tab-content" id="nav-tabContent">
              <FirstPanel/>
              <SecondPanel/>
              <ThirdPanel/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlocParentsLife
