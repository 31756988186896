import React from 'react'

require('moment-duration-format')

function Bar (props) {
  const { duration, curTime, onTimeUpdate, id } = props
  const curPercentage = (curTime / duration) * 100

  function calcClickedTime (event) {
    const clickPositionInPage = event.pageX
    const bar = document.querySelector(`.bar_${id}`)
    const barStart = bar.getBoundingClientRect().left + window.scrollX
    const barWidth = bar.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = duration / barWidth
    return timePerPixel * clickPositionInBar
  }

  function handleTimeDrag (e) {
    onTimeUpdate(calcClickedTime(e))

    const updateTimeOnMove = eMove => {
      onTimeUpdate(calcClickedTime(eMove))
    }

    document.addEventListener('mousemove', updateTimeOnMove)

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  return (
    <div className="bar">
      <div
        className={`bar__progress bar_${id}`}
        style={{
          background: `linear-gradient(to right, #02378C ${curPercentage}%, #2D60EC 0)`
        }}
        onMouseDown={e => handleTimeDrag(e)}
      >
        <span
          className="bar__progress__knob"
          style={{ left: `${curPercentage - 2}%` }}
        />
      </div>
    </div>
  )
}

export default Bar
