import PropTypes from 'prop-types'
import React, { useState } from 'react'
import useDefaultClassName from './useDefaultClassName'
import './HX.scss'

function H2 (props) {
  const { defaultClassName } = useDefaultClassName()
  const [text] = useState(props.text)
  const [className] = useState(props.className || defaultClassName)
  const [classSup] = useState(props.classSup || '')

  return (
    <h2 className={className + ' ' + classSup}>{text}</h2>
  )
}

H2.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  classSup: PropTypes.string
}

export default H2
