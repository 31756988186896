import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'
import { toast } from 'react-toastify'
import toBoolean from 'validator/es/lib/toBoolean'
import useMpecSecure from '../../Tools/secure'
import useMpecTranslations from '../../Tools/translations'

function useMpecServices () {

  const convertServiceName = (name) => {
    switch (name) {
      case 'conferencesAndWorkshops':
        return 'conferencesWorkshops'
      case 'nurseryPlaces':
        return 'nurseryPlace'
      case 'ourSelectedPartners':
        return 'ourSelectedPartners'
      case 'coaching':
        return 'coachingParentsZens'
      case 'zensReturnsFromMaternityAndPaternityLeave':
        return 'returnMaternityPaternityLeave'
      case 'myFamiliz':
        return 'myFamiliz'
      case 'maxicours':
        return 'maxicours'
      case "complementaryGuardCera":
        return "complementaryGuardCera"
      default:
        return name
    }
  }

  const getHeaderNameForService = (service) => {
    switch (service) {
      case 'conferencesAndWorkshops':
        return 'conferencesAtelier'
      case 'nurseryPlaces':
        return 'placesNurseries'
      case 'complementaryGuard':
        return 'complementaryServicesBabySittor'
      case 'complementaryGuardCera':
        return 'complementaryServices'
      case 'complementaryGuardO2':
        return 'complementaryServicesO2'
      case 'coaching':
        return 'coachingParentsZen'
      case 'zensReturnsFromMaternityAndPaternityLeave':
        return 'paternityMaternity'
      case 'ourSelectedPartners':
        return 'ourSelectedPartners'
      default:
        return service
    }
  }

  const { getStorage, disconnectUser } = useMpecSecure()
  const { getTextForPage, getRouteFor } = useMpecTranslations()

  const getCurrentServiceName = (services, location) => {
    let currentServiceName = ''
    _.forEach(services, service => {
      const routeName = convertServiceName(service._name)
      const route = getRouteFor(routeName, {}, true)
      if (route === location) {
        currentServiceName = service
        return location
      }
    })

    return currentServiceName
  }

  const getListOfServices = async () => {
    const getServicesOnLoginOnly = toBoolean(process.env.REACT_APP_LINK_GET_SERVICES_ON_LOGIN_ONLY)
    let myServices = {}
    if (getServicesOnLoginOnly !== false) {
      // get from sessions data
      myServices.services = JSON.parse(localStorage.getItem('services'))
      myServices.envs = JSON.parse(localStorage.getItem('envs'))
    } else {
      // call api service
      myServices = await getMyServicesFromApi()
      if (_.has(myServices, 'hasError')) {
        toast.error(getTextForPage('error.'.concat(myServices.textToDisplay)), {
          autoClose: true,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: false,
          position: 'top-right',
          progress: undefined,
          theme: 'colored',
          onClose: props => disconnectUser()
        })
      } else {
        localStorage.setItem('services', JSON.stringify(myServices.services))
        localStorage.setItem('envs', JSON.stringify(myServices.envs))

        // set active env
        const listEnvs = myServices.envs
        setMyServices(myServices.services)
        setListEnvs(listEnvs)
        setMyCurrentEnv(listEnvs)
        setServicesLoad(true)
        return myServices.services
      }
    }

  }

  const getMyCurrentEnv = () => {
    const envs = myServices.envs = JSON.parse(localStorage.getItem('envs'))
    return envs[0]
  }

  const setMyCurrentEnv = (envs, index = null) => {
    localStorage.setItem('envs', JSON.stringify(envs))
    if (_.isNull(index)) {
      setCurrentEnv(envs[0])
    } else {
      setCurrentEnv(_.get(envs, index, ''))
    }
  }

  const getMyServicesFromApi = async () => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/auth/get-my-services', { sessionId: getStorage().getItem('sessionId') }, {
        cancelToken: source.token
      })
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const isServiceAllowed = async (serviceKey) => {
    const listServices = await getMyServicesFromApi()
    let accessAllowed = false
    _.map(listServices.services, (serviceData) => {
      const serviceName = _.get(serviceData, '_name', false)
      if (serviceName && serviceName === serviceKey) {
        accessAllowed = true
      }
    })
    return accessAllowed
  }

  const [myServices, setMyServices] = useState([])
  const [currentEnv, setCurrentEnv] = useState(getMyCurrentEnv())
  const [listEnvs, setListEnvs] = useState()
  const [servicesLoad, setServicesLoad] = useState(false)

  return {
    convertServiceName,
    currentEnv,
    getCurrentServiceName,
    getHeaderNameForService,
    getListOfServices,
    isServiceAllowed,
    listEnvs,
    myServices,
    servicesLoad
  }
}

export default useMpecServices
