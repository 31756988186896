const myJourney = {
  fr: {
    translation : {
      pages: {
        myJourney: {
          alternateWelcome: "Une application mobile d'activités simples et ludiques",
          coachParentsZen: "Coaching pour des Parents Zens",
          complementaryGuards: "Garde complémentaire",
          complementaryGuardsO2: "Garde complémentaire O2",
          complementaryGuardsCera: "Garde complémentaire - BBS",
          conferencesAndWorkshops: "Conférences et ateliers",
          maxicours: "Maxicours",
          myFamiliz: "My familiz",
          nurseryPlace: "Places en crèches",
          returnFromPaternity: "Retours de congés maternité et paternité zens",
          smallTitle: "Mon parcours",
          title: "Vivre ma parentalité au sein de mon entreprise",
          underTitle: 'En tant que collaborateur de {{company}}, je bénéficie des services suivants :'
        }
      }
    }
  }
}

export { myJourney }