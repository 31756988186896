import { useHistory } from 'react-router'
import useMpecTranslations from '../../Tools/translations'

function useRoutesTools () {
  const { getRouteFor } = useMpecTranslations('')
  const history = useHistory()

  const goToRoute = (event, route, routeParam = null, fromRoute = true) => {
    event.preventDefault()
    if (route) {
      redirect(getRouteFor(route, routeParam, fromRoute))
    }
  }

  const redirect = (route) => {
    history.push(route)
  }

  return {
    goToRoute,
    redirect
  }
}

export default useRoutesTools
