import moment from "moment"

const footer = {
  fr: {
    translation: {
      pages: {
        footer: {
          copy: 'Copyright',
          legalMentions: "mentions Légales",
          parentszens: 'Les Parents Zens',
          return: 'Revenir sur Les Parents Zens',
          right: 'Tous droits réservés.',
          withLove: {
            beforeHeart: 'fait avec ',
            afterHeart: ' par Les Parents Zens'
          },
          year: new moment().format('Y'),
        }
      }
    }
  }
}

export { footer }
