const notAllowed = {
  fr: {
    translation: {
      pages: {
        notAllowed: {
          title: 'accès non autorisé',
          insufficientRights: 'vos droits d\'accès sont insuffisants pour pouvoir accéder à ce service'
        }
      }
    }
  }
}

export { notAllowed }
