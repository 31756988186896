const replay = {
  fr: {
    translation: {
      pages: {
        replay: {
          animate: '{{category}} animée par',
          firstPart: 'première partie',
          secondPart: 'seconde partie',
          time: '{{duration}} minutes',
          title: 'voir le replay',
          waiting: 'en attente de chargement',
          workshops: 'atelier',
          conferences: 'conférence',
          workshopDate: 'atelier du {{date}}'
        }
      }
    }
  }
}

export { replay }
