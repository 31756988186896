import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useDefaultClassName from './useDefaultClassName'
import './HX.scss'

function H3 (props) {
  const { defaultClassName } = useDefaultClassName()
  const [text, setText] = useState(props.text)
  const [className] = useState(props.className || defaultClassName)

  useEffect(() => {
    if (_.has(props, 'text')) {
      setText(props.text)
    }
  }, [props])

  return (
    <h3 className={className}>{text}</h3>
  )
}

H3.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}

export default H3
