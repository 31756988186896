import _ from 'lodash'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import usePagesTools from '../../../../Hooks/usePagesTools'

function FirstPanel (props) {
  const { getTextForPage } = useMpecTranslations('conferencesWorkshops')
  const { spinner } = usePagesTools()
  const [events, setEvents] = useState(props.events)
  const [eventsLoad, setEventsLoad] = useState(props.eventsLoad)
  const getAllImgBlocks = props.getAllImgBlocks

  useEffect(() => {
    if (props.eventsLoad) {
      setEventsLoad(props.eventsLoad)
    }
    if (props.events) {
      setEvents(props.events)
    }
  }, [props])

  const getAllBlocks = () => {
    if (eventsLoad) {
      if (_.isEmpty(events)) {
        return (
          <div className="d-flex col-lg-12 row">
            <div className={'ml-auto mr-auto mb-5 emptyEvents'}>
              <p className={'mt-5'}>{_.upperFirst(getTextForPage('noEvents.noWorkshopAndConference'))}</p>
              <img src={process.env.REACT_APP_LINK_IMG + 'photo_children.jpg'} alt={'children_photo'} className="brand-image"/>
            </div>
          </div>
        )
      } else {
        return (
          <div className="d-flex col-lg-12 row justify-content-center">
            {getAllImgBlocks(false, true)}
          </div>
        )
      }
    } else {
      return spinner(_.upperFirst(getTextForPage('activation')))
    }
  }

  return (
    <div className="tab-pane fade show active" id="nav-event" role="tabpanel" aria-labelledby="nav-event-tab">
      {getAllBlocks()}
    </div>
  )
}

FirstPanel.propTypes = {
  events: PropTypes.array,
  eventsLoad: PropTypes.bool,
  getAllImgBlocks: PropTypes.func
}

export default FirstPanel
