import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import useMpecTranslations from '../../../Tools/translations'
import Button from '../../Common/Button/Button'
import Footer from '../../Common/Footer/Footer'
import H1 from '../../Common/HX/H1'
import H2 from '../../Common/HX/H2'
import Register from '../Register/Register'
import './login.scss'
import Activation from './partials/Activation'
import FormForgot from './partials/FormForgot'
import FormLogin from './partials/FormLogin'
import Header from './partials/Header'

function Login ({ setToken }) {
  const { getTextButton, getTextForPage } = useMpecTranslations('login')
  const [activate, setActivate] = useState(false)
  const [formToRender, setFormToRender] = useState('login')

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const myActivate = _.get(params, 'activate', false)
    if (myActivate) {
      setActivate(myActivate)
      setFormToRender('activation')
    }
  }, [])

  const firstConnection = () => {
    return (
      <li className={'list-group-item'}>
        <button
          className={'linkLogin'}
          onClick={event => handlerRender(event, 'register')}>
          {_.upperFirst(getTextForPage('firstConnection'))} >
        </button>
      </li>
    )
  }

  const handlerRender = (event, toRender = 'login') => {
    event.preventDefault()
    setFormToRender(toRender)
  }

  const renderChangeConfirm = () => {
    return (
      <Fragment>
        <div className="col-12 col-sm-10 m-auto">
          <H1 text={_.upperFirst(getTextForPage('changeConfirm.title'))}/>
        </div>
        <div className={'col-12 col-sm-10 m-auto text-bold text-center'}>
          <p className={'mb-0'}>{_.upperFirst(getTextForPage('changeConfirm.weWillSendEmail'))}</p>
          <p>{_.upperFirst(getTextForPage('changeConfirm.ifNotReceived'))}</p>
        </div>
        <div className={'col-12 col-sm-6 text-center mt-3'}>
          <Button
            className={'btn btn-sm pl-5 pr-5'}
            buttonFunction={event => setFormToRender('login')}
            isButtonWithAction={true}
            title={_.toUpper(getTextButton('forgot.backToConnection'))}
          />
        </div>
      </Fragment>
    )
  }

  const renderForm = () => {
    switch (formToRender) {
      case 'activation':
        return <Activation activate={activate} setFormToRender={setFormToRender}/>
      case 'forgot':
        return renderForgot()
      case 'changeConfirmation':
        return renderChangeConfirm()
      case 'register':
        return <Register/>
      case 'login':
      default:
        return renderLogin()
    }
  }

  const renderLogin = () => {
    return (
      <Fragment>
        <div className="col-12 col-sm-10 m-auto">
          <p className={'title text-center mt-5'}>{_.upperFirst(getTextForPage('title'))}</p>
          <p className={'underTitle mb-5'}>{_.upperFirst(getTextForPage('subtitle'))}</p>
        </div>
        <div className="col-12 col-sm-6 m-auto text-left">
          <H2 className={'page-subtitle centerAccount'} text={_.upperFirst(getTextForPage('iHaveMyAccount'))}/>
        </div>
        <FormLogin setToken={setToken}/>
        <div className={'col-12 col-sm-6 col-md-12 d-flex align-items-start justify-content-center mt-3'}>
          <ul className={'w-100 list-group d-flex flex-row align-items-start justify-content-center'}>
            <li className={'list-group-item'}>
              <button className={"linkLogin"} onClick={event => handlerRender(event, 'forgot')}>
                {_.upperFirst(getTextForPage('forgotPassword'))} >
              </button>
            </li>
            {firstConnection()}
          </ul>
        </div>
      </Fragment>
    )
  }

  const renderForgot = () => {
    return (
      <Fragment>
        <div className="col-12 col-sm-10 m-auto">
          <H1 className={'bigPadding title text-center mt-5 mb-5'} text={_.upperFirst(getTextForPage('forgot.title'))}/>
        </div>
        <FormForgot setRender={setFormToRender}/>
        <div className={'col-12 col-sm-6 col-md-12 d-flex align-items-start'}>
          <ul className={'w-100 list-group d-flex flex-row align-items-start justify-content-center'}>
            <li className={'list-group-item'}>
              <button onClick={event => handlerRender(event, 'login')}>
                {_.upperFirst(getTextForPage('forgot.backToConnection'))} >
              </button>
            </li>
            {firstConnection()}
          </ul>
        </div>
      </Fragment>
    )
  }

  return (
    <div className={'Login d-flex flex-column justify-content-around'}>
      <Header/>
      <div className={'LoginContainer h-100 container-fluid d-flex flex-column justify-content-center'}>
        <div className={'formContainer'}>
          <div className="formLogin mb-2 mx-auto mb-5">
            {renderForm()}
          </div>
        </div>
      </div>
      <Footer mode={'login'}/>
      <ToastContainer/>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
export default Login
