import _ from 'lodash'
import useMpecTranslations from '../../../Tools/translations'
import { useWindowDimensions } from '../../Providers/WindowDimensionsProvider'
import H1 from '../HX/H1'
import './NotAllowed.scss'

function NotAllowed () {
  const { height } = useWindowDimensions()
  const { getTextForPage } = useMpecTranslations('notAllowed')
  return (
    <div className="NotAllowed content-wrapper">
      <div className={'container'}>
        <div className={'d-flex justify-content-center align-items-center'} style={{ minHeight: height - 150 }}>
          <div>
            <H1 text={getTextForPage('title')}/>
            <p className={'w-100 text-center'}>
              {_.upperFirst(getTextForPage('insufficientRights'))}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotAllowed
