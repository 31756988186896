import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'

function AlreadyParticipate (props) {
  const { getTextForPage, getTextButton, getRouteFor } = useMpecTranslations('returnMaternityPaternityLeave')

  return (
    <div className={'col-lg-5 greyBlock mb-5'}>
      <h2 className={'page-subtitle mb-5'}>{getTextForPage('greyBlock.titleBlock')}</h2>
      <p className={'text-center mb-5'}>{getTextForPage('alreadyParticipate.text1')}</p>
      <Button
        classSup={'btnGreen pr-5 pl-5'}
        link={getRouteFor('contact', {}, true)}
        classSupDiv={'mr-auto ml-auto'}
        className={'btn p-3'}
        title={_.upperFirst(getTextButton('contactFamily'))}
      />
    </div>
  )
}

AlreadyParticipate.propTypes = {
  clicked: PropTypes.func
}

export default AlreadyParticipate
