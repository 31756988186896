import _ from 'lodash'
import { useContext } from 'react'
import useMpecSecure from '../../Tools/secure'
import ContentPageContext from '../Context/ContentPageContext'
import useCodeTools from './useCodeTools'
import useCompaniesTools from './useCompaniesTools'
import useEventsTools from './useEventsTools'
import useMailerTools from './useMailerTools'
import useMpecServices from './useMpecServices'

function useButtonTools (typeFunction = '', buttonState = {}) {
  const {
    actualService,
    content,
    setCode,
    setContent,
    setNoMoreCode
  } = useContext(ContentPageContext)
  const { currentEnv } = useMpecServices()

  const { handleEmailTo, sendEmailForLastPlaceRemaining } = useMailerTools()
  const { getCodeAvailableForService } = useCodeTools()
  const { getContactInformation, getContactSalesInformation } = useCompaniesTools()
  const { createEventRegistration, isUserAlreadySubscribe, isEventFull } = useEventsTools()
  const { getStorage, getUserData } = useMpecSecure()
  const sessionId = getStorage().getItem('sessionId')

  const canRegisterToEvent = (events) => {
    return !(isUserAlreadySubscribe(events) || isEventFull(events))
  }

  const handleFunction = () => {
    switch (typeFunction) {
      case 'handleVisibilityHiddenText':
        return handleVisibilityHiddenText
      case 'registerMaxicours':
        return registerMaxicours
      case 'registerMyFamiliz':
        return registerFamiliz
      default:
        return () => console.log('empty function')
    }
  }

  const handleVisibilityHiddenText = () => {
    const newGlobalState = _.forEach(content, (state) => {
      if (state.type === 'HiddenText' && state.content.id === buttonState.idHiddenText) {
        state.content.states.visibility = !state.content.states.visibility
      }
      return state
    })

    setContent({ type: 'content', data: newGlobalState })
  }

  const registerFamiliz = async (events) => {
    return await registerMaxicours(events, 'My Familiz')
  }

  const registerMaxicours = async (events, eventName = 'Maxicours') => {
    if (canRegisterToEvent(events)) {
      const idCompany = getUserData().idCompany
      const dataForAvailableCode = {
        idCompany,
        idService: actualService._id
      }
      const resultCode = await getCodeAvailableForService(dataForAvailableCode, sessionId)

      if (resultCode.error) {
        setNoMoreCode({ type: 'code', data: true })
        return true
      }

      setCode({ type: 'code', data: resultCode._code })

      const data = {
        idStatus: events?.idInscriptionInProgress,
        idEventSchedule: events?.idEventSchedule,
        idUser: events?.idUser,
        isActive: true
      }

      const placesRemaining = events?.remainingPlaces
      const isLastPlaceRemaining = placesRemaining === 1
      const resultRegistration = await createEventRegistration(data, sessionId)

      if (resultRegistration._status > 201) {
        setNoMoreCode({ type: 'code', data: true })
        return true
      }

      if (isLastPlaceRemaining) {
        const ddos = await getContactInformation(currentEnv)
        const sales = await getContactSalesInformation(currentEnv)
        //@Todo voir si il faut mettre un fichier de traduction juste pour cet élément ou alors le mettre dans le fichier json, à voir
        const subject = getUserData().companyName + ' a atteint sa limite de licence ' + eventName
        const ddoAndSalesContact = _.concat(ddos.data, sales.data)
        console.log({ddoAndSalesContact})
        await sendEmailForLastPlaceRemaining(getUserData(), getUserData().companyName, eventName, subject, handleEmailTo(ddoAndSalesContact))
      }
    }
    return true
  }

  return {
    handleFunction
  }
}

export default useButtonTools