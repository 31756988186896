import Axios from 'axios'
import { useState } from 'react'

function useReplayTools () {
  const [replay, setReplay] = useState([])
  const [replays, setReplays] = useState([])

  const fetchReplaysData = async (titleName, category, companyName) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/replays/get-data', { titleName, category, companyName }, {
        cancelToken: source.token
      })

      setReplay(result.data)
      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const fetchAllReplays = async (companyName) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/replays/get-all', {companyName}, {
        cancelToken: source.token
      })
      setReplays(result.data)
      return result.data
    } catch (error) {
      console.log({error})
    }
  }

  return {
    fetchAllReplays,
    fetchReplaysData,
    replay,
    replays
  }
}

export default useReplayTools
