import Pagination from '@material-ui/lab/Pagination'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../../Tools/translations'
import useNavigationTools from '../../../../Hooks/useNavigationTools'

function SecondBlock (props) {
  const { getTextForPage, getRouteFor } = useMpecTranslations('searchResults')
  const { currentPage, fetchSearch, getSearchData, totalPages, setCurrentPage } = useNavigationTools()
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    fetchSearch(props.searchInput, props.currentFilters).then(() => {
      setIsLoaded(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentFilters, currentPage, props.searchInput])

  const changePage = (event, value) => {
    setCurrentPage(value)
  }

  const cardSearch = (content) => {
    const names = getNameCategoryNames(content.type)
    return (
      <div key={uuidv4()} className={'secondBlock p-1'}>
        <h5 className={'title'}>{_.upperFirst(names.title)}</h5>
        <p className={'font-weight-bold mb-0'}>{content.title}</p>
        <p className={'mb-1'}>{ReactHtmlParser(content.description)}</p>
        <a className={'link'}
           href={getRouteFor(
             names.route,
             { title: _.kebabCase(content?.title), category: content.category },
             true
           )}
           data-end=">">
          {_.upperFirst(getTextForPage('secondBlock.seePage'))}
        </a>
      </div>
    )
  }

  const displayGreyBlocks = () => {
    if (isLoaded) {
      const data = getSearchData().results

      return _.map(data, content => {
        return <div key={uuidv4()} className="secondBlock p-3 mb-5">
          {cardSearch(content)}
        </div>
      })
    }
  }

  const getNameCategoryNames = (name) => {
    switch (name) {
      case 'articles':
        return { title: getTextForPage('secondBlock.article'), route: name }
      case 'activities':
        return { title: getTextForPage('secondBlock.activities'), route: 'activity' }
      default:
        return name
    }
  }

  return (
    <>
      <div className="col-lg-8">
        {displayGreyBlocks()}
      </div>
      <Pagination
        className={'pagination ml-auto mr-auto'}
        count={totalPages}
        shape="rounded"
        onChange={changePage}
      />
    </>
  )
}

export default SecondBlock
