import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './ImgTextCard.scss'

function ImgTextCard (props) {
  const [content] = useState(props.content)
  const [img] = useState(props.img)
  const [classNameImg] = useState(props.classNameImg || '')

  return (
    <div className={'ImgTextCard row'}>
      <div className={'mb-4 p-0 img-card col-12 col-sm-12 col-md-12 col-lg-5 d-none d-sm-block'}>
        <img
          alt={img}
          src={img}
          className={classNameImg}
        />
      </div>
      <div className="mb-lg-4 para-texts col-12 col-sm-12 col-md-12 col-lg-7">
        <div className={'blocktext'}>
          {content}
        </div>
      </div>
      <div className={'mb-4 p-0 img-card col-12 col-sm-12 col-md-12 col-lg-5 d-block d-sm-none'}>
        <img
          alt={img}
          src={img}
          className={classNameImg}
        />
      </div>
    </div>
  )
}

ImgTextCard.propTypes = {
  content: PropTypes.any,
  img: PropTypes.any,
  classNameImg: PropTypes.any
}

export default ImgTextCard
