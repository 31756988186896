const conferencesWorkshops = {
  fr: {
    translation: {
      pages: {
        conferencesWorkshops: {
          activation: 'chargement de la page',
          conferences: 'conférences',
          description: 'cette année, je grandis sur la thématique du bien-être',
          event: 'evénements  à venir',
          limitedPersons: 'Limité à 1 personne',
          limitedPersons_plural: 'Limité à {{count}} personnes',
          nope: 'pas de replay de disponible pour l\'instant',
          passedEvent: 'évènements passées',
          replay: 'replay',
          title: 'conférences et Ateliers',
          workshop: 'ateliers',
          firstPanel: {
            nope: 'pas d\'atelier et conférence de disponible pour l\'instant',
          },
          imgBlock: {
            firstPart: '1ère partie',
            secondPart: '2ème partie',
            workshopDistance: 'les ateliers sont en distanciel'
          },
          noEvents: {
            noWorkshopAndConference: 'Il n’y a pas de conférence ou d’atelier accessible pour l’instant. Restez attentifs, des événements sont à venir.',
            noReplay: 'Il n’y a pas de replay accessible pour l’instant. Restez attentifs, des événements sont à venir.'
          }
        }
      }
    }
  }
}

export { conferencesWorkshops }
