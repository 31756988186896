import React, { useState } from 'react'
import CoachingParentsZens from '../CoachingParentsZens/CoachingParentsZens'
import './CoachingParentsZensPlus.scss'
import '../CoachingParentsZens/CoachingParentsZens.scss'
import BlockGreen from './partials/BlockGreen/BlockGreen'
import BlockPlus from './partials/BlockPlus/BlockPlus'
import Form from './partials/Form/Form'

function CoachingParentsZensPlus () {
  const [isClicked, setIsClicked] = useState(false)

  const onClick = () => {
    setIsClicked(true)
  }

  const displayBlock = () => {
    if (isClicked) {
      return <Form/>
    }

    return <BlockGreen clicked={onClick.bind(this)}/>
  }

  return (
    <div className={'CoachingParentsZens CoachingParentsZensPlus'}>
      <CoachingParentsZens/>
      <div className="content-wrapper">
        <div className={'col-lg-11 ml-auto mr-auto'}>
          <div className="line mt-4 mb-4"></div>
          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <BlockPlus/>
            {displayBlock()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoachingParentsZensPlus
