import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import './Button.scss'

function Button (props) {
  const buttonFunction = props.buttonFunction
  const defaultClassName = 'btn p-3 mt-5 mb-5'
  const [className] = useState(props.className || defaultClassName)
  const [classSup] = useState(props.classSup || 'btnGreen')
  const [classDiv] = useState(props.classDiv || 'ButtonComponent center ')
  const [classSupDiv] = useState(props.classSupDiv || '')
  const [disabled, setDisabled] = useState(props.disabled || false)
  const [id] = useState(props.id|| '')
  const [title] = useState(props.title)
  const [isButtonWithAction] = useState(props.isButtonWithAction || false)
  const [isLoading, setIsLoading] = useState(false)
  const [link] = useState(props.link || '#')
  const [name] = useState(props.name || '')
  const [target] = useState(props.target || '')
  const [type] = useState(props.type || 'button')

  useEffect(() => {
    if (props.isLoading) {
      setIsLoading(props.isLoading)
    }
  }, [props])

  useEffect(() => {
    setDisabled(props.disabled)
  },[props.disabled])

  const displayButtonWithAction = () => {
    return <button
      className={className + ' ' + classSup}
      id={id}
      name={name}
      onClick={buttonFunction}
      type={type}
      disabled={disabled}
    >
      {
        isLoading ?
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} className={'fa-pulse'}/>
        </div>
        : title
      }
    </button>
  }

  return (
    <div className={classDiv + classSupDiv}>
      {isButtonWithAction ? displayButtonWithAction() :
        <a href={link} target={target} className={className + ' ' + classSup}>{title}</a>
      }
    </div>
  )
}

Button.propTypes = {
  buttonFunction: PropTypes.func,
  className: PropTypes.string,
  classSup: PropTypes.string,
  classDiv: PropTypes.string,
  classSupDiv: PropTypes.string,
  disabled: PropTypes.bool,
  isButtonWithAction: PropTypes.bool,
  isLoading: PropTypes.bool,
  link: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
}

export default Button
