import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../../Tools/translations'

function LegalInformationArticle05 () {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'container article'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <h3 className={'col-12'}>{getTextForPage('article5.title')}</h3>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article5.siteAvailabilty'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{ReactHtmlParser(getTextForPage('article5.editorNoResponsibility.text'))}</p>
        </div>
        <ul className={''}>
          <li>{getTextForPage('article5.editorNoResponsibility.li01')}</li>
          <li>{getTextForPage('article5.editorNoResponsibility.li02')}</li>
          <li>{getTextForPage('article5.editorNoResponsibility.li03')}</li>
          <li>{getTextForPage('article5.editorNoResponsibility.li04')}</li>
        </ul>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article5.noGuarantee'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{ReactHtmlParser(getTextForPage('article5.userResponsibility.text'))}</p>
        </div>
        <ul className={''}>
          <li>{getTextForPage('article5.userResponsibility.li01')}</li>
          <li>{getTextForPage('article5.userResponsibility.li02')}</li>
          <li>{getTextForPage('article5.userResponsibility.li03')}</li>
          <li>{getTextForPage('article5.userResponsibility.li04')}</li>
        </ul>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article5.contentReporting'))}</p>
        </div>
      </div>
    </div>
  )
}

export default LegalInformationArticle05
