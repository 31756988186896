const coachingParentsZensPlus = {
  fr: {
    translation: {
      pages: {
        coachingParentsZensPlus: {
          blockPlus: {
            text: 'vous vivez une situation particulièrement difficile en ce moment (divorce, deuil, maladie...) et avez besoin d\'en parler. Nos experts peuvent vous accompagner sur plusieurs séances pour vous aider à surmonter cet événement.',
            title: 'obtenir un accompagnement sur plusieurs séances',
            greenBlock: {
              button: 'je choisis ma première date',
              title: 'je souhaite débuter mon accompagnement'
            }
          },
        }
      }
    }
  }
}

export { coachingParentsZensPlus }
