import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Block from '../Partials/Block/Block'
import './TimelineDesktop.scss'

function TimelineDesktop (props) {
  const [allData, setAllData] = useState(props.allData)
  const [company] = useState(props.company)

  useEffect(() => {
    if (props.allData) {
      setAllData(props.allData)
    }
  }, [props])

  const displayBlocks = () => {
    return _.map(getAvailableData(), (data, index) => {
      if (index % 2 !== 0) {
        return <Block key={uuidv4()} title={data.title} img={data.img} isUp={false} isMobile={false} route={data.key}/>
      } else {
        return <Block key={uuidv4()} title={data.title} img={data.img} isUp={true} isMobile={false} route={data.key}/>
      }
    })
  }

  const getAvailableData = () => {
    return _.filter(allData, data => {
      return !_.includes(company.blockNotAvailable, data.key)
    })
  }

  const centerTimelineDesktop = () => {
    const nbServices = getAvailableData().length
    if (nbServices >= 8) {
      return '600px'
    } else if (nbServices >= 5) {
      return '100px'
    } else {
      return '0px'
    }
  }

  return (
    <div className={'TimelineDesktop mt-5'}>
      <div id="horizontal-timeline" className="timelineDesktop horizontal reverse" style={{ marginLeft: centerTimelineDesktop() }}>
        {displayBlocks()}
        <div id="end"></div>
      </div>
    </div>
  )
}

TimelineDesktop.propTypes = {
  allData: PropTypes.array
}

export default TimelineDesktop
