const parentsLife = {
  fr: {
    translation: {
      pages: {
        parentsLife: {
          description: 'Votre enfant grandit jour après jour. Pour vous aider à comprendre ses besoins et vous soutenir dans votre rôle de parent nous vous proposons des outils, activités, fiches pratiques en fonction de chaque âge. Cliquez et découvrez ! :)',
          title: 'vie de parent : boite à outils'
        }
      }
    }
  }
}

export { parentsLife }