import PropTypes from 'prop-types'
import React, { useState } from 'react'
import H1 from '../HX/H1'

function TitlePage (props) {
  const [classTitle] = useState(props.classTitle)
  const [title] = useState(props.title)
  return (
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-12 m-auto">
            <H1 text={title} className={classTitle}/>
          </div>
        </div>
      </div>
    </div>
  )
}

TitlePage.propTypes = {
  classTitle: PropTypes.string,
  title: PropTypes.string
}

export default TitlePage