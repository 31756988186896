import React from 'react'
import BabysitorMainPage from './babysitor/BabysitorMainPage'
import './ComplementaryGuard.scss'

function ComplementaryGuard () {

  return (
    <BabysitorMainPage/>
  )
}

export default ComplementaryGuard