import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './BreadCrumb.scss'
import BreadCrumbLink from './partials/BreadCrumbLink'

function BreadCrumb (props) {
  const [links, setLinks] = useState(props.links || [])
  const [className] = useState(props.className || 'mt-3 mb-3')

  useEffect(() => {
    if (props.links) {
      setLinks(props.links)
    }
  }, [props])

  return (
    <div id="breadcrumb" className={className}>
      {links.map((link, i) => {
        return <BreadCrumbLink
          key={uuidv4()}
          {...link}
        />
      })}
    </div>
  )
}

BreadCrumb.propTypes = {
  links: PropTypes.array,
  className: PropTypes.string
}

export default BreadCrumb
