import React from 'react'
import Aside from './Partials/Aside'
import Navbar from './Partials/Navbar'
import './Header.scss'

function Header () {
  return (
    <div>
      <Navbar/>
      <Aside/>
    </div>
  )
}

export default Header
