import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useDefaultClassName from './useDefaultClassName'
import './HX.scss'

function H4 (props) {
  const { defaultClassName } = useDefaultClassName()
  const [text] = useState(props.text)
  const [className] = useState(props.className || defaultClassName)

  return (
    <h4 className={className}>{text}</h4>
  )
}

H4.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}

export default H4
