import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'

function BlockGreen (props) {
  const setClicked = props.clicked

  const { getTextForPage } = useMpecTranslations('coachingParentsZensPlus')
  return (
    <div className={'BlockGreen col-lg-4 text-center p-5'}>
      <h2 className={'white mb-3'}>{_.upperFirst(getTextForPage('blockPlus.greenBlock.title'))}</h2>
      <Button
        title={_.toUpper(getTextForPage('blockPlus.greenBlock.button'))}
        classSup={'btnWhiteBorder'}
        isButtonWithAction={true}
        buttonFunction={setClicked.bind(this)}
      />
    </div>
  )
}

BlockGreen.propTypes = {
  clicked: PropTypes.func,
}

export default BlockGreen
