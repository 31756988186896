import Axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import TitlePage from '../../Common/TitlePage/TitlePage'
import useCompaniesTools from '../../Hooks/useCompaniesTools'
import useEventsTools from '../../Hooks/useEventsTools'
import useMailerTools from '../../Hooks/useMailerTools'
import useMpecServices from '../../Hooks/useMpecServices'
import usePagesTools from '../../Hooks/usePagesTools'
import ThanksBlock from '../CoachingParentsZens/partials/ThanksBlock/ThanksBlock'
import AlreadyParticipate from './partials/AlreadyParticipate/AlreadyParticipate'
import FullBlock from './partials/FullBlock/FullBlock'
import GreyBlock from './partials/GreyBlock/GreyBlock'
import './ReturnMaternityPaternityLeave.scss'

//@Todo Refacto avec la page coachingParent
function ReturnMaternityPaternityLeave () {
  const { contactInformations, contactSalesInformation, getContactInformation, getContactSalesInformation } = useCompaniesTools()
  const {
    createEventRegistration,
    fetchEventsInfoFromApi,
    getEventByName,
    isEventFull,
    isUserAlreadySubscribe,
    isUserCanSubscribe
  } = useEventsTools()
  const { getEmailData, handleEmailTo } = useMailerTools()
  const { getStorage, getUserData } = useMpecSecure()
  const { currentEnv } = useMpecServices()
  const { getTextCompaniesNames, getTextForPage } = useMpecTranslations('returnMaternityPaternityLeave')
  const { spinner, fetchPageData, pageData } = usePagesTools()
  const [currentStep, setCurrentStep] = useState(1)
  const [isClicked, setIsClicked] = useState(false)
  const [isConditionsTrue, setIsConditionsTrue] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const sessionId = getStorage().getItem('sessionId')
  const source = Axios.CancelToken.source()

  useEffect(() => {
    fetchEventsInfoFromApi(getUserData().idCompany, sessionId).then()
    fetchPageData('returnMaternityPaternityLeave').then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getContactInformation(currentEnv).then()
    getContactSalesInformation(currentEnv).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnv])

  const displayErrorMessage = () => {
    if (isClicked && !isConditionsTrue) {
      $('.inscriptionLabel').addClass('error')
      return <p className={'error warning font-italic'}>{_.upperFirst(getTextForPage('greyBlock.warning'))}</p>
    }
  }

  const displayGreyBlock = () => {
    const event = getEventByName('returnMaternityPaternityLeave')

    if (isUserAlreadySubscribe(event)) {
      return <AlreadyParticipate/>
    }

    if (isEventFull(event)) {
      return <FullBlock/>
    }

    if (currentStep === 2) {
      return <ThanksBlock
        title={_.upperFirst(getTextForPage('thanksBlock.title'))}
        subject={_.upperFirst(getTextForPage('thanksBlock.subject'))}
      />
    }

    return <GreyBlock
      clicked={onClick.bind(this)}
      displayErrorMessage={displayErrorMessage.bind(this)}
      handleChange={handleChange.bind(this)}
    />
  }

  const getEmailForEvent = (templateId, userData) => {
    const companyName = _.upperFirst(getTextCompaniesNames(userData.companyName))
    const emailTo = userData.defaultEmail
    let phone = ''
    if (_.isString(userData.phone)) {
      phone = userData.phone
    } else {
      const name = _.keys(userData.phone)
      phone = userData.phone[name].phone.concat(' - ').concat(name)
    }

    const variables = {
      'NomService': getTextForPage('title'),
      'Societe': companyName,
      'Nom': userData.lastname,
      'Prénom': userData.firstname,
      'téléphone': phone,
      'email': emailTo
    }
    return getEmailData(templateId, userData, variables, getTextForPage('subjectMail'), handleEmailTo(contactInformations))
  }

  const getEmailDataForLastPlace = (templateId, userData) => {
    const variables = {
      'Nomclient': _.upperFirst(getTextCompaniesNames(userData.companyName)),
      'Nomservice': getTextForPage('title')
    }
    const ddoAndSalesContact = _.concat(contactInformations, contactSalesInformation)

    return getEmailData(templateId, userData, variables, getTextForPage('subjectMail2'), handleEmailTo(ddoAndSalesContact))
  }

  const handleChange = (id, event) => {
    const myClickField = $(event.target)
    if (myClickField.is(':checked')) {
      setIsConditionsTrue(true)
      $('.inscriptionLabel').removeClass('error')
    } else {
      setIsConditionsTrue(false)
    }
  }

  const onClick = async (event) => {
    setIsClicked(true)
    if (isConditionsTrue && isUserCanSubscribe('returnMaternityPaternityLeave')) {
      await sendEmailForEvent(event)
      await registerUserToEvent()
    }
  }

  const registerUserToEvent = async () => {
    const event = getEventByName('returnMaternityPaternityLeave')

    if (event) {
      const placesRemaining = event?.remainingPlaces
      const isLastPlaceRemaining = placesRemaining === 1

      const data = {
        idStatus: event?.idInscriptionInProgress,
        idEventSchedule: event?.idEventSchedule,
        idUser: event?.idUser,
        isActive: true
      }
      await createEventRegistration(data, sessionId)

      if (isLastPlaceRemaining) {
        await sendEmailForLastPlaceRemaining()
      }
    }
  }

  const sendEmailForEvent = async (event) => {
    event.preventDefault()
    const emailData = getEmailForEvent(3194158, getUserData())
    try {
      await Axios.post('/api/mpecMailer', emailData, {
        cancelToken: source.token
      })
      const newStep = currentStep + 1
      setCurrentStep(newStep)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const sendEmailForLastPlaceRemaining = async () => {
    const emailData = getEmailDataForLastPlace(3194170, getUserData())
    try {
      await Axios.post('/api/mpecMailer', emailData, {
        cancelToken: source.token
      })
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const displayAll = () => {
    return <div className="d-flex justify-content-between content col-lg-11 row">
      <div className={'col-lg-6 mr-1'}>
        {ReactHtmlParser(pageData.description)}
        <div className="d-flex justify-content-center">
          <img src={process.env.REACT_APP_LINK_IMG + pageData.img} className={'col-lg-7 p-0'} alt=""/>
        </div>
        <p className={'d-lg-none d-sm-block mb-5'}></p>
      </div>
      {displayGreyBlock()}
    </div>

  }

  return (
    <div className={'ReturnMaternityPaternityLeave content-wrapper mb-5'}>
      <HeaderPage isDisplayLogo={false} imgBannerBackground={'returnMaternityPaternityLeave'}/>
      <div className="container">
        <TitlePage title={getTextForPage('title')}/>
        {isLoad ? displayAll() : spinner()}
      </div>
    </div>
  )
}

export default ReturnMaternityPaternityLeave
