import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './SubMenu.scss'
import useWichTerminal from '../../Hooks/useWichTerminal'

function SubMenu (props) {
  const { isMobile } = useWichTerminal()
  const [col] = useState(props.col)
  const [colDiv] = useState(props.colDiv)
  const [links] = useState(props.links)

  const displayLinks = () => {
    return _.map(links, (link, index) => {
      let newCol = col

      if ( _.has(link, 'col') && _.get(link, 'col') !== false) {
        newCol = link.col
      }
      if (index === 0) {
        return <a key={uuidv4()}
                  className={'nav-link active text-center ' + col} id={link.id} data-toggle="tab"
                  href={link.href} role="tab" aria-controls={link.ariaControls}
                  aria-selected="true">
          {link.title}
        </a>
      }
      return <a key={uuidv4()}
                className={'nav-link text-center ' + newCol} id={link.id} data-toggle="tab"
                href={link.href} role="tab" aria-controls={link.ariaControls}
                aria-selected="false">
        {link.title}
      </a>
    })
  }

  const getSpaceBetween = () => {
    return isMobile() ? '' : 'justify-content-between'
  }

  return (
    <nav className={'SubMenu m-auto ' + colDiv}>
      <div className={'nav nav-tabs row mt-3 mb-3 d-flex ' + getSpaceBetween()} id="nav-tab" role="tablist">
        {displayLinks()}
      </div>
    </nav>
  )
}

SubMenu.propTypes = {
  col: PropTypes.string,
  colDiv: PropTypes.string,
  links: PropTypes.array
}

export default SubMenu
