import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import useMpecTranslations from '../../../../../../../Tools/translations'
import './CardsTimeline.scss'

function CardsTimeline (props) {
  const history = useHistory()
  const { getRouteFor } = useMpecTranslations()
  const [isTop] = useState(props.isTop || false)
  const [title] = useState(props.title || false)
  const [img] = useState(props.img || false)
  const [isMobile, setIsMobile] = useState(props.isMobile || false)
  const [route] = useState(props.route || 'blocParentsLife')
  const [routeParam] = useState(props.routeParam || {})

  const redirect = (route) => {
    history.push(route)
  }

  const getOnClick = (event) => {
    event.preventDefault()
    if (route !== '') {
      redirect('/'.concat(getRouteFor(route, routeParam)))
    }
    return false
  }

  useEffect(() => {
    if (props.isMobile) {
      setIsMobile(props.isMobile)
    }
  }, [props])

  const displayArrowDirection = () => {
    return isTop ? 'down' : 'up'
  }

  const displayDesktopMode = () => {
    return (
      <div>
        {isTop ? <div className={'triangle-code-up'}></div> : null}
        <div className={'CardsTimeline square ' + displayArrowDirection()}>
          <div className={'image'}>
            <img src={'https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/' + img} alt="Parentszens" className="brand-image"/>
          </div>
          <p className={'d-flex align-items-center justify-content-center text-center p-3'}>
            {title}
          </p>
        </div>
        {!isTop ? <div className={'triangle-code-down'}></div> : null}
      </div>
    )
  }

  const displayMobileMode = () => {
    return (
      <div className={'d-flex'}>
        <div className={'triangle-code'}></div>
        <div className={'CardsTimeline square ' + displayArrowDirection()}>
          <div>
            <img src={'https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/' + img} alt="Parentszens" className="brand-image"/>
          </div>
          <p className={'text-center p-3'}>
            {title}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div onClick={(event) => {
      getOnClick(event)
    }}>
      {isMobile ? displayMobileMode() : displayDesktopMode()}
    </div>
  )
}

CardsTimeline.propTypes = {
  isTop: PropTypes.bool,
  title: PropTypes.string,
  isMobile: PropTypes.bool,
  img: PropTypes.string,
  route: PropTypes.string
}

export default CardsTimeline
