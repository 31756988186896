import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import H3 from '../../Common/HX/H3'
import TitlePage from '../../Common/TitlePage/TitlePage'
import useAccountsTools from '../../Hooks/useAccountsTools'
import useFormsTools from '../../Hooks/useFormsTools'
import './MyProfile.scss'
import CheckboxInformation from './partial/CheckboxInformation'
import InputInformation from './partial/InputInformation'
import InputPassword from './partial/InputPassword'

function MyProfile () {
  const { getTextForPage } = useMpecTranslations('myProfile')
  const { changeMyPassword, getMyProfile, getParamsForForms, paramsForms, myProfile, saveMyProfile } = useAccountsTools()
  const { comparePasswords, disconnectUser, isValidPassword } = useMpecSecure()
  const { toastError, toastSuccess } = useFormsTools()
  const [updateData] = useState({})

  useEffect(() => {
    getMyProfile().then()
    getParamsForForms().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    _.set(updateData, 'id', _.get(myProfile, '_id'))
    saveMyProfile(updateData)
  }

  const handleSubmitFormPasswords = async (event) => {
    event.preventDefault()
    const fieldNewPassword = document.getElementById('inputNewPassword')
    const fieldConfirmPassword = document.getElementById('inputPasswordConfirmed')
    const fieldCurrentPassword = document.getElementById('inputActualPassword')
    const currentPassword = fieldCurrentPassword.value
    const newPassword = fieldNewPassword.value
    const confirmPassword = fieldConfirmPassword.value
    let hasError = false

    if (!comparePasswords(newPassword, confirmPassword)) {
      $(fieldNewPassword).addClass('is-invalid')
      $(fieldConfirmPassword).addClass('is-invalid')
      toastError(_.upperFirst(getTextForPage('error.noMatchingPassword')))
      hasError = true
    } else {
      $(fieldNewPassword).removeClass('is-invalid')
      $(fieldConfirmPassword).removeClass('is-invalid')
    }

    if (comparePasswords(newPassword, currentPassword)) {
      $(fieldNewPassword).addClass('is-invalid')
      $(fieldCurrentPassword).addClass('is-invalid')
      toastError(_.upperFirst(getTextForPage('error.newMustDifferFromActual')))
      hasError = true
    } else {
      $(fieldNewPassword).removeClass('is-invalid')
      $(fieldCurrentPassword).removeClass('is-invalid')
    }

    if (!isValidPassword(newPassword)) {
      $(fieldNewPassword).addClass('is-invalid')
      toastError(_.upperFirst(getTextForPage('error.passwordNotConformToTheRule')))
      hasError = true
    } else {
      $(fieldNewPassword).removeClass('is-invalid')
    }

    if (!hasError) {
      $(fieldCurrentPassword).addClass('is-valid')
      $(fieldNewPassword).addClass('is-valid')
      $(fieldConfirmPassword).addClass('is-valid')

      $('#submitChangePassword').prop('disabled', true)
      const result = await changeMyPassword(currentPassword, newPassword, confirmPassword)
      if (_.get(result, 'error', false)) {
        toastError(_.upperFirst(getTextForPage('error.'.concat(_.camelCase(result.detail)))))
        $('#submitChangePassword').prop('disabled', false)
        $(fieldCurrentPassword).addClass('is-invalid')
        $(fieldNewPassword).addClass('is-invalid')
        $(fieldConfirmPassword).addClass('is-invalid')
      } else {
        toastSuccess(_.upperFirst(getTextForPage('success.'.concat(_.camelCase(result.detail)))), { onClose: props => disconnectUser() })
      }
      console.log({ result })
    }
  }

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    let val = _.get(updateData, name)
    if (_.isArray(val)) {
      if (!event.target.checked) {
        val = val.filter(function (internalValue, index, arr) {
          return internalValue !== parseInt(value)
        })
      } else {
        val.push(parseInt(value))
      }
      _.set(updateData, name, val)
    } else {
      _.set(updateData, name, value)
    }
  }

  return (
    <div className={'container'}>
      <div className="MyProfile content-wrapper">
        <div className="row mb-2 mt-3">
          <div className="col-sm-6 m-auto">
            <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
          </div>
        </div>
        <div className={'container'}>
          <div className="mb-2">
            <H3 text={_.upperFirst(getTextForPage('information'))} className={'page-subtitle-blueAzure400 mb-3'}/>
          </div>
          <form className={'form'} onSubmit={event => handleSubmit(event)} name={'profileData'} method={'POST'} id={'my-profile'}>
            <InputInformation
              formParams={paramsForms}
              handleChange={handleChange}
              myProfile={myProfile}
              updateData={updateData}
            />
            <CheckboxInformation
              formParams={paramsForms}
              handleChange={handleChange}
              myProfile={myProfile}
              updateData={updateData}
            />
          </form>
          <form className={'form'} name={'changePassword'} method={'POST'} onSubmit={handleSubmitFormPasswords}>
            {<InputPassword/>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default MyProfile
