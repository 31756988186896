import ImgBlock from '../ImgBlock/ImgBlock'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import useMpecSecure from '../../../../../Tools/secure'
import useMpecTranslations from '../../../../../Tools/translations'
import usePagesTools from '../../../../Hooks/usePagesTools'
import useReplayTools from '../../../../Hooks/useReplayTools'
import { v4 as uuidv4 } from 'uuid'

function SecondPanel (props) {
  const [eventsLoad, setEventsLoad] = useState(props.eventsLoad)
  const [isLoad, setIsLoad] = useState(false)
  const getAllImgBlocks = props.getAllImgBlocks
  const { fetchAllReplays, replays } = useReplayTools()
  const { getTextForPage } = useMpecTranslations('conferencesWorkshops')
  const { getUserData } = useMpecSecure()
  const { spinner } = usePagesTools()

  useEffect(() => {
    fetchAllReplays(getUserData().companyName).then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.eventsLoad) {
      setEventsLoad(props.eventsLoad)
    }
  }, [props])

  const displayPage = () => {
    return <div className="d-flex col-lg-12 row justify-content-center">
      {_.map(replays, replay => {
        replay.classSubTitleImg = replay.category === 'workshop' ? 'subTitleBlue' : 'subTitleGreen'
        replay.imgTitle = _.upperFirst(getTextForPage(replay.category))
        replay.isReplay = true
        return <ImgBlock key={uuidv4()} {...replay}/>
      })}
      {getAllImgBlocks(true)}
    </div>
  }

  const allIsLoad = () => {
    return eventsLoad && isLoad
  }

  return (
    <div className="tab-pane fade show" id="nav-replay" role="tabpanel" aria-labelledby="nav-replay-tab">
      {allIsLoad() ? displayPage() : spinner()}
    </div>
  )
}

SecondPanel.propTypes = {
  events: PropTypes.array,
  eventsLoad: PropTypes.bool,
  getAllImgBlocks: PropTypes.func
}

export default SecondPanel
