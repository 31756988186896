import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useAccountsTools from '../../../Hooks/useAccountsTools'
import ActivationActivate from './activation/ActivationActivate'
import ActivationError from './activation/ActivationError'
import ActivationLoading from './activation/ActivationLoading'

function Activation (props) {
  const [activateCode, setActivateCode] = useState(props.activate || '')
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState({})
  const [message, setMessage] = useState({})
  const { activateMyAccount } = useAccountsTools()
  const setFormToRender = props.setFormToRender

  useEffect(() => {
    if (_.has(props, 'activate')) {
      setActivateCode(props.activate)
    }
  }, [props])

  useEffect(() => {
    async function fetchData () {
      if (!_.isEmpty(activateCode)) {
        const result = await activateMyAccount(activateCode)
        if (_.has(result, 'error')) {
          // an error occurred during the validation
          setHasError(true)
          setError(result)
          setLoading(false)
        } else {
          setMessage(result)
          setLoading(false)
        }
        console.log({ result })
      }
    }
    fetchData().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateCode])

  const renderTheContent = () => {
    if (loading) {
      return <ActivationLoading/>
    } else {
      if (hasError) {
        return (
          <ActivationError error={error}/>
        )
      } else {
        return <ActivationActivate message={message} setFormToRender={setFormToRender}/>
      }
    }
  }

  return (
    <div className={'Activation w-100 h-100 d-flex flex-column justify-content-center'}>
      {renderTheContent()}
    </div>
  )
}

Activation.propTypes = {
  activate: PropTypes.string,
  setFormToRender: PropTypes.func
}

export default Activation
