// eslint-disable-next-line no-unused-vars
import React, { createContext, useContext, useEffect, useState } from 'react'

const WindowDimensionsCtx = createContext(null)
const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState(
    {
      width: window.innerWidth,
      height: window.innerHeight
    }
  )
  useEffect(
    () => {
      const handleResize = () => {
        setDimensions(
          {
            width: window.innerWidth,
            height: window.innerHeight
          }
        )
      }
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
    , []
  )
  return (
    <WindowDimensionsCtx.Provider value={dimensions}>
      {children}
    </WindowDimensionsCtx.Provider>
  )
}
export default WindowDimensionsProvider
export const useWindowDimensions = () => useContext(WindowDimensionsCtx)
