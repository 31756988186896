import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useDefaultClassName from './useDefaultClassName'
import './HX.scss'

function H1 (props) {
  const { defaultClassName } = useDefaultClassName()
  const [text, setText] = useState(props.text)
  const [className] = useState(props.className || defaultClassName)

  useEffect(() => {
    if ( props?.text) {
      setText(props.text)
    }
  }, [props])

  return (
    <h1 className={className}>{text}</h1>
  )
}

H1.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}

export default H1
