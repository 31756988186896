import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecServices from '../../Hooks/useMpecServices'
import usePicturesTools from '../../Hooks/usePicturesTools'
import Header from '../ImgHeader/Header'
import './HeaderPage.scss'

function HeaderPage (props) {
  const defaultImgBackground = 'picture-banner'
  const { currentEnv } = useMpecServices()
  const [isDisplayLogo] = useState(props.isDisplayLogo !== false)
  const [imgBannerBackground] = useState(props.imgBannerBackground || defaultImgBackground)
  const { companyLogoData, getCompanyLogo } = usePicturesTools()
  const [classNameSup] = useState(props.classNameSup || '')

  useEffect(() => {
    if (isDisplayLogo) {
      getCompanyLogo(currentEnv).then(() => {
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnv])

  return (
    <div className="HeaderPage d-lg-block d-md-block d-sm-none">
      <Header
        logo={companyLogoData}
        imgBannerBackground={imgBannerBackground}
        isDisplayLogo={isDisplayLogo}
        classNameSup={classNameSup}
      />
    </div>
  )
}

HeaderPage.propTypes = {
  classNameSup: PropTypes.string,
  company: PropTypes.string,
  imgBannerBackground: PropTypes.string,
  isDisplayLogo: PropTypes.bool
}

export default HeaderPage
