import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import useFormsTools from '../../../../Hooks/useFormsTools'
import FormDataStep1 from './FormDataStep1'

function Step1 (props) {
  const { dataGeneralInformation } = FormDataStep1()
  const { getTextForPage } = useMpecTranslations('register')
  const { allInputs } = useFormsTools()
  const [multiplesCompaniesByDomain, setMultiplesCompaniesByDomain] = useState(props.multiplesCompaniesByDomain || {})

  useEffect(() => {
    if (props.multiplesCompaniesByDomain) {
      setMultiplesCompaniesByDomain(props.multiplesCompaniesByDomain)
    }
  }, [props])

  return (
    <>
      <div className={'row'}>
        <div className={'col-12'}>
          <p className={'fs-4 font-weight-bold'}>{getTextForPage('step1.subTitle')}</p>
        </div>
      </div>
      {allInputs(dataGeneralInformation, multiplesCompaniesByDomain)}
      <div className={'row justify-content-center'}>
        <Button
          className={'btn p-3 mt-5 mb-5 btnGreenLight'}
          id={'submitStep1'}
          isButtonWithAction={true} name={'submitStep1'}
          title={_.toUpper(getTextForPage('buttonStep2'))}
          type={'submit'}
        />
      </div>
    </>
  )
}

Step1.propTypes = {
  multiplesCompaniesByDomain: PropTypes.object
}

export default Step1