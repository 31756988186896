const filter = {
  fr: {
    translation: {
      filter: {
        activities: 'Activités',
        activitiesCultural: 'Activités culturelles',
        activitiesManual: 'Activités manuelles',
        activitiesTop: 'Top activités',
        activityAndPregnancy: 'Activité et grossess',
        audioBook: 'livres audio',
        beautyMode: 'mode et beauté',
        brainActivities: 'Activités cérébrales',
        childbirthPreparation: 'Préparation à l\'accouchement',
        college: 'Collège',
        coloring: 'Coloriages',
        culture: 'culture',
        development: 'développement',
        familyLife: 'vie de famille',
        favorActivities: 'Activités favorites',
        food: 'alimentation',
        gardening: 'jardinage',
        health: 'Santé',
        highSchool: 'Lycée',
        histories: 'Histoires',
        nursery: 'crèche',
        nutritionAndBaby: 'Nutrition et développement du bébé',
        parentalLeave: 'Les congés parentaux',
        parentAndEmployee: 'parent et salarié',
        parentAndFamilyLife: 'Parent et vie de famille',
        podcast: 'podcast',
        recipes: 'recettes',
        school: "L'école primaire",
        screens: 'Les écrans',
        songs: 'Chansons',
        songsNurseryRhymes: 'Chansons & Comptines',
        sport: 'sport',
        storiesAndTales: 'Histoires et contes',
        videos: 'vidéos',
        welcomeBabyPreparing: 'Préparer l\'accueil du bébé'
      }
    }
  }
}

export { filter }
