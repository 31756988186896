import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import Filter from '../../../../Common/Filter/Filter'
import useArticlesTools from '../../../../Hooks/useArticlesTools'
import AudioComponent from './components/AudioComponent/AudioComponent'
import './components/AudioComponent/styles.scss'
import VideoComponent from './components/VideoComponent/VideoComponent'
import MediaComponent from "./components/MediaComponent/MediaComponent";

function ThirdPanel (props) {
  const { category } = useParams() || null
  const { getTextForPage, getTextInSpecificLanguage } = useMpecTranslations('blocParentsLife')
  const { fetchListOfFilterMultimedia, fetchListOfMultimedia, getAllMultimedia, getNoMoreElementsMultimedia, getAllFiltersMultimedia } = useArticlesTools()
  const [currentFilters, setCurrentFilters] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [options, setOptions] = useState(
    {
      currentLimit: 0,
      limit: 12
    })

  useEffect(() => {
    setOptions(
      {
        currentLimit: 0,
        limit: 12
      })
  }, [currentFilters])

  useEffect(() => {
    const categoryTranslate = getTextInSpecificLanguage('en', 'paramsUrl', category)

    fetchListOfFilterMultimedia(categoryTranslate).then()
    fetchListOfMultimedia(categoryTranslate, currentFilters, options).then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const addFilterOrRemove = (filter) => {
    if (currentFilters.includes(filter)) {
      const newCurrentFilters = currentFilters.filter(selectedFilter => selectedFilter !== filter)
      setCurrentFilters(newCurrentFilters)
    } else {
      setCurrentFilters([...currentFilters, filter])
    }
  }

  const getAllCards = () => {
    const multimedia = getAllMultimedia()
    const multimediaGroup = _.chunk(multimedia, 3)

    if (!_.isEmpty(multimedia) && isLoad) {
      return _.map(multimediaGroup, group => {
        return <div key={uuidv4()} className="col-lg-12 d-flex row">
          {getElementFromGroup(group)}
        </div>
      })
    } else {
      return <p className={'text-center p-3 mt-4'}>{getTextForPage('noContent')}</p>
    }
  }

  const getElementFromGroup = (multimedia) => {
    if (isLoad) {
      return _.map(multimedia, (element) => {
        if (element.category === 'audio') {
          return <AudioComponent key={uuidv4()} audio={element} id={element?.id} img={element.img}/>
        }
        if (element.category === 'video') {
          return <VideoComponent key={uuidv4()} video={element} id={element?.id}/>
        }
        if (element.category === 'otherMedia') {
          return <MediaComponent key={uuidv4()} media={element} id={element?.id} img={element.img}/>
        }
      })
    }
  }

  const handleButtonFunction = () => {
    setOptions(oldOptions => ({ ...oldOptions, currentLimit: oldOptions.currentLimit + oldOptions.limit }))
  }

  return (
    <div className="ThirdPanel tab-pane fade" id="nav-questions" role="tabpanel" aria-labelledby="nav-questions-tab">
      <Filter
        addFilterOrRemove={addFilterOrRemove}
        allFilters={getAllFiltersMultimedia()}
      />
      {isLoad ?
        getAllCards()
        :
        <p>{_.upperFirst(getTextForPage('thirdPanel.loading'))}</p>
      }
      {!getNoMoreElementsMultimedia() &&
      <Button
        buttonFunction={handleButtonFunction}
        classSup={'btnGreen'}
        isButtonWithAction={true}
        title={_.toUpper(getTextForPage('moreVideos'))}
      />
      }
    </div>
  )
}

export default ThirdPanel
