import { faSignOutAlt, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import useMpecSecure from '../../../../Tools/secure'
import useMpecTranslations from '../../../../Tools/translations'
import Search from '../../Search/Search'

function Navbar () {
  const { disconnectUser } = useMpecSecure()
  const { getRouteFor } = useMpecTranslations('header')

  return (
    <nav className="NavBar main-header navbar navbar-expand navbar-white navbar-light justify-content-between">
      {/*<!-- Left navbar links -->*/}
      <ul className="navbar-nav">
        <li className="nav-item d-flex align-items-center">
          <span id="openMenu" className="nav-link" data-widget="pushmenu" role="button"><FontAwesomeIcon icon={faAngleRight}/></span>
        </li>
        <div className={'row'}>
          <a href={getRouteFor('homepage', {}, true)} className="brand-link w-100 d-flex justify-content-center">
            <img src={process.env.REACT_APP_LINK_IMG_LOGOS + 'Les-Parents-Zens.png'} alt="Parentszens" className="brand-image"/>
          </a>
        </div>
      </ul>
      <div className={'text-center'}>
        <Search/>
      </div>
      {/*<!-- SEARCH FORM -->*/}
      <div className="navbar-nav">
        <button type={'button'} className={'btn-sm btn-green mr-1 disconnectUser'} onClick={(event) => disconnectUser()}>
          <FontAwesomeIcon icon={faSignOutAlt}/>
        </button>
      </div>
    </nav>
  )
}

export default Navbar
