import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import useRoutesTools from '../../../../Hooks/useRoutesTools'
import './ImgBlock.scss'

function ImgBlock (props) {
  const { goToRoute } = useRoutesTools()
  const { getTextButton, getTextForPage } = useMpecTranslations('conferencesWorkshops')
  const [category] = useState(props.category)
  const [classSubTitleImg] = useState(props.classSubTitleImg)
  const [closeRegistrationDate] = useState(props.closeRegistrationDate)
  const [dataImg] = useState(props.img || 'fille-dos.png')
  const [directoryLink] = useState(props.directoryLink || 'evenements_hpz/')
  const [link] = useState(props.link || '')
  const [limitedPersons] = useState(props.limitedPersons)
  const [id] = useState(props.idEvent)
  const [imgTitle] = useState(props.imgTitle)
  const [isDownload] = useState(props.isDownload || false)
  const [isLimited] = useState(props.isLimited || false)
  const [isPast] = useState(props.isPast)
  const [isRemote] = useState(props.isRemote)
  const [isReplay] = useState(props.isReplay || false)
  const [isVariousParts] = useState(props.isVariousParts)
  const [openRegistrationDate] = useState(props.openRegistrationDate)
  const [resume] = useState(props.resume)
  const [room] = useState(props.room)
  const [roomDesc] = useState(props.roomDesc)
  const [roomDesc1] = useState(props.roomDesc1)
  const [roomDesc2] = useState(props.roomDesc2)
  const [title] = useState(props.title)

  const displayButton = () => {
    moment.locale('fr')
    const array = ["workshop", "conferences"]
    const date = openRegistrationDate
    const closeDate = closeRegistrationDate
    const now = moment()

    if (isDownload) {
      return <Button
          className={'btn pl-4 pr-4 cta'}
          isButtonWithAction={false}
          link={`${process.env.REACT_APP_LINK_IMG}${directoryLink}${link}`}
          target={"_blank"}
          buttonFunction={getOnClick.bind()}
          title={_.toUpper(getTextButton('downloadDocument'))}
      />
    }
    if (isPast && !isReplay) {
      return <Button
          className={'btn pl-5 pr-5 cta'}
          classSup={'btnWBBlue'}
          isButtonWithAction={true} buttonFunction={getOnClick.bind(this)}
          title={_.toUpper(getTextButton('passedEvent'))}
          disabled={true}
      />
    }
    if (now > closeDate) {
      return <Button
          className={'btn pl-5 pr-5 cta'}
          isButtonWithAction={true} buttonFunction={getOnClick.bind(this)}
          title={_.toUpper(getTextButton('endRegistration'))}
      />
    }
    if (now < date) {
      return <Button
          className={'btn pl-5 pr-5 cta'}
          isButtonWithAction={true} buttonFunction={getOnClick.bind(this)}
          title={_.toUpper(getTextButton('more'))}
      />
    }
    if((!isReplay && array.includes(category))) {
      return <Button
          className={'btn pl-5 pr-5 cta'}
          isButtonWithAction={true} buttonFunction={getOnClick.bind(this)}
          title={_.toUpper(getTextButton('inscription'))}
      />
    } else {
      return <Button
          className={'btn pl-5 pr-5 cta'}
          isButtonWithAction={true} buttonFunction={getOnClick.bind(this)}
          title={_.toUpper(getTextButton('seeReplay'))}
      />
    }
  }

  const displayDetails = () => {
    if (isLimited) {
      return (
          <div className="date mb-3 d-flex">
            <div className={'mr-3'}>
              <p className="mb-0 smallText">{roomDesc}</p>
              <p className={'smallText'}>{room}</p>
            </div>
            <p className={'verySmallText'}>{getTextForPage('limitedPersons', { limitedPersons })}</p>
          </div>
      )
    }

    return (
      <div className="date mb-3">
        <p className="mb-0 smallText">{ReactHtmlParser(roomDesc)}</p>
        <p className={'smallText'}>{ReactHtmlParser(room)}</p>
      </div>
    )
  }

  const displayFirstPart = () => {
    if (isVariousParts) {
      return (
        <div className="date mb-3 d-flex">
          <div className={'mr-3'}>
            <p className="mb-0 smallText">
              <span className="font-weight-bold">{ReactHtmlParser(getTextForPage('imgBlock.firstPart') + ' ')}</span>
              {roomDesc1}
            </p>
            <p className={'smallText'}>
              <span className="font-weight-bold">{ReactHtmlParser(getTextForPage('imgBlock.secondPart') + ' ')}</span>
              {roomDesc2}
            </p>
          </div>
        </div>
      )
    }
    return displayDetails()
  }

  const displayWorkshopPart = () => {
    if (category === 'workshop' && isVariousParts) {
      const actualRoom = isRemote ? _.upperFirst(getTextForPage('imgBlock.workshopDistance')) : room
      return <div className={'mt-3'}>
        <p className={'mb-0 font-weight-bold'}>{actualRoom}</p>
        {isLimited ? <p>{getTextForPage('limitedPersons',{ count: limitedPersons })}</p> : ''}
      </div>
    }
  }

  const getOnClick = (event) => {

    switch (true) {
      case (category === 'workshop' && !isReplay) :
        goToRoute(event, 'workshopInscription', { variable: _.kebabCase(title), id }, true)
        break
      case (category !== 'workshop' && !isReplay) :
        goToRoute(event, 'conferenceInscription', { variable: _.kebabCase(title), id }, true)
        break
      case (category === 'conferences') :
        goToRoute(event, 'replayConference', { title: _.kebabCase(title) }, true)
        break
      case (category === 'workshop') :
        goToRoute(event, 'replayWorkshop', { title: _.kebabCase(title) }, true)
        break
      default:
        return false
    }
  }
  return (
      <div className={'ImgBlock col-lg-4 mb-5'}>
        <div className="img">
          <img src={'https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/' + dataImg} alt={dataImg} className="brand-image"/>
          <p className={classSubTitleImg}>{imgTitle}</p>
        </div>
        <div className="content">
          {displayFirstPart()}
          <div className="title mb-3">
            {title}
          </div>
          <div className="description">
            {ReactHtmlParser(resume)}
          </div>
          {displayWorkshopPart()}
          <div className="childButton">
            {displayButton()}
          </div>
          <p className={'mb-4 d-sm-block d-lg-none'}/>
        </div>
      </div>
  )
}

ImgBlock.propTypes = {
  category: PropTypes.string,
  classSubTitleImg: PropTypes.string,
  closeRegistrationDate: PropTypes.object,
  description: PropTypes.string,
  directoryLink: PropTypes.string,
  img: PropTypes.string,
  imgTitle: PropTypes.string,
  isDistance: PropTypes.bool,
  isDownload: PropTypes.bool,
  isLimited: PropTypes.bool,
  isVariousParts: PropTypes.bool,
  link: PropTypes.string,
  openRegistrationDate: PropTypes.object,
  room: PropTypes.string,
  roomDesc1: PropTypes.string,
  roomDesc2: PropTypes.string,
  roomDesc: PropTypes.string,
  title: PropTypes.string
}

export default ImgBlock
