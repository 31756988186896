const inspirationalArticles = {
  fr: {
    translation : {
      pages : {
        inspirationalArticles : {
          home: 'Accueil',
          title: "Des articles pour m'inspirer",
          underTitle: "Etre parent, c'est faire partie d'une grande communauté où les questions se succèdent peu importe l'âge des enfants.\n Pour tous ces sujets qui nous touchent tout au long de notre parentalité, je clique, je lis et je m'inspire ici !"
        }
      }
    }
  }
}

export { inspirationalArticles }
