import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../Tools/translations'
import Button from '../../Common/Button/Button'
import H2 from '../../Common/HX/H2'
import TitlePage from '../../Common/TitlePage/TitlePage'
import usePagesTools from '../../Hooks/usePagesTools'
import '../ComplementaryGuard/ComplementaryGuard.scss'

function ComplementaryGuardO2 () {
  const { fetchPageData, pageData, spinner } = usePagesTools()
  const { getTextButton } = useMpecTranslations('complementaryGuardO2')
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    fetchPageData('complementaryGuardO2').then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayAll = () => {
    return <Fragment>
      <TitlePage title={_.upperFirst(pageData.title)}/>
      <div className="container">
        {ReactHtmlParser(pageData.firstBlock)}
        <div className={'col-lg-12 d-flex row'}>
          <div className="col-lg-6 p-0">
            <H2 className={'subtitleOther text-left'} text={_.upperFirst(pageData.subTitle1)}/>
            <div>{ReactHtmlParser(pageData.secondBlock)}</div>
          </div>
          <div className="col-lg-2 ml-auto divImgLogo mb-4">
            <img src={process.env.REACT_APP_LINK_IMG + pageData.img} className={'small mt-5'} alt=""/>
          </div>
        </div>
        <H2 className={'subtitleOther text-left'} text={_.upperFirst(pageData.subTitle2)}/>
        <p className={'mb-5'}>{pageData.benefits}</p>
        <Button
          classSupDiv={'text-center'}
          classSup={'btnGreen'}
          className={'btn p-3 cta mb-5'}
          title={_.upperFirst(getTextButton('more'))}
          link={'https://www.o2.fr/lp/partenaires/lesparentszens'} target={'_blank'}
        />
      </div>

    </Fragment>
  }

  return (
    <div className="ComplementaryGuardO2 content-wrapper">
      <div className="content smallContainer">
        {isLoad ? displayAll() : spinner()}
      </div>
    </div>
  )
}

export default ComplementaryGuardO2
