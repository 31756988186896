import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Block from '../Partials/Block/Block'
import './TimelineMobile.scss'

function TimelineMobile (props) {
  const [allData, setAllData] = useState(props.allData)

  useEffect(() => {
    if (props.allData) {
      setAllData(props.allData)
    }
  }, [props])

  const displayBlocks = () => {
    return _.map(allData, (data) => {
      return <li key={uuidv4()} className="timeline-item">
        <div className="timeline-marker"></div>
        <div className="timeline-content">
          <Block key={uuidv4()} title={data.title} img={data.img} isUp={true} isMobile={true}/>
        </div>
      </li>
    })
  }

  return (
    <div className="TimelineMobile">
      <div className="container-fluid mb-5 mt-5">
        <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
          <ul className="timelineUl">
            {displayBlocks()}
          </ul>
        </div>
      </div>
    </div>
  )
}

TimelineMobile.propTypes = {
  allData: PropTypes.array
}

export default TimelineMobile
