import _ from 'lodash'
import React from 'react'
import useMpecTranslations from '../../../Tools/translations'
import H1 from '../../Common/HX/H1'
import './LegalInformation.scss'
import Introduction from './partial/01-introduction'
import LegalInformationArticle01 from './partial/02-article01'
import LegalInformationArticle02 from './partial/03-article02'
import LegalInformationArticle03 from './partial/04-article03'
import LegalInformationArticle04 from './partial/05-article04'
import LegalInformationArticle05 from './partial/06-article05'
import LegalInformationArticle06 from './partial/07-article06'
import LegalInformationArticle07 from './partial/08-article07'
import LegalInformationArticle08 from './partial/09-article08'
import LegalInformationarticle09 from './partial/10-article09'
import LegalInformationarticle10 from './partial/11-article10'

function LegalInformation (props) {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'LegalInformation content-wrapper'}>
      <div className="col-sm-6 m-auto">
        <H1 text={_.upperFirst(getTextForPage('title'))}/>
      </div>
      <Introduction/>
      <LegalInformationArticle01/>
      <LegalInformationArticle02/>
      <LegalInformationArticle03/>
      <LegalInformationArticle04/>
      <LegalInformationArticle05/>
      <LegalInformationArticle06/>
      <LegalInformationArticle07/>
      <LegalInformationArticle08/>
      <LegalInformationarticle09/>
      <LegalInformationarticle10/>
    </div>
  )
}

export default LegalInformation
