import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'

function useCodeTools () {
  const [codeLoad, setCodeLoad] = useState(false)

  const createCodeLink = async (data, sessionId) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/code/company/service/create-link', { data, sessionId }, {
        cancelToken: source.token
      })

      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getCodeAvailableForService = async (data, sessionId) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/code/available/code/service', { data, sessionId }, {
        cancelToken: source.token
      })

      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getMyCode = async (sessionId) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/code/me/get', { sessionId }, {
        cancelToken: source.token
      })

      return result.data
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const getCodeNameForCompanyAndService = (idCompany, idService, codes) => {
    let currentCode = null
    _.forEach(codes, code => {
      if (code._company?.id === idCompany && code._customService?.id === idService) {
        currentCode = code._code
        return true
      }
    })

    setCodeLoad(true)

    return currentCode
  }

  return {
    codeLoad,
    createCodeLink,
    getCodeAvailableForService,
    getCodeNameForCompanyAndService,
    getMyCode
  }
}

export default useCodeTools