const coachingParentsZens = {
  fr: {
    translation: {
      pages: {
        coachingParentsZens: {
          subjectMail: 'DDO HPZ : demande de service',
          subjectMail2: 'DDO HPZ : plus de service',
          smallTitle: 'votre réponse nous aide à vous orienter vers le coach le plus adapté à votre besoin',
          greyBlock: {
            notPreference: 'Pas de préférence',
            phone: 'Téléphone',
            text1Block: 'Je donne mes préférences :',
            text5Block: 'Je souhaite aborder un sujet en particulier :',
            text6Block: 'votre réponse nous aide à vous orienter vers le coach le plus adapté à votre besoin',
            text7Block: 'Mon coach me contacte dans les 72h pour convenir du jour et de l\'horaire de notre séance',
            text8Block: 'J\'accepte que les coordonnées renseignées dans mon profil soient transmises à mon coach',
            text9Block: 'Important : le nombre de sessions est limité',
            titleBlock: 'Je souhaite échanger avec un coach',
            visio: 'Visio',
            warning: 'vous devez accepter que vos coordonnées soient transmises à votre coach, sans cela, il ne pourra pas vous contacter'
          },
          thanksBlock: {
            subject: 'l\'atelier des futurs parents vous contactera dans les 72h pour convenir avec vous des modalités de l\'accompagnement.',
            title: 'je bénéficie de l\'accompagnement',
            text1Thanks: 'Votre demande a bien été prise en compte.',
            text2Thanks: 'Votre coach vous contactera dans les 72h pour convenir avec vous du jour et de l\'horaire de votre séance.',
            text3Thanks: 'Si besoin, vous pouvez nous contacter, nous restons à votre écoute',
          }
        }
      }
    }
  }
}

export { coachingParentsZens }