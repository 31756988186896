const buttons = {
  fr: {
    translation: {
      button: {
        activeInscription: 'inscription active le {{date}}',
        changePassword: 'changer mon mot de passe',
        commission: 's\'inscrire à la commission',
        contactFamily: 'contacter ma référente famille',
        contactMe: 'me contacter',
        createAccount: 'créer mon compte',
        downloadColoring: 'télécharger le coloriage',
        downloadDocument: 'télécharger le document',
        endRegistration: 'fin des inscriptions',
        inscription: 's\'inscrire',
        key: 'Récupérer la clé',
        like: 'J\'aime cette activité',
        listenPodcast: 'ecouter les Podcasts',
        login: 'se connecter',
        more: 'en savoir plus',
        passedEvent: 'évènement terminé',
        print: 'j\'imprime l\'activité',
        redirectLoginScreen: 'revenir sur l\'espace de connexion',
        save: 'télécharger',
        saveChanges: 'enregistrer les modifications',
        seeActivity: 'voir l\'activité',
        seeAllArticles: 'voir tous les articles',
        seeColorings: 'voir les coloriages',
        seeRecipes: 'voir les recettes',
        seeReplay: 'voir le replay',
        send: 'envoyer',
        signUp: 'je m\'inscris',
        forgot: {
          backToConnection: 'retour sur l\'espace de connexion',
          sendTemporaryPassword: 'envoyer un mot de passe provisoire'
        }
      }
    }
  }
}

export { buttons }
