const header = {
  fr: {
    translation: {
      header : {
        '0-3': '0/3 ans',
        '3-6': '3/6 ans',
        '6-11': '6/11 ans',
        '11-15': '11/15 ans',
        '15-18': '15/18 ans',
        coachingParentsZen: "Coaching pour Parents Zens",
        conferencesAtelier: "Conférences et ateliers",
        contact: 'Contact',
        complementaryServices: "Garde complémentaire - BBS",
        complementaryServicesO2: "Garde complémentaire - O2",
        complementaryServicesBabySittor: "Garde complémentaire - Baby Sittor",
        elevenFifteenYear: "11/15 ans",
        fifteenEighteenYear: "15/18 ans",
        homePage: 'accueil',
        inspirationalArticles: "Des articles pour m'inspirer",
        livingParenthoodCompany: "Vivre ma parentalité au sein de mon entreprise",
        maxicours: "Maxicours",
        myFamiliz: "My familiz",
        myJourney: 'Mon parcours',
        myProfile: 'Mon Profil',
        ourSelectedPartners: 'nos partenaires sélectionnés',
        paternityMaternity: "Retours de congés paternité et maternité zens",
        parentingToolkit: "Vie de parents",
        placesNurseries: "Places en crèches",
        pregnancy: 'Grossesse',
        sixElevenYear: "6/11 ans",
        threeSixYear: "3/6 ans",
        toolkit: 'Boite à outils',
        register: 'C\' est ma première inscription',
        search: 'rechercher',
        zeroThreeYear: "0/3 ans",
      }
    }
  }
}

export { header }
