const returnMaternityPaternityLeave = {
  fr: {
    translation: {
      pages: {
        returnMaternityPaternityLeave: {
          subjectMail: 'DDO HPZ : demande de service',
          subjectMail2: 'DDO HPZ : plus de service',
          text1: 'Pour gérer dans les meilleures conditions votre retour de congé maternité, paternité ou second parent, nous avons mis à votre disposition un service d\'accompagnement clé-en-main.',
          text2: 'Un parcours individualisé est établi pour le parent jusqu’à son retour dans l’entreprise avec des propositions concrètes pour gérer cette période.',
          text3: 'Chaque nouveau parent bénéficie :',
          text4: '<ul><li>d\'un atelier de deux heures avec son conjoint/sa conjointe</li><li>de 2 mois de coaching</li></ul>',
          text5: 'Education positive, vie de couple, vie de famille, gestes du quotidien... Libre à vous de choisir les thèmes que vous souhaitez aborder.',
          title: 'Retours de congés maternité et paternité zens',
          alreadyParticipate: {
            text1: 'Vous avez déjà bénéficié de ce service. Si besoin, contactez votre référente famille.'
          },
          fullBlock: {
            text1: 'Le compte de votre entreprise a atteint sa limite d\'utilisation.'
          },
          greyBlock: {
            conditions: 'J\'accepte que les coordonnées renseignées dans mon profil soient transmises à l\'atelier des futurs parents',
            text2Block: 'Une fois inscrit(e), je serai donc contacté(e) par les services de l\'atelier des futurs parents pour définir ce qui correspond au mieux à mes besoins (ateliers en physique ou à distance, en couple ou individuel, date de l\'atelier...).',
            text3Block: 'Important : le nombre de sessions est limité',
            text1Block: 'Parce que chaque grossesse est unique, et que chaque parent a des besoins différents, il est essentiel que cet accompagnement soit réalisé sur mesure pour moi.',
            titleBlock: 'Je bénéficie de l\'accompagnement',
            warning: 'vous devez accepter que vos coordonnées soient transmises à votre coach, sans cela, il ne pourra pas vous contacter'
          },
          thanksBlock: {
            subject: 'l\'atelier des futurs parents vous contactera dans les 72h pour convenir avec vous des modalités de l\'accompagnement.',
            title: 'je bénéficie de l\'accompagnement'
          }
        }
      }
    }
  }
}

export { returnMaternityPaternityLeave }
