import PropTypes from 'prop-types'
import { useState } from 'react'
import { Route } from 'react-router'
import useMpecServices from '../../Hooks/useMpecServices'
import NotAllowed from '../NotAllowed/NotAllowed'

function ProtectedRoute (props) {
  const { isServiceAllowed } = useMpecServices()
  const [serviceKey] = useState(props.serviceKey)
  const [accessAllowed, setAccessAllowed] = useState(true)

  const check = () => {
    isServiceAllowed(serviceKey).then((res) => {
      if (!res) {
        setAccessAllowed(false)
      }
    }).catch((err) => {
      return false
    })
  }

  const renderRoute = () => {
    check()
    if (accessAllowed) {
      return <Route {...props}/>
    } else {
      return <Route {...props} component={NotAllowed}/>
    }
  }

  return renderRoute()
}

ProtectedRoute.propTypes = {
  serviceKey: PropTypes.string.isRequired
}

export default ProtectedRoute
