import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import useMpecSecure from '../../../../../Tools/secure'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../../Common/Button/Button'
import Card from '../../../../Common/Card/Card'
import Filter from '../../../../Common/Filter/Filter'
import useArticlesTools from '../../../../Hooks/useArticlesTools'
import useLikeTools from '../../../../Hooks/useLikeTools'

function SecondPanel (props) {
  const { fetchLikesByIdUser, likes, hasLike, isPageLike } = useLikeTools()
  const { getUserData } = useMpecSecure()
  const { category } = useParams()
  const { fetchListOfActivities, fetchListOfFilterActivities, getAllActivities, getAllFiltersActivities, getNoMoreElementsActivities } = useArticlesTools()
  const { getRouteFor, getTextForPage, getTextInSpecificLanguage } = useMpecTranslations('blocParentsLife')
  const [currentFilters, setCurrentFilters] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [options, setOptions] = useState(
    {
      currentLimit: 0,
      limit: 12
    })
  const history = useHistory()

  useEffect(() => {
    fetchLikesByIdUser(getUserData().idUser).then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOptions(
      {
        currentLimit: 0,
        limit: 12
      })
  }, [currentFilters])

  useEffect(() => {
    const categoryTranslate = getTextInSpecificLanguage('en', 'paramsUrl', category)

    fetchListOfFilterActivities(categoryTranslate).then()
    fetchListOfActivities(categoryTranslate, currentFilters, options, getUserData().idUser).then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const addFilterOrRemove = (filter) => {
    if (currentFilters.includes(filter)) {
      const newCurrentFilters = currentFilters.filter(selectedFilter => selectedFilter !== filter)
      setCurrentFilters(newCurrentFilters)
    } else {
      setCurrentFilters([...currentFilters, filter])
    }
  }

  const getAllCards = () => {
    const activities = getAllActivities()
    const cardsGroup = _.chunk(activities, 3)

    if (!_.isEmpty(activities) && isLoad) {
      return _.map(cardsGroup, group => {
        return <div key={uuidv4()} className="row mb-5">
          {getCardsFromGroup(group)}
        </div>
      })
    } else {
      return <p className={'text-center p-3 mt-4'}>{getTextForPage('noContent')}</p>
    }
  }

  const getCardsFromGroup = (group) => {
    return group.map((element) => {
      const textObject = {
        text: element?.header?.title,
        className: element.className,
        description: element?.description,
        classNameDescription: element?.classNameDescription
      }

      const routeName = getRouteFor('activity', { title: _.kebabCase(element?.header?.title), category }, true)
      element.like = isPageLike(likes, routeName, 'activities')

      return <Card key={uuidv4()}
                   allTexts={textObject}
                   buttonText={element?.buttonText}
                   className={'col-xs-12 col-sm-4'}
                   hasLike={element?.like}
                   fromRoute={true}
                   file={element?.file}
                   imgBackground={element.imgBackground}
                   isActivity={true}
                   isDownload={element?.isDownload}
                   isRoute={element?.isRoute}
                   redirect={redirect.bind(this)}
                   routeParam={{ title: _.kebabCase(element?.header?.title), category }}
                   route={'activity'}
                   type={element?.type}
                   isExtern={element?.isExtern}
                   link={element?.link}
      />
    })
  }

  const getFilters = () => {
    const filtersActivities = getAllFiltersActivities()
    if (!_.isEmpty(likes) && hasLike(likes, 'activities') && !_.isEmpty(filtersActivities)) {
      const lastIndex = _.last(filtersActivities)
      const option = lastIndex.value.replace('option', '')
      const index = parseInt(option)

      const favoriteFilter = {
        htmlFor: 'favorActivities',
        id: 'favorActivities',
        title: 'favorActivities',
        value: 'option' + (index + 1)
      }

      filtersActivities.push(favoriteFilter)
    }

    return filtersActivities
  }

  const handleButtonFunction = () => {
    setOptions(oldOptions => ({ ...oldOptions, currentLimit: oldOptions.currentLimit + oldOptions.limit }))
  }

  const redirect = (route) => {
    history.push(route)
  }

  return (
    <div className="tab-pane fade" id="nav-activity" role="tabpanel" aria-labelledby="nav-activity-tab">
      <Filter
        addFilterOrRemove={addFilterOrRemove}
        allFilters={getFilters()}
      />
      {getAllCards()}
      {!getNoMoreElementsActivities() &&
        <Button
          buttonFunction={handleButtonFunction}
          classSup={'btnGreen'}
          isButtonWithAction={true}
          title={getTextForPage('moreActivities')}
        />
      }
    </div>
  )
}

export default SecondPanel
