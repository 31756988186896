import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import HeaderPage from '../../Common/HeaderPage/HeaderPage'
import H2 from '../../Common/HX/H2'
import TitlePage from '../../Common/TitlePage/TitlePage'
import usePagesTools from '../../Hooks/usePagesTools'
import usePicturesTools from '../../Hooks/usePicturesTools'
import useReplayTools from '../../Hooks/useReplayTools'
import useWichTerminal from '../../Hooks/useWichTerminal'
import './Replay.scss'

function Replay (props) {
  const { getUserData } = useMpecSecure()
  const { displaySpeakers } = usePicturesTools()
  const { spinner } = usePagesTools()
  const { fetchReplaysData, replay } = useReplayTools()
  const { getTextForPage } = useMpecTranslations('replay')
  const { isMobile } = useWichTerminal()
  const { title } = useParams()
  const [category] = useState(props.isWorkshop ? 'workshop' : 'conferences')
  const [isLoad, setIsLoad] = useState(false)
  const [workshop] = useState(props.isWorkshop ? category : '')

  useEffect(() => {
    fetchReplaysData(title, category, getUserData().companyName).then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  const displayFirstBlock = () => {
    return (<Fragment>
      <H2 className={'subtitleOther text-left mb-5 ' + workshop} text={replay.title}/>
      <div className="mb-5">
        {ReactHtmlParser(replay.description)}
      </div>
      <H2 className={'smallTitle text-left mb-2 ' + workshop} text={_.upperFirst(getTextForPage('animate', { category: getTextForPage(category) }))}/>
    </Fragment>)
  }

  const displayPage = () => {
    return (
      <div className="ml-auto mr-auto d-flex justify-content-between col-lg-11 row mt-5">
        <div className="col-lg-6">
          {displayFirstBlock()}
          {displaySpeakers(replay.speakers, isMobile())}
        </div>
        <div className="col-lg-5">
          {displayVideo()}
        </div>
      </div>
    )
  }

  const displayVideo = () => {
    if (replay.isVariousParts) {
      return (
        <Fragment>
          {getVideo(replay.video1, replay.date, replay.duration1, _.upperFirst(getTextForPage('firstPart')))}
          {getVideo(replay.video2, replay.date2, replay.duration2, _.upperFirst(getTextForPage('secondPart')))}
        </Fragment>
      )
    }
    return getVideo(replay.video, replay.date, replay.duration)
  }

  const getVideo = (video, date, duration, part = null) => {
    return (
      <div className={'mb-5'}>
        <h2 className={'subtitleOther text-center mb-0 ' + workshop}>{_.upperFirst(getTextForPage('workshopDate', { date }))}</h2>
        {!_.isNull(part) ? <p className={'partVideoTitle font-italic text-center mb-0 ' + workshop}>{part}</p> : null}
        <p className={'timeVideo text-center'}>{getTextForPage('time', { duration })}</p>
        <iframe
          width="100%" height="345px"
          src={video}
          frameBorder="0"
          title={'replay'}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
        </iframe>
      </div>
    )
  }

  const displayAll = () => {
    if (isLoad) {
      if (!_.isEmpty(replay)) {
        return displayPage()
      }

      return ''
    }
    return spinner(_.upperFirst(getTextForPage('waiting')))
  }
  return (
    <div className="Replay content-wrapper mb-5">
      <HeaderPage isDisplayLogo={true} imgBannerBackground={'blondGirl'} classNameSup={'thirty'}/>
      <TitlePage title={_.upperFirst(getTextForPage('title'))}/>
      {displayAll()}
    </div>
  )
}

Replay.propTypes = {
  isWorkshop: PropTypes.bool
}

export default Replay
