import React from 'react'
import ConferenceInscription from '../ConferenceInscription/ConferenceInscription'
import './WorkshopInscription.scss'

function WorkshopInscription () {
  return (
    <div className={'WorkshopInscription'}>
      <ConferenceInscription isWorkshop={true}/>
    </div>
  )
}

export default WorkshopInscription
