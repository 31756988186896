import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import H1 from '../../Common/HX/H1'
import H3 from '../../Common/HX/H3'
import TitlePage from '../../Common/TitlePage/TitlePage'
import useAccountsTools from '../../Hooks/useAccountsTools'
import useFormsTools from '../../Hooks/useFormsTools'
import useWichTerminal from '../../Hooks/useWichTerminal'
import Step1 from './partials/Step1/Step1'
import Step2 from './partials/Step2/Step2'
import ThanksPage from './partials/ThanksPage/ThanksPage'
import './Register.scss'

function Register () {
  const { getParamsForForms, multiplesCompaniesByDomain, paramsForms, register } = useAccountsTools()
  const { toastError } = useFormsTools()
  const { comparePasswords, isValidPassword } = useMpecSecure()
  const { getTextForPage } = useMpecTranslations('register')
  const { isMobile } = useWichTerminal()
  const [currentStep, setCurrentStep] = useState(1)
  const maxStep = 2

  useEffect(() => {
    getParamsForForms('register').then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCurrentStep(currentStep)
  }, [currentStep])

  const goBackStep = () => {
    return (
      <div className={'col-12 pb-3'}>
        <button className={'goBack'} onClick={previousStep}>
          {`< ${_.upperFirst(getTextForPage('previousStep'))} ${currentStep - 1}`}
        </button>
      </div>
    )
  }

  const validateStep1 = (event) => {
    const fieldNewPassword = document.getElementById('inputPassword')
    const fieldConfirmPassword = document.getElementById('inputConfirmPassword')
    const newPassword = fieldNewPassword.value
    const confirmPassword = fieldConfirmPassword.value
    let hasError = false

    if (!comparePasswords(newPassword, confirmPassword)) {
      $(fieldNewPassword).addClass('is-invalid')
      $(fieldConfirmPassword).addClass('is-invalid')
      toastError(_.upperFirst(getTextForPage('error.noMatchingPassword')))
      hasError = true
    } else {
      $(fieldNewPassword).removeClass('is-invalid')
      $(fieldConfirmPassword).removeClass('is-invalid')
    }

    if (!isValidPassword(newPassword)) {
      $(fieldNewPassword).addClass('is-invalid')
      toastError(_.upperFirst(getTextForPage('error.passwordNotConformToTheRule')))
      hasError = true
    } else {
      $(fieldNewPassword).removeClass('is-invalid')
    }

    if (hasError) {
      return false
    }
    return true
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const buttonStep1 = _.get(event.target, 'submitStep1', false)
    const buttonStep2 = _.get(event.target, 'submitStep2', false)

    if (event.target.checkValidity() && buttonStep1) {
      //HANDLE STEP1
      if (!validateStep1()) {
        return false
      }
      setCurrentStep(currentStep + 1)
    } else if (event.target.checkValidity() && buttonStep2) {
      //HANDLE STEP2
      const data = JSON.parse(localStorage.getItem('register'))
      const resultRegister = await register(data)
      if (_.has(resultRegister, '_message')) {
        if (_.get(resultRegister, '_message')) {
          setCurrentStep('success')
        }
      }
      const resultStatus = _.get(resultRegister, '_status', false)
      if (resultStatus) {
        switch (resultStatus) {
          case 400:
            toastError(_.upperFirst(getTextForPage('error.'.concat(_.camelCase(_.get(resultRegister, '_detail'))), { mailContact: process.env.REACT_APP_CONTACT_MAIL })))
            break
          default:
            return false
        }
      }
    }
  }

  const render = () => {
    switch (currentStep) {
      case 1:
      default:
        return <Step1 multiplesCompaniesByDomain={multiplesCompaniesByDomain}/>
      case 2:
        return <Step2 paramsForm={paramsForms}/>
      case 'success':
        return <ThanksPage/>
    }
  }

  const previousStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const getTitle = () => {
    return isMobile() ? <H1
        className="page-title text-center title mb-5"
        text={_.upperFirst(getTextForPage('title'))}
      /> :
      <TitlePage
        classTitle={'page-title text-center title mb-5'}
        title={_.upperFirst(getTextForPage('title'))}
      />
  }

  return (
    <div className="Register">
      {getTitle()}
      <div className={'container'}>
        {
          currentStep !== 'success' &&
          <div className={'row'}>
            {
              currentStep !== 1 &&
              goBackStep()
            }
            <div className={'col-12'}>
              <H3 text={`${_.upperFirst(getTextForPage('step'))} ${currentStep}/${maxStep}`} className={'page-subtitle-blueAzure400 mb-3'}/>
            </div>
          </div>
        }
        <form className={'form'} onSubmit={handleSubmit}>
          {render()}
          <div className={'row justify-content-center'}>
          </div>
          {
            currentStep === 1 &&
            <div className={'row mb-3'}>
              <div className={'col-12'}>
                <span className={'fs-7'}>{getTextForPage('note1')}</span>
              </div>
              <div className={'col-12'}>
                <span className={'fs-7'}>{getTextForPage('note2')}</span>
              </div>
            </div>
          }
        </form>
      </div>
    </div>
  )
}

export default Register
