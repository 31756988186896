const legalInformation = {
  fr: {
    translation: {
      pages: {
        legalInformation: {
          article1: {
            title: 'Article 1. Objet du service',
            objectOfTheSite: 'Le Site a pour objet la présentation des services proposés par la Société, ainsi que la collecte de données des salariés de l’entreprise cliente, ceci afin de fournir le service tel que défini dans le contrat liant les 2 entreprises.',
            objectOfTheCompany: 'La Société a pour objet la réalisation de prestations de service dans le secteur de la recherche de place en crèche d\'entreprise et l’accès à des services parentalité à destination des employés des entreprises clientes de son offre. Le Site n’a pas vocation à détailler les prix des différentes prestations mais simplement à éclairer l’internaute de leur existence ainsi que des modalités générales pour l’obtention d’une crèche et l’accès aux différents services parentalité.'
          },
          article2: {
            title: 'Article 2. Définitions',
            editor: '“<b>Editeur</b>” : désigne la personne qui met à disposition du public le Site https://hey.lesparentszens.fr, en l’occurrence, M. Tanguy DESANDRE.',
            site: '“<b>Site</b>” : désigne le site Internet appartenant à la Société AMANCE SAS référencé à l’adresse https://hey.lesparentszens.fr/.',
            company: '“<b>Société</b>” : désigne la société AMANCE, société par actions simplifiée.',
            user: '“<b>Utilisateur</b>“ : désigne toute personne qui accède, navigue, consulte ou se rend sur le Site.'
          },
          article3: {
            title: 'Article 3. Utilisation du site',
            accessAndUse: 'L’accès et l’utilisation du Site sont soumis à l’acceptation et au respect des présentes Conditions Générales d’Utilisation. L’Editeur se réserve le droit de modifier, à tout moment et sans préavis, le Site et ses services ainsi que les présentes Conditions, notamment pour s’adapter aux évolutions du Site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes. Il est donc conseillé à l’utilisateur de se référer avant toute navigation à la dernière version des Conditions, accessible à tout moment sur le Site. En cas de désaccord avec les Conditions, aucun usage du Site ne saurait être effectué par l’utilisateur.',
            connectionAndNavigation: 'La connexion et la navigation sur le Site valent acceptation sans réserve des présentes Conditions, quelques soient les moyens techniques d’accès et les terminaux utilisés. Les présentes Conditions s’appliquent, en tant que de besoin, à toute déclinaison ou extension du Site sur les réseaux sociaux et/ou communautaires existantes ou à venir.'
          },
          article4: {
            title: 'Article 4. Gestion du site',
            questionAndInformation: 'Pour toute question ou demande d’information concernant le site, ou tout signalement de contenu ou d’activités illicites, l’utilisateur peut contacter l’Editeur à l’adresse mel suivante : contact@lesparentszens.com ou adresser un courrier recommandé avec accusé de réception à l’adresse suivante : Département marketing et communication, 87 rue la Boétie, 75008 Paris.',
            accessLevel: 'Le site est accessible gratuitement à tous employés de la société cliente disposant d’un accès à Internet. L’Utilisateur est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à Internet.',
            editorSolutions: 'L’éditeur met en œuvre les solutions techniques à sa disposition pour permettre l’accès au site 24 heures sur 24, 7 jours sur 7.',
            suspensionCondition: 'Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l’accès au site ou à certaines pages de celui-ci afin de procéder à des mises à jour, des modifications de son contenu ou tout autre action jugée nécessaire au bon fonctionnement du site. Les actions concernées sont notamment les suivantes :',
            suspension1: 'Suspendre, interrompre ou limiter l’accès à tout ou partie du site, réserver l’accès au site, ou à certaines parties du site, à une catégorie déterminée d’internaute ;',
            suspension2: 'Supprimer toute information pouvant perturber le fonctionnement du Site ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la nétiquette ;',
            suspension3: 'Suspendre le Site – ou l’accès au site – afin de procéder à des mises à jour ou tout mouvement de maintenance.'
          },
          article5: {
            title: 'Article 5. Responsabilité',
            siteAvailabilty: 'Le Site est par principe accessible aux Utilisateurs 24 heures sur 24 et 7 jours sur 7, sauf interruption programmée ou non, pour des besoins de maintenance ou en cas de force majeure. En cas d’impossibilité d’accès au Site, l’Editeur s’engage à faire son maximum afin d’en rétablir l’accès. Le Site et l\'Éditeur ne sauraient être tenus pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité.',
            editorNoResponsibility: {
              text: 'L\'Éditeur n’est responsable que du contenu qu’il a lui-même édité. L\'Éditeur n’est pas responsable :',
              li01: 'En cas de problématiques ou défaillances techniques, informatiques ou de compatibilité du Site avec un matériel ou logiciel quel qu’il soit.',
              li02: 'Des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant de l’utilisation ou des difficultés d’utilisation du Site ou de ses services.',
              li03: 'Des caractéristiques intrinsèques de l’Internet, notamment celles relatives au manque de fiabilité et au défaut de sécurisation des informations y circulant.',
              li04: 'Des contenus ou activités illicites utilisant son Site et ce, sans qu’il en ait pris dûment connaissance au sens de la Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique et la Loi n°2004-801 du 6 août 2004 relative à la protection des personnes physiques à l’égard du traitement de données à caractère personnel.'
            },
            noGuarantee: 'Par ailleurs, le Site ne saurait garantir l’exactitude, la complétude, et l’actualité des informations qui y sont diffusées.',
            userResponsibility: {
              text: 'L’Utilisateur est responsable :',
              li01: 'De la protection de son matériel et de ses données.',
              li02: 'De la non-divulgation de ses identifiants',
              li03: 'De l’utilisation qu’il fait du Site ou de ses services.',
              li04: 'S’il ne respecte ni la lettre, ni l’esprit des présentes CGU.'
            },
            contentReporting: 'Pour tout signalement de contenus ou d’activités illicites, l’Utilisateur peut contacter l’Éditeur à l’adresse suivante : contact@lesparentszens.com, ou par lettre recommandée avec accusé de réception adressé à l’Éditeur : Département marketing et communication, 87 rue la Boétie, 75008 Paris.'
          },
          article6: {
            title: 'Article 6. Force majeure',
            editorResponsibility: 'La responsabilité de l\'Éditeur ne pourra être engagée en présence d’un fait de force majeure ou indépendant de sa volonté.'
          },
          article7: {
            title: 'Article 7. Gestion des données à caractère personnel',
            collectedDataObject: {
              text: 'Les données collectées le sont uniquement par la volonté de l’internaute (opt-in) qui en remplissant le formulaire de création d’un compte et enregistre ses données. Ce formulaire recueille des informations à caractère personnel de différents types (liste non-exhaustive) : ',
              li01: 'Données d’identités (nom, prénom, adresse mel, numéro de téléphone …) ',
              li02: 'Données familiales (parent, grand parent …) ',
              li03: 'Données de connexion et préférences de contenu (date de connexion, durée de la connexion, localisation de la connexion…) '
            },
            collectedDataDestination: 'Les données à caractère personnel qui sont fournies par l’Utilisateur sont, notamment, collectées et traitées dans le but de fournir des places en crèche ou tous autres services complémentaires tels que définis entre la Société et son client.',
            companyCommitment: 'La Société s\'engage à assurer la confidentialité des données à caractère personnel communiquées par l’Utilisateur.',
            userIsInformed: 'L’Utilisateur est informé que la Société peut utiliser les données personnelles le concernant afin de lui adresser des offres commerciales susceptibles de l\'intéresser.',
            collectedDataDeletion: 'Ces données sont supprimées par Les Parents Zens au terme du contrat ; durant la durée d’exécution du contrat elles ne seront partagées qu’avec les crèches partenaires ayant été sélectionnées/validées par l’Utilisateur afin de fournir le service.',
            dataProcessingAndFreedom: 'En application de la loi 78-17 du 6 janvier 1978 dite “Informatique et Libertés” et du Règlement général (UE) n° 2016/679 relatif à la protection des personnes physiques à l\'égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après le « RGPD »), il est rappelé que les données nominatives qui sont demandées à l’Utilisateur qui remplit un formulaire sont nécessaires au traitement de sa demande d\'information.',
            userPermanentRights: 'L’Utilisateur dispose, conformément aux réglementations nationales et européennes en vigueur d\'un droit d\'accès permanent, de modification, de rectification, de portabilité, d\'opposition et d’effacement s\'agissant des informations le concernant.',
            userPermanentRightsEffects: {
              text: 'Ce droit peut être exercé : (au choix parmi formulaire web ou mail)',
              li01: 'par courrier postal : À l’attention du DPO, 87 rue La Boétie, 75008 Paris',
              li02: 'par formulaire web : https://www.lesparentszens.com/nous-contacter/ ',
              li03: 'par email à l’adresse rgpd@lesparentszens.com'
            },
            userInformedAboutReclamation: 'L’Utilisateur est informé du fait qu’il a la possibilité d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés soit, via l’URL suivante https://www.cnil.fr/fr/plaintes ou soit, via courrier postal (3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07).'
          },
          article8: {
            title: 'Article 8. Propriété intellectuelle',
            siteStructuration: 'La structuration du Site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques qui le composent sont la propriété de l’Éditeur et sont protégés comme tels par les dispositions du Code de la propriété intellectuelle.',
            reproductionForbidden: 'Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées et services proposés par le Site, par quelque procédé que ce soit, sans l’autorisation préalable, expresse et écrite de l’Éditeur, est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L335-2 et suivants du Code de la propriété intellectuelle. Exception faite des éléments expressément désignés comme libres de droits sur le Site, et des exceptions légales posés à l’article L122-5 du code de la Propriété Intellectuelle.',
            siteAccessNotConstituteRecognition: 'L’accès au Site ne vaut pas reconnaissance d’un droit et, de manière générale, ne confère aucun droit de propriété intellectuelle relatif à un élément dudit Site, lesquels restent la propriété exclusive de l’Éditeur.',
            userForbidden: 'Il est interdit à l’Utilisateur d’introduire sur le Site des données qui modifieraient ou qui seraient susceptibles d’en modifier le contenu ou l’apparence.',
            dontPublishIllegalContent: 'Il est interdit de publier sur le Site des informations à caractère illicite, violent, polémique, pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre.'
          },
          article9: {
            title: 'Article 9. Droit applicable et juridiction compétente',
            siteSubjectProvisionsOfLaw: 'Le présent Site est soumis aux dispositions du droit français. Toute survenance d’un litige non réglé par un accord à l’amiable ou une procédure de conciliation se verra porté devant les tribunaux compétents du ressort de la Cour d’Appel de Paris.'
          },
          article10: {
            title: 'Article 10. Utilisation et gestion des cookies',
            whatIsACookie: 'Qu\'est-ce qu\'un cookie ?',
            cookieWhat: 'Pour assurer le bon fonctionnement de ce site, nous devons parfois enregistrer de petits fichiers de données sur l’équipement de nos utilisateurs. La plupart des grands sites web font de même.',
            cookieDetails: 'Un cookie est un petit fichier texte que les sites web sauvegardent sur votre ordinateur ou appareil mobile lorsque vous les consultez. Il permet à ces sites de mémoriser vos actions et préférences (nom d’utilisateur, langue, taille des caractères et autres paramètres d’affichage) pendant un temps donné, pour que vous n’ayez pas à réindiquer ces informations à chaque fois vous consultez ces sites ou naviguez d’une page à une autre.',
            howWeUseThem: 'Comment utilisons-nous les cookies ?',
            howWeUseThemDetails: {
              text: 'Comment utilisons-nous les cookies ?',
              li01: 'vos préférences d’affichage, comme le contraste et la taille des caractères,',
              li02: 'si vous avez accepté que nous utilisions des cookies sur ce site,',
              li03: 'votre navigation sur notre site à des fins d’amélioration de l’expérience de visite sur notre site via le cookie Analytics de Google Analytics décrit ci-après.'
            },
            notMandatoryBut: 'Ces cookies ne sont pas indispensables au fonctionnement du site, mais ils facilitent votre navigation. Vous pouvez les bloquer ou les supprimer, mais vous risquez alors d’empêcher le bon fonctionnement de certains éléments du site.',
            dataNotIdentify: 'Les informations contenues dans les cookies ne visent pas à vous identifier personnellement et nous en contrôlons pleinement les données. Ces cookies ne sont jamais utilisés à d’autres fins que celles indiquées ici.',
            googleAnalytics: 'Google Analytics est un outil Google d’analyse d’audience Internet permettant aux propriétaires de sites Web et d’applications de mieux comprendre le comportement de leurs utilisateurs. Cet outil peut utiliser des cookies pour collecter des informations et générer des rapports sur les statistiques d’utilisation d’un site Web sans que les utilisateurs individuels soient identifiés personnellement par Google. Le cookie “__ga” est celui qui est le plus utilisé par Google Analytics.',
            googleAnlyticsWhy: 'Nous l’utilisons pour établir des rapports statistiques d’utilisation de notre site web et non pour vous proposer des publicités plus pertinentes sur les services Googles (comme Google Search).'
          },
          introduction: {
            lastUpdateDate: 'dernière mise à jour',
            directorOfPublication: {
              title: 'directeur de publication de hey.lesparentszens.fr',
              value: 'Tanguy Desandre, Président de AMANCE Les Parents Zens.'
            },
            dataCollected: 'les informations recueillies font l’objet d’un traitement informatique destiné à proposer des services souscrits par l’entreprise cliente pour ses collaborateurs.',
            dataRecipients: 'le destinataire des données est la société AMANCE Les Parents Zens.',
            dataProcessingAndFreedom: 'conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à',
            companyName: 'AMANCE Les Parents Zens',
            companyWebsite: 'lesparentszens.com',
            companyStreet: '87 rue la Boétie',
            zipCodeCity: '75008 Paris',
            oppositionToDataProcessing: 'vous pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.',
            websiteInformation: 'le Site Internet https://www.lesparentszens.com est un outil d’information mis à disposition des collaborateurs de l’entreprise cliente par la société AMANCE SAS au capital de 10000 € dont le siège social est situé 87 rue La Boétie, 75008 Paris, immatriculée au Registre du commerce et des sociétés de Paris au n°(ci-après la “Société”). Les présentes conditions générales d’utilisation (ci-après les “Conditions”) constituent le contrat par lequel la Société met à disposition des internautes, sur son Site, des informations concernant les prestations de service qu’elle réalise dans son domaine d’activité.'
          },
          title: 'mentions légales'
        }
      }
    }
  }
}

export { legalInformation }
