import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../../Tools/translations'
import useFunctionsTools from '../../../../../utils/useFunctionsTools'
import Button from '../../../../Common/Button/Button'
import Filter from '../../../../Common/Filter/Filter'
import useArticlesTools from '../../../../Hooks/useArticlesTools'
import GroupCards from '../BlockCard/components/GroupCards/GroupCards'

function FirstPanel (props) {
  const { category } = useParams() || null
  const { chunking } = useFunctionsTools()
  const { fetchListOfArticles, fetchListOfFilterArticles, getAllArticles, getAllFiltersArticles, getNoMoreElementsArticles } = useArticlesTools()
  const { getTextForPage, getTextInSpecificLanguage } = useMpecTranslations('blocParentsLife')
  const [currentFilters, setCurrentFilters] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [options, setOptions] = useState(
    {
      currentLimit: 0,
      limit: 12
    })
  const history = useHistory()

  useEffect(() => {
    setOptions(
      {
        currentLimit: 0,
        limit: 12
      })
  }, [currentFilters])

  useEffect(() => {
    const categoryTranslate = getTextInSpecificLanguage('en', 'paramsUrl', category)

    fetchListOfFilterArticles(categoryTranslate).then().then()
    fetchListOfArticles(categoryTranslate, currentFilters, options).then(() => {
      setIsLoad(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const addFilterOrRemove = (filter) => {
    if (currentFilters.includes(filter)) {
      const newCurrentFilters = currentFilters.filter(selectedFilter => selectedFilter !== filter)
      setCurrentFilters(newCurrentFilters)
    } else {
      setCurrentFilters([...currentFilters, filter])
    }
  }

  const displayCards = () => {
    const articles = getAllArticles()

    if (!_.isEmpty(articles) && isLoad) {
      const cardsGroup = chunking(articles, [4, 5])
      const allCards = transformCards(cardsGroup)
      return _.map(allCards, (element, index) => {
        if (index % 2 === 0) {
          return <GroupCards
            key={uuidv4()}
            {...element}
            isRoute={true}
            category={category}
            redirect={redirect.bind(this)}
          />
        } else {
          return <GroupCards
            key={uuidv4()}
            {...element}
            isFourSmall={true}
            isRoute={true}
            category={category}
            redirect={redirect.bind(this)}
          />
        }
      })
    } else {
      return <p className={'text-center p-3 mt-4'}>{getTextForPage('noContent')}</p>
    }
  }

  const getObjectForCard = (groupArticle) => {
    const objectCard = {}
    _.forEach(groupArticle, (article, index) => {
      objectCard['allTexts' + (index + 1)] = { text: article?.title, className: 'text-center m-0' }
      objectCard['imgBackground' + (index + 1)] = article?.img
    })

    return objectCard
  }

  const handleButtonFunction = () => {
    setOptions(oldOptions => ({ ...oldOptions, currentLimit: oldOptions.currentLimit + oldOptions.limit }))
  }

  const redirect = (route) => {
    history.push(route)
  }

  const transformCards = (articleChunk) => {
    const result = []
    _.forEach(articleChunk, element => {
      result.push(getObjectForCard(element))
    })

    return result
  }

  return (
    <div className="tab-pane fade show active" id="nav-infos" role="tabpanel" aria-labelledby="nav-infos-tab">
      <Filter
        addFilterOrRemove={addFilterOrRemove}
        allFilters={getAllFiltersArticles()}
      />
      {displayCards()}
      {!getNoMoreElementsArticles() &&
        <Button
          buttonFunction={handleButtonFunction}
          classSup={'btnGreen'}
          isButtonWithAction={true}
          title={getTextForPage('moreArticles')}
        />
      }
    </div>
  )
}

export default FirstPanel
