import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import useMpecTranslations from '../../../Tools/translations'
import useDownloadTools from '../../Hooks/useDownloadTools'
import ActivityCard from './partials/ActivityCard/ActivityCard'
import BasicCard from './partials/BasicCard/BasicCard'

function Card (props) {
  const { getRouteFor } = useMpecTranslations()
  const { download } = useDownloadTools()
  const [allTexts] = useState(props.allTexts)
  const [buttonText] = useState(props.buttonText || null)
  const [className] = useState(props.className || '')
  const [classSup] = useState(props.classSup || '')
  const [file] = useState(props.file || '')
  const [fromRoute] = useState(props.fromRoute || false)
  const [hasLike] = useState(props.hasLike || false)
  const [imgBackground] = useState(props.imgBackground)
  const [isActivity] = useState(props.isActivity || false)
  const [isDownload] = useState(props.isDownload)
  const [isRoute] = useState(props.isRoute !== false)
  const [link] = useState(props.link || false)
  const [route] = useState(props.route || '')
  const [routeParam] = useState(props.routeParam || {})
  const [space] = useState(props.space || 'smallSpace')
  const [type] = useState(props.type)
  const redirect = props.redirect

  const getOnClick = (event) => {
    event.preventDefault()
    if (isRoute && route !== '') {
      redirect(getRouteFor(route, routeParam, fromRoute))
    } else if (isDownload) {
      const data = {
        fileToGet: file,
        category: 'parentsLife',
        subcategory: 'colorings'
      }
      download(data).then()
    } else {
      return false
    }
  }

  const getIsButtonWithAction = () => {
    return link === false
  }

  const displayCard = () => {
    if (isActivity) {
      return <ActivityCard
        displayTexts={displayTexts.bind(this)}
        hasLike={hasLike}
        buttonText={buttonText}
        getOnClick={getOnClick.bind(this)}
        imgBackground={imgBackground}
        type={type}
        link={link}
        isButtonWithAction={getIsButtonWithAction()}
      />
    }

    return <BasicCard
      className={className}
      classSup={classSup}
      displayTexts={displayTexts.bind(this)}
      getOnClick={getOnClick.bind(this)}
      imgBackground={imgBackground}
      space={space}
    />
  }

  const displayTexts = () => {
    if (_.isPlainObject(allTexts)) {
      if (!_.isEmpty(allTexts.description)) {
        return (<div>
            <p className={allTexts.className + ' title'}>{allTexts.text}</p>
            <p className={allTexts.classNameDescription + ' mb-0'}>{allTexts.description}</p>
          </div>
        )
      } else {
        return <p className={allTexts.className + ' title'}>{allTexts.text}</p>
      }
    }
    return allTexts.map((value, index) => {
      if (!_.isEmpty(value.description)) {
        return <div key={index}>
          <p className={value.className + ' title'}>{value.text}</p>
          <p className={value.classNameDescription + ' mb-0'}>{value.description}</p>
        </div>
      } else {
        return <p key={index} className={value.className + ' title'}>{value.text}</p>
      }
    })
  }

  return (
    displayCard()
  )
}

Card.propTypes = {
  allTexts: PropTypes.oneOfType(
    [
      PropTypes.array,
      PropTypes.object
    ]),
  buttonText: PropTypes.string,
  className: PropTypes.string,
  classSup: PropTypes.string,
  file: PropTypes.string,
  fromRoute: PropTypes.bool,
  hasLike: PropTypes.bool,
  imgBackground: PropTypes.string,
  isActivity: PropTypes.bool,
  isDownload: PropTypes.bool,
  isRoute: PropTypes.bool,
  redirect: PropTypes.func,
  route: PropTypes.string,
  routeParam: PropTypes.object,
  space: PropTypes.string
}

export default Card
