import PropTypes from 'prop-types'
import React, { useState } from 'react'
import '../TextImgCard/TextImgCard.scss'

function TextImgCard (props) {
  const [content] = useState(props.content)
  const [img] = useState(props.img)
  const [classNameContent] = useState(props.classNameContent || 'mb-lg-4 para-texts col-sm-12 col-md-12 col-lg-7')
  const [classNameDivImg] = useState(props.classNameDivImg || 'mb-4 p-0 img-card col-sm-12 col-md-12 col-lg-5')
  const [classNameImg] = useState(props.classNameImg ||'')
  const [classNameItcRow] = useState(props.classNameItcRow || 'ImgTextCard row' )
  const [classNameBlockText] = useState(props.classNameBlockText || 'blocktext')
  const [classParentDiv] = useState(props.classParentDiv || '')

  return (
    <div className={`${classNameItcRow} ${classParentDiv}`}>
      <div className={classNameContent}>
        <div className={classNameBlockText}>
          {content}
        </div>
      </div>
      <div className={classNameDivImg}>
        <img
          alt={''}
          src={img}
          className={classNameImg}
        />
      </div>
    </div>
  )
}

TextImgCard.propTypes = {
  classNameBlockText: PropTypes.any,
  classNameContent: PropTypes.any,
  classNameDivImg: PropTypes.any,
  classNameImg: PropTypes.any,
  classNameItcRow: PropTypes.any,
  classParentDiv: PropTypes.any,
  content: PropTypes.any,
  img: PropTypes.any
}

export default TextImgCard
