import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'
import Button from '../../../Button/Button'
import './ActivityCard.scss'

function ActivityCard (props) {
  const { getTextButton } = useMpecTranslations()
  const [buttonText] = useState(props.buttonText || null)
  const [hasLike] = useState(props.hasLike || false)
  const [imgBackground] = useState(props.imgBackground)
  const [isButtonWithAction, setIsButtonWithAction] = useState(_.get(props, 'isButtonWithAction', true))
  const [link, setLink] = useState(props.link)
  const [type] = useState(props.type)
  const displayTexts = props.displayTexts
  const getOnClick = props.getOnClick

  useEffect(() => {
    if (props.isButtonWithAction) {
      setIsButtonWithAction(props.isButtonWithAction)
    }

    if(props.link) {
      setLink(props.link)
    }
  }, [props])

  const displayLike = () => {
    if (hasLike) {
      return <FontAwesomeIcon size={'2x'} icon={faHeart} className={'nav-icon heart float-right p-1'}/>
    }
  }

  const getImgBackground = () => {
    return type === 'coloring' ? 'coloriage' : imgBackground
  }

  const getTextButtonCard = () => {
    if (!_.isNull(buttonText)) {
      return _.toUpper(getTextButton(buttonText))
    }

    switch (type) {
      case 'coloring':
        return _.toUpper(getTextButton('downloadColoring'))
      case 'activity':
        return _.toUpper(getTextButton('seeActivity'))
      default:
        return _.toUpper(getTextButton('seeActivity'))
    }
  }

  return (
    <div className="ActivityCard col-lg-4">
      <div className={`card`}>
        <div className={' picture imgHeight ' + getImgBackground()}></div>
        <div className="p-3">
          <div className="textHeight text-center">
            {displayTexts()}
          </div>
          {displayLike()}
          <Button
            link={link}
            target={'_blank'}
            title={getTextButtonCard()}
            className={'btn p-3 mt-3 mb-4'}
            isButtonWithAction={isButtonWithAction}
            buttonFunction={getOnClick.bind(this)}
          />
        </div>
      </div>
    </div>
  )
}

ActivityCard.propTypes = {
  buttonText: PropTypes.string,
  displayTexts: PropTypes.func,
  getOnClick: PropTypes.func,
  hasLike: PropTypes.bool,
  imgBackground: PropTypes.string,
  isButtonWithAction: PropTypes.bool,
  link: PropTypes.string,
  type: PropTypes.string
}
export default ActivityCard
