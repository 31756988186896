const register = {
  fr: {
    translation: {
      pages: {
        register: {
          buttonStep2: 'passer à l\'étape 2',
          contentType: 'type de contenu',
          iAm: 'je suis',
          information: 'mes informations',
          interest: 'mes centres d\'intérêt',
          messageThanksPage: 'Votre compte a bien été créé. Afin de valider votre adresse e-mail, vous allez recevoir un e-mail de notre part à l\'adresse {{login}}. Vous trouverez un lien pour activer votre compte.\nSi vous ne l\'avez pas reçu, pensez à vérifier dans vos spams avant de contacter l\'équipe maplaceencrèche à contact@maplaceencreche.com.',
          note1: '* Les champs marqués d\'une étoile sont obligatoires',
          note2: '** Votre mot de passe doit comprendre une majuscule, une minuscule et un chiffre',
          previousStep: 'revenir à l\'étape',
          role: 'mon rôle',
          step: 'etape',
          title: 'c\'est ma première inscription',
          step1: {
            subTitle: 'Merci de renseigner les champs suivants'
          },
          step2: {
            accompanyTheChildren: 'accompagner les enfants dans leur apprentissage',
            activities: 'activités',
            adviceAndCoaching: 'conseils et coaching',
            articles: 'articles',
            balanceLife: 'equilibre vie pro/vie perso',
            boostingParentingSkills: 'booster les compétences parentales',
            cgu: 'Ces informations sont nécessaires à la création de votre compte, pour pouvoir vous proposer du contenu adapté. Elles seront conservées tout au long de l\'activité de votre compte et conservées 3 ans après sa suppression. Vous disposez des droits d’opposition, d’accès, de rectification, de limitation et d’effacement, et d’un droit à la portabilité de vos données personnelles, en nous contactant sur notre formulaire de contact.',
            conference: 'conférences et ateliers',
            corporateParenting: 'parentalité et entreprise',
            contentType: 'type de contenu',
            developingSkills: 'développer les compétences relationnelles',
            elevenFifteenYear: '11/15 ans',
            fifteenEighteenYear: '15/18 ans',
            health: 'santé / Bien-être',
            informations: '*Ces informations sont indispensables pour l\'accès à l\'ensemble de vos services',
            interest: 'mes centres d\'intérêt',
            pregnancy: 'grossesse',
            sixElevenYear: '6/11 ans',
            sliceOfLife: 'tranche de vie',
            subTitle: 'Pour pouvoir vous proposer du contenu pertinent, nous avons besoin de ces dernières informations avant de vous donner accès à votre espace personnel',
            threeSixYear: '3/6 ans',
            theme: 'thématique',
            zeroThreeYear: '0/3 ans'
          },
          error: {
            newMustDifferFromActual: 'le nouveau mot de passe ne peut être identique à l\'actuel !!',
            noMatchingPassword: 'le nouveau mot de passe et sa confirmation ne sont pas identiques',
            passwordNotConformToTheRule: 'votre mot de passe doit contenir au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre et au moins 6 caractères de long, merci de corriger votre saisie',
            theGivenActualPasswordIsNotValidPleaseRetryWithAnAnotherPassword: 'le mot de passe actuel n\'est pas celui enregistré dans notre système, merci de vérifier votre saisie',
            itSeemsYouAlreadyHaveAnHeyParentsZensPleaseTryToChangeYourPasswordOrContactUsByMailArYourMailContact: 'il semblerait que vous ayez déjà un compte, essayez de changer votre mot de passe ou contacter nous par mail {{mailContact}}',
            yourCompanyDidNotSubscribeToTheHeyParentsZensServicesPleaseTryWithAnOtherEmailIfYouHaveOne: 'votre société n\'a pas souscris aux services Hey LesParentsZens, si vous avez un autre email professionnel vous pouvez essayer de nouveau ou contacter nous par mail {{mailContact}}'
          }
        }
      }
    }
  }
}

export { register }
