import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { resources } from './translation'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      lng: 'fr',
      fallbackLng: 'fr',
      returnObjects: true,
      debug: false,
      interpolation: {
        escapeValue: false
      }
    })
export default i18n
