import _ from 'lodash'
import React from 'react'
import { Route } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import ProtectedRoute from './Components/Common/Route/ProtectedRoute'
import Activity from './Components/Pages/Activity/Activity'
import Articles from './Components/Pages/Articles/Articles'
import BlocParentsLife from './Components/Pages/BlocParentsLife/BlocParentsLife'
import CoachingParentsZens from './Components/Pages/CoachingParentsZens/CoachingParentsZens'
import CoachingParentsZensPlus from './Components/Pages/CoachingParentsZensPlus/CoachingParentsZensPlus'
import ComplementaryGuard from './Components/Pages/ComplementaryGuard/ComplementaryGuard'
import ComplementaryGuardCera from './Components/Pages/ComplementaryGuardCera/ComplementaryGuardCera'
import ComplementaryGuardO2 from './Components/Pages/ComplementaryGuardO2/ComplementaryGuardO2'
import ConferenceInscription from './Components/Pages/ConferenceInscription/ConferenceInscription'
import ConferencesWorkshops from './Components/Pages/ConferencesWorkshops/ConferencesWorkshops'
import Contact from './Components/Pages/Contact/Contact'
import ContentPage from './Components/Pages/ContentPages/ContentPages'
import Gazouyi from './Components/Pages/Gazouyi/Gazouyi'
import HomePage from './Components/Pages/HomePage/HomePage'
import InspirationalArticles from './Components/Pages/InspirationalArticles/InspirationalArticles'
import LegalInformation from './Components/Pages/LegalInformation/LegalInformation'
import MyJourney from './Components/Pages/MyJourney/MyJourney'
import MyProfile from './Components/Pages/MyProfile/MyProfile'
import NurseryPlace from './Components/Pages/NurseryPlace/NurseryPlace'
import OurSelectedPartners from './Components/Pages/OurSelectedPartners/OurSelectedPartners'
import ParentsLife from './Components/Pages/ParentsLife/ParentsLife'
import Register from './Components/Pages/Register/Register'
import ReplayConference from './Components/Pages/Replay/ReplayConference/ReplayConference'
import ReplayWorkshop from './Components/Pages/Replay/ReplayWorkshop/ReplayWorkshop'
import ReturnMaternityPaternityLeave from './Components/Pages/ReturnMaternityPaternityLeave/ReturnMaternityPaternityLeave'
import SearchResults from './Components/Pages/SearchResults/SearchResults'
import WorkshopInscription from './Components/Pages/WorkshopInscription/WorkshopInscription'
import i18n from './i18n'

const allRoutes = [
  {
    routeName: 'activity',
    component: Activity
  },
  {
    routeName: 'articles',
    component: Articles
  },
  {
    routeName: 'coachingParentsZens',
    component: CoachingParentsZens,
    isProtected: true,
    serviceKey: 'coaching'
  },
  {
    routeName: 'coachingParentsZensPlus',
    component: CoachingParentsZensPlus
  },
  {
    routeName: 'complementaryGuard',
    component: ComplementaryGuard,
    isProtected: true,
    serviceKey: 'complementaryGuard'
  },
  {
    routeName: 'complementaryGuardCera',
    component: ComplementaryGuardCera,
    isProtected: true,
    serviceKey: 'complementaryGuardCera'
  },
  {
    routeName: 'complementaryGuardO2',
    component: ComplementaryGuardO2,
    isProtected: true,
    serviceKey: 'complementaryGuardO2'
  },
  {
    routeName: 'conferenceInscription',
    component: ConferenceInscription,
    isProtected: true,
    serviceKey: 'conferencesAndWorkshops'
  },
  {
    routeName: 'conferencesWorkshops',
    component: ConferencesWorkshops,
    isProtected: true,
    serviceKey: 'conferencesAndWorkshops'
  },
  {
    routeName: 'contact',
    component: Contact
  },
  {
    routeName: 'gazouyi',
    component: Gazouyi
  },
  {
    routeName: 'homepage',
    component: HomePage
  },
  {
    routeName: 'inspirationalArticles',
    component: InspirationalArticles
  },
  {
    routeName: 'inspirationalArticlesView',
    component: Articles
  },
  {
    routeName: 'legalInformation',
    component: LegalInformation
  },
  {
    routeName: 'maxicours',
    component: ContentPage
  },
  {
    routeName: 'myFamiliz',
    component: ContentPage
  },
  {
    routeName: 'myJourney',
    component: MyJourney
  },
  {
    routeName: 'myProfile',
    component: MyProfile
  },
  {
    routeName: 'nurseryPlace',
    component: NurseryPlace,
    isProtected: true,
    serviceKey: 'nurseryPlaces'
  },
  {
    routeName: 'ourSelectedPartners',
    component: OurSelectedPartners,
    isProtected: true,
    serviceKey: 'ourSelectedPartners'
  },
  {
    routeName: 'parentsLife',
    component: ParentsLife
  },
  {
    routeName: 'parentsLifeCategory',
    component: BlocParentsLife
  },
  {
    routeName: 'register',
    component: Register
  },
  {
    routeName: 'replayConference',
    component: ReplayConference,
    isProtected: true,
    serviceKey: 'conferencesAndWorkshops'
  },
  {
    routeName: 'replayWorkshop',
    component: ReplayWorkshop,
    isProtected: true,
    serviceKey: 'conferencesAndWorkshops'
  },
  {
    routeName: 'returnMaternityPaternityLeave',
    component: ReturnMaternityPaternityLeave,
    isProtected: true,
    serviceKey: 'zensReturnsFromMaternityAndPaternityLeave'
  },
  {
    routeName: 'searchResults',
    component: SearchResults
  },
  {
    routeName: 'workshopInscription',
    component: WorkshopInscription,
    isProtected: true,
    serviceKey: 'conferencesAndWorkshops'
  }
]

function getAllRoutes () {
  const routes = []
  const tmpTranslator = i18n.getFixedT('fr')

  allRoutes.map(async (value, index) => {
    const routeName = `routes.${value.routeName}`
    let routePath = `/${tmpTranslator(routeName)}`
    if (value.path) {
      routePath = value.path
    }

    let tmpRoute = null
    if (_.get(value, 'isProtected', false)) {
      tmpRoute =
        <ProtectedRoute
          key={uuidv4()}
          path={routePath}
          exact={true}
          component={value.component}
          serviceKey={value.serviceKey}
        />
    } else {
      tmpRoute =
        <Route
          key={uuidv4()}
          path={routePath}
          exact={true}
          component={value.component}
        />
    }

    routes.push(tmpRoute)
    return true
  })

  return routes
}

export default getAllRoutes
