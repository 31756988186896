import Axios from 'axios'
import _ from 'lodash'

function useMailerTools () {
  const getEmailData = (templateId, userData, variables, subject, emailToData) => {
    const emailFrom = process.env.REACT_APP_CONTACT_MAIL
    const emailTo = emailToData ? emailToData : userData.email
    const nameFrom = process.env.REACT_APP_CONTACT_NAME
    const nameTo = `${_.upperFirst(userData?.lastname)} ${_.upperFirst(userData?.firstname)}`

    return {
      emailFrom,
      emailTo,
      nameFrom,
      nameTo,
      subject,
      templateId,
      variables
    }
  }

  const getEmailDataForLastPlace = (templateId, userData, companyName, title, subject, contactInformations) => {
    const variables = {
      'Nomclient': companyName,
      'Nomservice': title
    }
    return getEmailData(templateId, userData, variables, subject, contactInformations)
  }

  const handleEmailTo = (contacts) => {
    return contacts.map((contact) => {
      return {
        Email: contact.email,
        Name: `${contact.lastname} ${contact.firstname}`
      }
    })
  }

  const sendEmailForEvent = async (emailData) => {
    const source = Axios.CancelToken.source()
    try {
      await Axios.post('/api/mpecMailer', emailData, {
        cancelToken: source.token
      })
      return true
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const sendEmailForLastPlaceRemaining = async (userData, companyName, title, subject, contactInformations) => {
    const emailData = getEmailDataForLastPlace(3194170, userData, companyName, title, subject, contactInformations)
    await sendEmailForEvent(emailData)
  }

  return { getEmailData, handleEmailTo, sendEmailForEvent, sendEmailForLastPlaceRemaining }
}

export default useMailerTools
