import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../../Tools/translations'

function LegalInformationArticle04 () {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'container article'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <h3 className={'col-12'}>{getTextForPage('article4.title')}</h3>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article4.questionAndInformation'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article4.accessLevel'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article4.editorSolutions'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{ReactHtmlParser(getTextForPage('article4.suspensionCondition'))}</p>
        </div>
        <ul className={''}>
          <li>{getTextForPage('article4.suspension1')}</li>
          <li>{getTextForPage('article4.suspension2')}</li>
          <li>{getTextForPage('article4.suspension3')}</li>
        </ul>
      </div>
    </div>
  )
}

export default LegalInformationArticle04
