import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { useState } from 'react'
import useMpecTranslations from '../../../Tools/translations'
import useNavigationTools from '../../Hooks/useNavigationTools'
import './Search.scss'

function Search () {
  const { getRouteFor, getTextForHeader } = useMpecTranslations()
  const { redirectToRoute } = useNavigationTools()
  const [searchInput, setSearchInput] = useState()

  const redirectToSearchPage = (event) => {
    event.preventDefault()
    redirectToRoute('/' + getRouteFor('searchResults'), { searchInput })
  }

  return (
    <form className="Search form-inline ml-3" onSubmit={redirectToSearchPage}>
      <div className="input-group input-group-sm">
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <FontAwesomeIcon icon={faSearch}/>
          </button>
        </div>
        <input
          aria-label="Search"
          className="form-control"
          onChange={event => setSearchInput(event.target.value)}
          placeholder={_.upperFirst(getTextForHeader('search'))}
          type="search"
        />
      </div>
    </form>
  )
}

export default Search