import { faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Play (props) {
  const { handleClick } = props

  return (
    <button className="player__button play" onClick={() => handleClick()}>
      <FontAwesomeIcon icon={faPause} className={'nav-icon'}/>
    </button>
  )
}
