import React from 'react'
import TagManager from 'react-gtm-module'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import Footer from './Components/Common/Footer/Footer'
import Header from './Components/Common/Header/Header'
import Login from './Components/Pages/Login/Login'
import './font.scss'
import getAllRoutes from './routes'
import useMpecSecure from './Tools/secure'

const tagManagerArgs = {
  gtmId: 'GTM-' + process.env.REACT_APP_GTM_CONTAINER,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
}
TagManager.initialize(tagManagerArgs)

function App () {
  const { token, setMyToken } = useMpecSecure()

  if (!token) {
    return <Login setToken={setMyToken}/>
  }

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Header/>
        <Switch>
          {getAllRoutes()}
        </Switch>
      </BrowserRouter>
      <Footer/>
      <ToastContainer/>
    </div>
  )
}

export default App
