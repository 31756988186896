import { faHeart as faHeartEmpty } from '@fortawesome/free-regular-svg-icons'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import useLikeTools from '../../Hooks/useLikeTools'
import './Like.scss'

function Like (props) {
  const { sendLike, fetchLikesByIdUser, isPageLike, likes } = useLikeTools()
  const { getTextButton } = useMpecTranslations('')
  const [isLoad, setIsLoad] = useState(false)
  const [isLiked, setIsLiked] = useState(false)
  const [page] = useState(props.page)
  const [type] = useState(props.type)
  const { getUserData } = useMpecSecure()

  useEffect(() => {
    fetchLikesByIdUser(getUserData().idUser).then(() => {
      setIsLoad(true)
    })
    if (!_.isEmpty(likes)) {
      setIsLiked(isPageLike(likes, page, type))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad])

  const displayLike = () => {
    return (!isLiked)
      ? <FontAwesomeIcon icon={faHeartEmpty} className={'nav-icon'}/>
      : <FontAwesomeIcon icon={faHeart} className={'nav-icon'}/>
  }

  const handleClick = async (event) => {
    event.preventDefault()
    if (!isLiked) {
      setIsLiked(true)
      await sendLike(true, getUserData().idUser, type, page)
    } else {
      setIsLiked(false)
      await sendLike(false, getUserData().idUser, type, page)
    }
  }

  return (
    <div className="heart d-flex flex-column" onClick={(event => handleClick(event))}>
      {displayLike()}
      <span className="like text-center">{getTextButton('like')}</span>
    </div>
  )
}

Like.propTypes = {
  page: PropTypes.string,
  type: PropTypes.string
}

export default Like
