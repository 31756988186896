import Axios from 'axios'
import _ from 'lodash'
import { useState } from 'react'
import useMpecSecure from '../../Tools/secure'

function useAccountsTools () {
  const [multiplesCompaniesByDomain, setMultiplesCompaniesByDomain] = useState({})
  const [myProfile, setMyProfile] = useState({})
  const [paramsForms, setParamsForms] = useState({})
  const { token } = useMpecSecure()

  const activateMyAccount = async (activationCode) => {
    const source = Axios.CancelToken.source()
    const axiosConfig = {
      cancelToken: source.token
    }
    const activation = await Axios.post(
      '/api/account/activate',
      {
        code: activationCode
      },
      axiosConfig
    )

    return activation.data
  }

  const changeMyPassword = async (actualPassword, newPassword, confirmationPassword) => {
    const source = Axios.CancelToken.source()
    const axiosConfig = {
      headers: { 'front-sessionid': token },
      cancelToken: source.token
    }
    const myProfile = await Axios.post(
      '/api/account/update-password',
      {
        actualPassword: Buffer.from(actualPassword).toString('base64'),
        newPassword: Buffer.from(newPassword).toString('base64'),
        confirmationPassword: Buffer.from(newPassword).toString('base64')
      },
      axiosConfig
    )

    return myProfile.data
  }

  const getMyProfile = async () => {
    console.log('get my profile')
    const source = Axios.CancelToken.source()
    const axiosConfig = {
      headers: { 'front-sessionid': token },
      cancelToken: source.token
    }
    const myProfile = await Axios.post('/api/account/my-profile', {}, axiosConfig)
    setMyProfile(myProfile.data.myProfile)
    return myProfile.data.myProfile
  }

  const getParamsForForms = async (form = 'forms') => {
    try {
      const source = Axios.CancelToken.source()
      const axiosConfig = {
        headers: { 'front-sessionid': token },
        cancelToken: source.token
      }
      let path
      switch (form) {
        case 'register':
          path = 'params-for-register'
          break
        default:
          path = 'params-for-forms'
          break
      }

      const paramsForForms = await Axios.post(`/api/account/${path}`, {}, axiosConfig)
      const params = {}
      _.set(params, 'genders', _.get(paramsForForms, 'data.params._genders', []))
      _.set(params, 'roles', _.get(paramsForForms, 'data.params._roles', []))
      _.set(params, 'contentTypes', _.get(paramsForForms, 'data.params._contentTypes', []))
      _.set(params, 'slicesOfLife', _.get(paramsForForms, 'data.params._sliceOfLife', []))
      _.set(params, 'thematics', _.get(paramsForForms, 'data.params._thematics', []))
      setParamsForms(params)

      const multiple = _.get(paramsForForms, 'data.params._sameCompaniesWithDomain')
      if (!_.isEmpty(_.keys(multiple))) {
        setMultiplesCompaniesByDomain(multiple)
      }

    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const register = async (data) => {
    const source = Axios.CancelToken.source()
    const axiosConfig = {
      cancelToken: source.token
    }
    const registerResult = await Axios.post(
      '/api/account/register',
      data,
      axiosConfig
    )

    return registerResult.data
  }

  const saveMyProfile = async (data) => {
    const source = Axios.CancelToken.source()
    const axiosConfig = {
      headers: { 'front-sessionid': token },
      cancelToken: source.token
    }
    await Axios.post('/api/account/my-profile-save', { data }, axiosConfig)
    await getMyProfile()
    // checkException
    return true
  }

  return {
    activateMyAccount,
    changeMyPassword,
    getMyProfile,
    getParamsForForms,
    multiplesCompaniesByDomain,
    myProfile,
    paramsForms,
    register,
    saveMyProfile
  }
}

export default useAccountsTools
