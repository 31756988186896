const gazouyi = {
  fr: {
    translation: {
      pages: {
        gazouyi: {
          acceptData: 'J\'accepte que mes données soient transmises à Gazouyi pour la création de mon compte',
          contentText1: 'Réalisez des activités en familles avec Gazouyi, une application issue du Laboratoire de Sciences Cognitives et Psycholinguistique de l\'Ecole Normale Supérieure et soutenue par le CNRS.',
          contentText2: 'Ces activités sont associées aux différentes étapes de développement de chaque enfant entre 0 et 5 ans. Pour chacune d\'entre elles, un contexte scientifique explique l\'étape de la vie de votre enfant avec des concepts accessibles à tous et des outils permettant de suivre l\'éveil de votre enfant au quotidien.'  ,
          content2Text1: 'Accès à l\'abonnement premium offert par votre employeur.',
          content2Text2: 'Un espace d\'échange privé et sécurisé avec des experts 7 jours sur 7.',
          content2Text3: 'Plus de 300 activités simples et ludique à faire en famille et adaptées au développement individuel de l\'enfants de 0 à 5 ans',
          content2Text4: 'Des questions/réponses adaptées aux besoins de chacun et animées par des professionnels de la petite enfance',
          content2Text5: 'Quizz personalisés au développement de votre/vos enfant/s',
          content3Text1: 'Complétez le formulaire ci-dessous avec les coordonnées que vous souhaitez utiliser sur l\'application. Votre compte sera crée automatiquement et vous recevez les liens de téléchargement de l\'application sur l\'email renseigné.',
          formName: 'Mon nom',
          formFirstName: 'Mon prénom',
          formEmail: 'Mon email',
          textServices: "Cochez la case qui autorise le transfert de vos données (email professionnel, Nom, Prénom et Société) à notre partenaire Gazouyi. Votre compte sera crée automatiquement et vous recevrez les liens de téléchargement de l'application sur votre email professionnel d'ici quelques minutes. Pensez à regarder dans vos spams !",
          titleContent2: 'Les avantages',
          titleContent3: 'Pour accéder à ce service',
          titlePage: "Une application mobile d'activités simples et ludiques"
        }
      }
    }
  }
}

export { gazouyi }
