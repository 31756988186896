import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import useMpecTranslations from '../../../../Tools/translations'

function LegalInformationArticle07 () {
  const { getTextForPage } = useMpecTranslations('legalInformation')

  return (
    <div className={'container article'}>
      <div className={'row'}>
        <div className={'col-12'}>
          <h3 className={'col-12'}>{getTextForPage('article7.title')}</h3>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{ReactHtmlParser(getTextForPage('article7.collectedDataObject.text'))}</p>
        </div>
        <ul className={''}>
          <li>{getTextForPage('article7.collectedDataObject.li01')}</li>
          <li>{getTextForPage('article7.collectedDataObject.li02')}</li>
          <li>{getTextForPage('article7.collectedDataObject.li03')}</li>
        </ul>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.collectedDataDestination'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.companyCommitment'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.userIsInformed'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.collectedDataDeletion'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.dataProcessingAndFreedom'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.userPermanentRights'))}</p>
        </div>
        <div className={'col-12'}>
          <p className={'col-12 mb-0'}>{ReactHtmlParser(getTextForPage('article7.userPermanentRightsEffects.text'))}</p>
        </div>
        <ul className={''}>
          <li>{getTextForPage('article7.userPermanentRightsEffects.li01')}</li>
          <li>{getTextForPage('article7.userPermanentRightsEffects.li02')}</li>
          <li>{getTextForPage('article7.userPermanentRightsEffects.li03')}</li>
        </ul>
        <div className={'col-12'}>
          <p className={'col-12'}>{ReactHtmlParser(getTextForPage('article7.userInformedAboutReclamation'))}</p>
        </div>
      </div>
    </div>
  )
}

export default LegalInformationArticle07
