const companiesNames = {
  fr: {
    translation: {
      companiesNames: {
        'CAISSE D\'EPARGNE LANGUEDOC ROUSSILLON': 'Caisse d\'Epargne Languedoc-Roussillon',
        CERA: 'La Caisse d’Epargne Rhône Alpes',
        'CHEUVREUX NOTAIRES': 'Cheuvreux Notaires',
        DNCA: 'DNCA',
        'EIFFAGE CONSTRUCTION CENTRE EST': 'Eiffage Construction Centre Est',
        'EIFFAGE CONSTRUCTION LORRAINE': 'Eiffage Construction Alsace Lorraine',
        'Eiffage Construction Rhône Loire': 'Eiffage Construction Rhône Loire',
        EURAZEO: 'Eurazeo',
        'GREEN STYLE': 'Green Style',
        'GROUPE LEADER': 'Groupe Leader',
        IMPACT: 'Impact Field Marketing Group',
        Leon10: 'Micro-crèche Léon',
        'Les Parents Zens': 'Les Parents Zens',
        LINEUP7: 'LineUp7',
        LOUBOUTIN: 'La Maison Christian Louboutin',
        LUCCA: 'Lucca',
        'LUSTUCRU FRAIS': 'Lustucru Frais',
        'MÖLNLYCKE HEALTH CARE': 'Mölnlycke Health Care',
        Mpec: 'maplaceencrèche',
        MAZARS: 'Mazars',
        'MAZARS SA (Entité Bordeaux)': 'Mazars Bordeaux SA',
        PANDROL: 'PANDROL',
        PANZANI: 'Panzani',
        URBASOLAR: 'Urbasolar',
        'VINCI AIRPORTS': 'VINCI AIRPORTS',
        'VINCI CONCESSIONS': 'VINCI CONCESSIONS',
        'VINCI SA': 'VINCI SA',
        'VINCI CONSTRUCTION FRANCE OUVRAGES FONCTIONNELS NEUFS': 'Vinci Construction France Ouvrages Fonctionnels Neufs',
        WENDEL: 'Wendel',
        'lesparentszens': 'Les Parents Zens'
      }
    }
  }
}

export { companiesNames }
