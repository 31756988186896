import React from 'react'
import Replay from '../Replay'
import './ReplayWorkshop.scss'

function ReplayWorkshop () {
  return (
    <div className={'ReplayWorkshop'}>
      <Replay isWorkshop={true}/>
    </div>
  )
}

export default ReplayWorkshop
