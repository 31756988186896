import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import useMpecSecure from '../../../Tools/secure'
import useMpecTranslations from '../../../Tools/translations'
import TitlePage from '../../Common/TitlePage/TitlePage'
import usePagesTools from '../../Hooks/usePagesTools'
import './NurseryPlace.scss'

function NurseryPlace () {
  const { displayBlocks } = usePagesTools()
  const { getTextForPage } = useMpecTranslations('nurseryPlace')
  const { getUserData } = useMpecSecure()
  const [blocks, setBlocks] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const source = Axios.CancelToken.source()

  useEffect(() => {
    fetchCompanyNurseryPlace().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCompanyNurseryPlace = async () => {
    const userData = getUserData()
    const companyName = _.get(userData, 'companyName', '')
    const name = companyName

    try {
      const result = await Axios.post('/api/companiesNurseriesPlaces/get-data', { name }, {
        cancelToken: source.token
      })
      setBlocks(_.get(result.data, 'blocks', []))
      setIsLoading(true)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  return (
    <div className={'NurseryPlace content-wrapper'}>
      <div className="container">
        <div className="content">
          <TitlePage title={getTextForPage('title')}/>
          <div className="container-fluid">
            {isLoading &&
            displayBlocks(blocks)
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default NurseryPlace
