import _ from 'lodash'
import React from 'react'
import useMpecTranslations from '../../../../Tools/translations'
import useWichTerminal from '../../../Hooks/useWichTerminal'

function Header () {
  const { isMobile } = useWichTerminal()
  const { getTextForPage } = useMpecTranslations('login')

  const displayDesktopHeader = () => {
    return (
      <header className={'login-header p-3 d-flex justify-content-center align-items-center'}>
          <img
            alt="Parentszens"
            className="brand-image mb-2"
            src={process.env.REACT_APP_LINK_IMG_LOGOS + 'logoLpzHorizontal.svg'}
            width={179}
          />
        <p className={'lineVertical mb-0 mr-4 ml-4'}></p>
        <p className={'mb-0 connexion text-bold'}>{_.upperFirst(getTextForPage('header.login'))}</p>
      </header>
    )
  }

  const displayMobileHeader = () => {
    return (
      <header className={'mobile'}>
        <div className={'overflow-hidden'}>
          <div className="big-round-blue"></div>
          <div className="small-round-blue"></div>
          <div className="round-logo m-auto">
            <img
              alt="Parentszens"
              className="brand-image"
              src={process.env.REACT_APP_LINK_IMG_LOGOS + 'logoLpzVertical.svg'}
              width={250}
            />
          </div>
        </div>
      </header>
    )
  }

  return (
    isMobile() ? displayMobileHeader() : displayDesktopHeader()
  )
}

export default Header
