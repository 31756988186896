const ourSelectedPartners = {
  fr: {
    translation: {
      pages: {
        ourSelectedPartners: {
          discover: 'découvrir l\'application',
          title: 'nos partenaires sélectionnés',
          gazouyi: 'gazouyi',
          maxicours: 'maxicours',
          myFamiliz: 'my familiz',
          babysittor: 'baby-sittor',
          workshopFutursParent: 'atelier des futurs parents'
        }
      }
    }
  }
}


export { ourSelectedPartners }