const paramsUrl = {
  en: {
    translation: {
      paramsUrl: {
        '0-3': '0-3',
        '3-6': '3-6',
        '6-11': '6-11',
        '11-15': '11-15',
        '15-18': '15-18',
        grossesse: 'pregnancy',
        pregnancy: 'pregnancy'
      }
    }
  },
  fr: {
    translation: {
      paramsUrl: {
        '0-3': '0-3',
        '3-6': '3-6',
        '6-11': '6-11',
        '11-15': '11-15',
        '15-18': '15-18',
        grossesse: 'grossesse',
        pregnancy: 'grossesse'
      }
    }
  },
}

export { paramsUrl }
