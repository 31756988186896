import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CardsTimeline from '../CardsTimeline/CardsTimeline'

function Block (props) {
  const [title] = useState(props.title)
  const [img] = useState(props.img)
  const [isMobile] = useState(props.isMobile)
  const [isUp] = useState(props.isUp)
  const [route, setRoute] = useState(props.route)

  useEffect(() => {
    if (_.has(props, 'route')) {
      setRoute(props.route)
    }
  }, [props])
  const displayUpBlock = () => {
    return (
      <div className="UpBlock block">
        <CardsTimeline title={title} isMobile={isMobile} img={img} route={route}/>
        <div className={'mt-2 mb-2'}>
          <div className="circle"></div>
          <div className="link"></div>
        </div>
      </div>
    )
  }

  const displayDownBlock = () => {
    return (
      <div className="DownBlock block mb-5">
        <div className="square up blank"></div>
        <div className={'triangle-hidden mb-2'}></div>
        <div className={'mb-3 mt-2'}>
          <div className="circle activated"></div>
          <div className="link"></div>
        </div>
        <div>
          <CardsTimeline isTop={true} title={title} isMobile={isMobile} img={img} route={route}/>
        </div>
      </div>
    )
  }

  return (
    <div>
      {isUp ? displayUpBlock() : displayDownBlock()}
    </div>
  )
}

Block.propTypes = {
  img: PropTypes.string,
  isMobile: PropTypes.bool,
  isUp: PropTypes.bool,
  route: PropTypes.string,
  title: PropTypes.string
}

export default Block