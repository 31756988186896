import { faBan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'

function ActivationError(props) {
  const { getTextForPage } = useMpecTranslations('login')
  const [error] = useState(props.error)
  return (
    <Fragment>
      <div className={'text-center'}>
        <h2>{_.upperFirst(getTextForPage("activation.titleAnErrorOccurred"))}</h2>
      </div>
      <div className={'text-center'}>
        {_.upperFirst(getTextForPage('activation.'.concat(error.message), { mailContact: `${process.env.REACT_APP_CONTACT_MAIL}` }))}
      </div>
      <div className={'text-center'}>
        <FontAwesomeIcon icon={faBan} className={'text-red'} size={'4x'}/>
      </div>
    </Fragment>
  )
}

ActivationError.propTypes = {
  error: PropTypes.object.isRequired
}

export default ActivationError
