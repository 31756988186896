import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './OriginalCard.scss'
import useRoutesTools from '../../Hooks/useRoutesTools'

function OriginalCard (props) {
  const { goToRoute } = useRoutesTools()
  const [description] = useState(props.description)
  const [imgBackground] = useState(props.imgBackground)
  const [route] = useState(props.route || null)
  const [routeParam] = useState(props.routeParam || null)
  const [title] = useState(props.title)

  return (
    <div className={'OriginalCard'} onClick={(
      (event) => {
        goToRoute(event, route, routeParam, true)
      })}
    >
      <div className={'image'}>
        <img src={process.env.REACT_APP_LINK_IMG + imgBackground} alt={imgBackground}/>
      </div>
      <div className="content pt-3">
        <p className={'text-center font-weight-bold mb-0'}>
          {_.upperFirst(title)}
        </p>
        <p className={'text-center'}>
          {_.upperFirst(description)}
        </p>
      </div>
    </div>
  )
}

OriginalCard.propTypes = {
  description: PropTypes.string,
  getOnClick: PropTypes.func,
  imgBackground: PropTypes.string.isRequired,
  route: PropTypes.string,
  routeParam: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default OriginalCard
