import _ from 'lodash'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../Tools/translations'
import Button from '../../../Common/Button/Button'
import H3 from '../../../Common/HX/H3'

function InputInformation () {
  const { getTextForPage, getTextButton } = useMpecTranslations('MyProfile')

  const dataInputs = () => [
    {
      htmlFor: 'inputActualPassword',
      text: 'inputPassword.actualPassword',
      type: 'password',
      id: 'inputActualPassword',
      name: 'currentPassword',
      required: true
    },
    {
      htmlFor: 'inputNewPassword',
      text: 'inputPassword.newPassword',
      type: 'password',
      id: 'inputNewPassword',
      required: true
    },
    {
      htmlFor: 'inputPasswordConfirmed',
      text: 'inputPassword.confirmPassword',
      type: 'password',
      id: 'inputPasswordConfirmed',
      required: true
    }
  ]

  const allInputPassword = () => {
    const data = dataInputs()
    return (
      data.map((value) => {
        return <div className="mb-3 row" key={uuidv4()}>
          <label htmlFor={value.htmlFor} className="col-sm-3 col-form-label">{_.upperFirst(getTextForPage(value.text))}</label>
          <div className="col-sm-7">
            <input
              className="form-control rounded"
              type={value.type}
              id={value.id}
              value={value?.value}
              name={value?.name}
              onChange={value?.handleChange}
              required
            />
          </div>
        </div>
      })
    )
  }

  return (
    <div>
      <div className="mb-2">
        <H3 text={_.upperFirst(getTextForPage('inputPassword.modifyPassword'))} className={'page-subtitle-blueAzure400 mb-3'}/>
      </div>
      {allInputPassword()}
      <p className={'mb-0'}>{getTextForPage('inputPassword.passwordInfo')}</p>
      <p>{getTextForPage('inputPassword.passwordSpecialCharacters')}</p>
      <div className={'justify-content-center row'}>
        <Button
          classSup={'btnGreenLight'}
          id={'submitChangePassword'}
          isButtonWithAction={true}
          title={_.toUpper(getTextButton('changePassword'))}
          type={'submit'}
        />
      </div>
    </div>
  )
}

export default InputInformation
