import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Axios from 'axios'
import _ from 'lodash'
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ImgTextCard from '../Common/Blocks/ImgTextCard/ImgTextCard'
import GenerateText from '../Common/Blocks/Text/GenerateText'
import TextImgCard from '../Common/Blocks/TextImgCard/TextImgCard'
import Button from '../Common/Button/Button'
import ButtonContextual from '../Common/Button/ButtonContextual'
import ConditionMessages from '../Common/ConditionMessages/ConditionMessages'
import HiddenText from '../Common/HiddenText/HiddenText'
import Image from '../Common/Image/Image'
import TitlePage from '../Common/TitlePage/TitlePage'

function usePagesTools () {
  const [pageData, setPageData] = useState()

  const addBlock = (blockData) => {
    const type = blockData.type
    let content = ''

    switch (type) {
      case 'Button':
        return <Button
          classDiv={blockData.content.classDiv}
          className={blockData.content.className}
          classSup={blockData.content.classSup}
          isButtonWithAction={blockData.content.isButtonWithAction}
          key={uuidv4()}
          title={blockData.content.title}
        />
      case 'ButtonContextual':
        return <ButtonContextual
          buttonState={blockData.content.states}
          classDiv={blockData.content.classDiv}
          className={blockData.content.className}
          classSup={blockData.content.classSup}
          displayContentOnClick={blockData.content.displayContentOnClick}
          functionType={blockData.content.functionType}
          isLoading={blockData.content.isLoading}
          isAsyncFunction={blockData.content.asyncFunction}
          isButtonWithAction={blockData.content.isButtonWithAction}
          key={blockData.content.id}
          title={blockData.content.title}
          typeFunctionContentPage={blockData.content.typeFunctionContentPage}
          visibility={blockData.content.states.visibility}
        />
      case 'Image':
        const imgSrc = (process.env.REACT_APP_LINK_IMG_ARTICLES + blockData.content.image.img)
        return <Image
          key={uuidv4()}
          img={imgSrc}
          className={_.get(blockData, 'content.image.className', '')}
          divClassName={_.get(blockData, 'content.image.divClassName', '')}
        />
      case 'ImgTextCard':
        const sourceImg = (process.env.REACT_APP_LINK_IMG + blockData.content.image.src)
        content = <GenerateText
          key={uuidv4()}
          className={blockData.content.text.title.className}
          message={blockData.content.text.message}
          messageDiv={blockData.content.text.messageDiv}
          multipleMessage={blockData.content.text.multipleMessage}
          title={extractTitle(blockData)}
        />
        return (
          <div key={uuidv4()} className={'d-flex flex-sm-column-reverse'}>
            <ImgTextCard
              key={uuidv4()}
              img={sourceImg}
              classNameImg={_.get(blockData, 'content.image.className', 'w-100')}
              content={content}
              className={'pl-3'}
            />
          </div>
        )
      case 'TextImgCard':
        let classNameBlockText = blockData.content.classNameBlockText
        let classNameContent = blockData.content.classNameContent
        let classNameDivImg = _.get(blockData, 'content.image.classNameDivImg', '')
        let classNameImg = _.get(blockData, 'content.image.classNameImg', '')
        let classNameItcRow = _.get(blockData, 'content.classNameItcRow', '')
        let classParentDiv = blockData.content.classParentDiv
        let img = (process.env.REACT_APP_LINK_IMG + blockData.content.image.src)
        content = <GenerateText
          key={uuidv4()}
          title={extractTitle(blockData)}
          message={blockData.content.text.message}
          messageDiv={blockData.content.text.messageDiv}
          multipleMessage={blockData.content.text.multipleMessage}
        />
        return <TextImgCard
          classNameItcRow={classNameItcRow}
          classParentDiv={classParentDiv}
          classNameBlockText={classNameBlockText}
          classNameContent={classNameContent}
          classNameDivImg={classNameDivImg}
          classNameImg={classNameImg}
          content={content}
          key={uuidv4()}
          img={img}
        />
      case 'TitlePage':
        return <TitlePage
          key={uuidv4()}
          classTitle={blockData.content.classTitle}
          title={blockData.content.title}
        />
      case 'Standard':
        return <GenerateText
          key={uuidv4()}
          title={blockData.content.text.title}
          message={blockData.content.text.message}
          messageDiv={blockData.content.text.messageDiv}
          multipleMessage={blockData.content.text.multipleMessage}
        />
      case 'HiddenText':
        return <HiddenText
          id={blockData.content.id}
          key={uuidv4()}
          hiddenTextState={blockData.content.states}
          messages={blockData.content.text.message}
          visibility={blockData.content.states.visibility}
        />
      case 'conditionMessages':
        return <ConditionMessages
          key={uuidv4()}
          visibility={blockData.content.states.visibility}
          category={blockData.content.category}
          messages={blockData.content.messages}
        />
      default:
        return ''
    }
  }


  const displayBlocks = (blocks) => {
    if (blocks) {
      const content = []
      _.forEach(blocks, block => {
        if (_.get(block, 'multipleElements')) {
          const elements = []
          _.forEach(block.elements, (element, key) => {
            elements.push(addBlock(element))
          })
          const parent = handleMultipleElements(block.parentElements, elements)
          content.push(parent)
        } else {
          content.push(addBlock(block))
        }
      })

      return content
    }
    return ''
  }

  const extractTitle = (blockData, defaultValue = null) => {
    return _.get(blockData, 'content.text.title', defaultValue)
  }

  const fetchPageData = async (pageName) => {
    const source = Axios.CancelToken.source()
    try {
      const result = await Axios.post('/api/simplePage/get-data', { pageName }, {
        cancelToken: source.token
      })
      setPageData(result.data)
    } catch (error) {
      // eslint-disable-next-line no-empty
      if (Axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  const handleMultipleElements = (blockParentElements, elements) => {
    blockParentElements.content.text.message.push(elements)
    return addBlock(blockParentElements)
  }

  const spinner = (text) => {
    return (
      <div>
        <div className={'text-center'}>
          <h2>{text}</h2>
        </div>
        <div className={'text-center'}>
          <FontAwesomeIcon icon={faSpinner} className={'fa-pulse'} size={'4x'}/>
        </div>
      </div>
    )
  }

  return {
    addBlock,
    displayBlocks,
    extractTitle,
    fetchPageData,
    pageData,
    spinner
  }
}

export default usePagesTools
