import PropTypes from 'prop-types'
import React, { useState } from 'react'
import './BasicCard.scss'

function BasicCard (props) {
  const [className] = useState(props.className)
  const [classSup] = useState(props.classSup)
  const [imgBackground] = useState(props.imgBackground)
  const [space] = useState(props.space)
  const displayTexts = props.displayTexts
  const getOnClick = props.getOnClick

  return (
    <div className={`BasicCard ${className}`} onClick={(event) => {
      getOnClick(event)
    }}>
      <div className={`card card-widget widget-services ${classSup}`}>
        <div className={'card-body picture ' + imgBackground}>
          <div className={'text ' + space}>
            {displayTexts()}
          </div>
        </div>
      </div>
    </div>
  )
}

BasicCard.propTypes = {
  className: PropTypes.string,
  classSup: PropTypes.string,
  displayTexts: PropTypes.func,
  getOnClick: PropTypes.func,
  imgBackground: PropTypes.string,
  space: PropTypes.string
}

export default BasicCard
