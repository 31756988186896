// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useWindowDimensions } from '../Providers/WindowDimensionsProvider'

function useWitchTerminal () {
  const { width, height } = useWindowDimensions()

  const isMobile = () => {
    return width < 576
  }

  const getBootstrapSize = () => {
    switch (width) {
      case width >= 1200:
        return 'xl'
      case width >= 992:
      default:
        return 'lg'
      case width >= 768:
        return 'md'
      case width >= 576:
        return 'sm'
      case width < 576:
        return 'xs'
    }
  }

  return { getBootstrapSize, height, isMobile, width }
}

export default useWitchTerminal
