import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import React, { Fragment } from 'react'
import useMpecTranslations from '../../../../../Tools/translations'

function ActivationLoading() {
  const {getTextForPage} = useMpecTranslations('login')
  return(
    <Fragment>
      <div className={'text-center'}>
        <h2>{_.upperFirst(getTextForPage('activation.weActivateYourAccount'))}</h2>
      </div>
      <div className={'text-center'}>
        <FontAwesomeIcon icon={faSpinner} className={'fa-pulse'} size={'4x'}/>
      </div>
    </Fragment>
  )
}

export default ActivationLoading
