import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useMpecTranslations from '../../../../Tools/translations'
moment.locale('fr')

function InputInformation (props) {
  const { getTextForPage } = useMpecTranslations('MyProfile')
  const [myProfileData, setMyProfileData] = useState(props.myProfile || {})
  const [genders, setGenders] = useState([])
  const [roles, setRoles] = useState([])
  const handleChange = props.handleChange
  const updateData = props.updateData

  useEffect(() => {
    if (_.has(props, 'formParams')) {
      setGenders(_.get(props, 'formParams.genders', []))
      setRoles(_.get(props, 'formParams.roles', []))
    }
    if (_.has(props, 'myProfile')) {
      setMyProfileData(_.get(props, 'myProfile'))
    }
  }, [props])

  const dataInputs = () => {
    const phone = _.get(myProfileData, '_phone')

    return [
      {
        htmlFor: 'inputName',
        text: 'inputInformation.name',
        type: 'name',
        name: 'lastname',
        id: 'inputName',
        required: true,
        value: _.get(myProfileData, '_lastname', '')
      },
      {
        htmlFor: 'inputFirstName',
        text: 'inputInformation.firstName',
        type: 'firstName',
        name: 'firstname',
        id: 'inputFirstName',
        required: true,
        value: _.get(myProfileData, '_firstname', '')
      },
      {
        htmlFor: 'inputTel',
        text: 'inputInformation.phoneNumber',
        type: 'tel',
        name: 'phone',
        id: 'inputTel',
        required: true,
        value: (_.isObject(phone) ? phone._number : phone),
        dataApiId: (_.isObject(phone) ? phone._id : null)
      },
      {
        htmlFor: 'inputEmail',
        text: 'inputInformation.emailPro',
        type: 'email',
        name: 'email',
        id: 'inputEmail',
        required: true,
        disable: true,
        value: _.get(myProfileData, '_email', '')
      },
      {
        htmlFor: 'inputAge',
        text: 'inputInformation.birthDate',
        type: 'date',
        name: 'birthdate',
        id: 'inputAge',
        required: false,
        value: moment(_.get(myProfileData, '_birthdate', '')).format('Y-MM-DD')
      }
    ]
  }

  const isChecked = (field, value) => {
    const testValue = _.get(myProfileData, '_'.concat(field).replace('[]', ''), '')
    let isChecked = false
    let isArray = false
    if (_.isArray(testValue)) {
      isChecked = _.indexOf(testValue, value) >= 0
      isArray = true
    } else {
      isChecked = testValue === value
    }
    if (isChecked && !isArray) {
      _.set(updateData, field, value)
    } else if( isChecked && isArray) {
      let vals = _.get(updateData, field, [])
      vals.push(value)
      _.set(updateData, field, _.uniq(vals))
    }
    return isChecked
  }

  const allInput = () => {
    const data = dataInputs()
    return (
      data.map((value) => {
        _.set(updateData, value.name, value.value)
        return <div className={'mb-3 row'} key={uuidv4()}>
          <label htmlFor={value.htmlFor} className={'col-sm-3 col-form-label'}>{_.upperFirst(getTextForPage(value.text))}</label>
          <div className={'col-sm-7'}>
            <input className={'form-control rounded'}
                   data-api-id={value?.dataApiId}
                   defaultValue={value?.value}
                   id={value.id}
                   name={value?.name}
                   required={value.required}
                   type={value.type}
                   onChange={handleChange}
                   disabled={value?.disable}
            />
          </div>
        </div>
      })
    )
  }

  const dataGender = () => {
    const configs = []
    if (genders.length > 0) {
      _.map(
        genders, (gender, index) => {
          const genderText = _.lowerCase(_.values(gender)[0])
          const value = _.lowerCase(_.keys(gender)[0])
          const genderId = 'gender-'.concat(genderText)
          configs.push(
            {
              firstDiv: index !== genders.length ? 'form-check col-sm-4' : 'form-check',
              htmlFor: genderId,
              type: 'radio',
              name: 'idGender',
              text: 'inputInformation.'.concat(genderText),
              id: genderId,
              value: parseInt(value),
              required: true
            }
          )
        }
      )
    }
    return configs
  }

  const checkGender = () => {
    const dataCheckGender = dataGender()
    return (
      dataCheckGender.map((value) => {
        const text = value.text
        return <div className={value.firstDiv} key={uuidv4()}>
          <label className="form-check-label" htmlFor={value.htmlFor}>
            <input className="form-check-input"
                   type={value.type}
                   name={value.name}
                   id={value.id}
                   value={value.value}
                   defaultChecked={isChecked(value.name, value.value)}
                   onChange={handleChange}
                   required={value?.required}
            />
            {_.upperFirst(getTextForPage(text))}
          </label>
        </div>
      })
    )
  }

  const dataRole = () => {
    const configs = []
    if (roles.length > 0) {
      _.map(
        roles, (role, index) => {
          const text = _.camelCase(_.values(role)[0])
          const id = 'role-'.concat(text)
          const value = _.lowerCase(_.keys(role)[0])
          configs.push(
            {
              firstDiv: index !== roles.length ? 'form-check col-sm-4' : 'form-check',
              htmlFor: id,
              type: 'checkbox',
              name: 'roles[]',
              text: 'inputInformation.'.concat(text),
              id,
              value: parseInt(value)
            }
          )
        }
      )
    }
    return configs
  }

  const checkRole = () => {
    const dataCheckRole = dataRole()
    return (
      dataCheckRole.map((value) => {
        const text = value.text
        return <div className={value.firstDiv} key={uuidv4()}>
          <label className="form-check-label" htmlFor={value.htmlFor}>
            <input className="form-check-input"
                   type={value.type}
                   name={value.name}
                   id={value.htmlFor}
                   value={value.value}
                   defaultChecked={isChecked(value.name, value.value)}
                   onChange={handleChange}
            />
            {_.upperFirst(getTextForPage(text))}
          </label>
        </div>
      })
    )
  }

  return (
    <div>
      {allInput()}
      <div className="row">
        <label htmlFor="inputGenre" className="col-sm-3 col-form-label">{_.upperFirst(getTextForPage('inputInformation.iAm'))}</label>
        <div className="row col-sm-9">
          {checkGender()}
        </div>
      </div>
      <div className="mb-4 row">
        <label htmlFor="inputGenre" className="col-sm-3 col-form-label">{_.upperFirst(getTextForPage('inputInformation.role'))}</label>
        <div className="row col-sm-9">
          {checkRole()}
        </div>
      </div>
    </div>
  )
}

InputInformation.propTypes = {
  formParams: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  updateData: PropTypes.object.isRequired
}

export default InputInformation
